import { lazy } from 'react'
import { RouteProps } from 'react-router'

import ConfigTemplates from 'pages/ConfigCenter/Template'
import TemplateChange from 'pages/ConfigCenter/Template/TemplateChange'
import ConfigTemplateDetail from 'pages/ConfigCenter/Template/TemplateLists/TemplateDetail'
import CPPODetails from 'pages/CPPO/Details'
import ClusterConfigHistoryDetail from 'pages/Tenants/ClusterDetail/Configs/Histories/History'

import {
  Access,
  Audits,
  DBaaSBRAC,
  Overview,
  Providers,
  SettingsPanel,
  Incidents,
  MSP,
  CPPO
} from './pages'
import K8sUpgrade from './pages/Devops/K8sUpgrade/TaskList'

const MSPDetails = lazy(() => import('pages/MSP/Details'))

const UserGroupDetail = lazy(
  () => import('pages/Access/UserGroups/UserGroupDetail')
)
const ConfigCenter = lazy(() => import('pages/ConfigCenter'))
const Tenants = lazy(() => import('./pages/Tenants'))
const ConfigChange = lazy(() => import('pages/Devops/ConfigChange'))
const JumpAuthorizations = lazy(
  () => import('pages/Devops/JumpServerAuthorization')
)
const JumpAuthorizationDetail = lazy(
  () => import('pages/Devops/JumpServerAuthorization/AuthorizationDetail')
)
const Maintenance = lazy(() => import('pages/Devops/Maintenance'))
const MaintenanceDetail = lazy(() => import('pages/Devops/Maintenance/Detail'))
const DevOpsOperations = lazy(() => import('pages/Devops/Operations'))
const DeployStatic = lazy(
  () => import('pages/Devops/Release/ReleaseDetail/Change/StaticChange')
)
const ReleaseDetail = lazy(
  () =>
    import('pages/Devops/Release/ReleaseDetail/ReleaseOverview/ReleaseDetail')
)
const TicketDetail = lazy(
  () => import('pages/Devops/Release/ReleaseDetail/Ticket/TicketDetail')
)
const ClusterOperation = lazy(
  () => import('pages/Tenants/ClusterDetail/Operations/ClusterOperation')
)
const Release = lazy(() => import('./pages/Devops/Release'))
const CheckList = lazy(
  () => import('./pages/Devops/Release/ReleaseDetail/Change/CheckList')
)
const DeploymentDetail = lazy(
  () => import('./pages/Devops/Release/ReleaseDetail/Change/UpdateChangePage')
)
const DeploymentList = lazy(
  () =>
    import('./pages/Devops/Release/ReleaseDetail/ReleaseOverview/ChangePortal')
)
const IncidentDetail = lazy(() => import('./pages/Incidents/IncidentView'))

const Certification = lazy(() => import('./pages/Monitoring/Certification'))
const InfraInfo = lazy(() => import('./pages/Monitoring/InfraInformation'))
const InfraInfoDetail = lazy(
  () => import('./pages/Monitoring/InfraInformation/InfraInfoDetail')
)
const K8sUpgradeTaskList = lazy(
  () => import('./pages/Devops/K8sUpgrade/TaskList')
)
const K8sUpgradeTaskDetail = lazy(
  () => import('./pages/Devops/K8sUpgrade/TaskDetail')
)
const K8sUpgradeTaskK8sClusterDetail = lazy(
  () => import('./pages/Devops/K8sUpgrade/K8sClusterDetail')
)

const ProviderDetail = lazy(() => import('./pages/Providers/Detail'))
const ClusterDetail = lazy(() => import('./pages/Tenants/ClusterDetail'))
const Gitops = lazy(() => import('./pages/Tenants/ClusterDetail/Gitops'))
const Terminal = lazy(
  () => import('./pages/Tenants/ClusterDetail/Maintance/terminal')
)
const TenantDetail = lazy(() => import('./pages/Tenants/TenantDetail'))

const NotificationDetail = lazy(
  () => import('./pages/Notification/NotificationDetail')
)

const NotificationStatistics = lazy(
  () => import('./pages/Notification/NotificationStatistics')
)

const Notification = lazy(() => import('./pages/Notification'))

const UserBehaviorSubscriptions = lazy(
  () => import('./pages/UserBehavior/Subscriptions')
)

const SLAMonthlySummaryPage = lazy(() => import('./pages/Overview/SLA/Summary'))

const SLAClusterDetailPage = lazy(() => import('./pages/Overview/SLA/Detail'))

const RemediationTickets = lazy(() => import('./pages/RemediationTickets'))

const RemediationTicketDetail = lazy(
  () => import('./pages/RemediationTickets/RemediationTicketDetail')
)

const allRoutes: RouteProps[] = [
  {
    component: Overview,
    path: '/overview',
    exact: true
  },
  {
    component: Tenants,
    path: '/orgs',
    exact: true
  },
  {
    component: TenantDetail,
    path: '/orgs/:org_id',
    exact: true
  },
  {
    component: ClusterConfigHistoryDetail,
    path: '/confighistories/:history_id',
    exact: true
  },
  {
    component: ClusterDetail,
    path: '/orgs/:org_id/clusters/:cluster_id',
    exact: true
  },
  {
    component: ClusterOperation,
    path: '/operations/:operation_id',
    exact: true
  },
  {
    component: DevOpsOperations,
    path: '/operations'
  },
  {
    component: JumpAuthorizations,
    path: '/jumps',
    exact: true
  },
  {
    component: JumpAuthorizationDetail,
    path: '/jumps/:authorization_id'
  },
  {
    component: Terminal,
    path: '/orgs/:org_id/clusters/:cluster_id/ctl'
  },
  {
    component: Gitops,
    path: '/orgs/:org_id/:cluster_id/gitops'
  },
  {
    component: Providers,
    path: '/providers',
    exact: true
  },
  {
    component: ProviderDetail,
    path: '/providers/:provider'
  },
  {
    component: CheckList,
    path: '/release/:release_id/:change_id/checklist'
  },
  {
    component: DeploymentDetail,
    path: '/release/:release_id/:change_id/update',
    exact: true
  },
  {
    component: DeployStatic,
    path: '/release/:release_id/change/:change_id',
    exact: true
  },
  {
    component: TicketDetail,
    path: '/release/:release_id/tickets/:name'
  },
  {
    component: ReleaseDetail,
    path: '/release/:release_id'
  },
  {
    component: Release,
    path: '/release'
  },
  {
    component: DBaaSBRAC,
    path: '/rbac'
  },
  {
    component: Incidents,
    path: '/incidents',
    exact: true
  },
  {
    component: IncidentDetail,
    path: '/incidents/:incident_id'
  },
  {
    component: Terminal,
    path: '/clusters/:cluster_id/ctl'
  },
  {
    component: ConfigChange,
    path: '/configchange'
  },
  {
    component: MaintenanceDetail,
    path: '/maintenance/:id'
  },
  {
    component: Maintenance,
    path: '/maintenance'
  },
  {
    component: Certification,
    path: '/certification'
  },
  {
    component: InfraInfo,
    path: '/infraInformation',
    exact: true
  },
  {
    component: InfraInfoDetail,
    path: '/infraInformation/:k8s_name',
    exact: true
  },
  {
    component: K8sUpgradeTaskList,
    path: '/k8sUpgradeTask',
    exact: true
  },
  {
    component: K8sUpgradeTaskDetail,
    path: '/k8sUpgradeTask/:task_id',
    exact: true
  },
  {
    component: K8sUpgradeTaskK8sClusterDetail,
    path: '/k8sUpgradeTask/:task_id/k8sCluster/:k8s_name',
    exact: true
  },
  {
    component: SettingsPanel,
    path: '/business'
  },
  {
    component: MSPDetails,
    path: '/msp/:org_id'
  },
  {
    component: MSP,
    path: '/msp'
  },
  {
    component: CPPODetails,
    path: '/cppo/:org_id'
  },
  {
    component: CPPO,
    path: '/cppo'
  },
  {
    component: ConfigCenter,
    path: '/dict'
  },
  {
    component: ConfigTemplateDetail,
    path: '/template/:template_id',
    exact: true
  },
  {
    component: ConfigTemplates,
    path: '/template',
    exact: true
  },
  {
    component: TemplateChange,
    path: '/template/modify/:change_id',
    exact: true
  },
  {
    component: Notification,
    path: '/notification',
    exact: true
  },
  {
    component: NotificationDetail,
    path: '/notification/:workflow_id?',
    exact: true
  },
  {
    component: NotificationStatistics,
    path: '/notification/:workflow_id/statistics',
    exact: true
  },
  {
    component: UserBehaviorSubscriptions,
    path: '/userbehavior_subscriptions',
    exact: true
  },
  {
    component: SLAMonthlySummaryPage,
    path: '/sla/summary'
  },
  {
    component: SLAClusterDetailPage,
    path: '/sla/:cluster_id/detail'
  },
  {
    component: RemediationTickets,
    path: '/remediation_tickets',
    exact: true
  },
  {
    component: RemediationTicketDetail,
    path: '/remediation_tickets/:ticket_id',
    exact: true
  }
]

const adminRoute: RouteProps[] = [
  {
    component: UserGroupDetail,
    path: '/access/usergroups/:user_group'
  },
  {
    component: Access,
    path: '/access'
  },
  {
    component: Audits,
    path: '/audits'
  }
]

export function getAllowedRoutes(roles: string[]) {
  if (roles.indexOf('admin') > -1) {
    return [...allRoutes, ...adminRoute]
  } else {
    return allRoutes
  }
}

export default allRoutes
