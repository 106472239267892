/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ApiBaseResp,
  TenantListTenantResp,
  ListTenantsInternalParams,
  TenantGetTenantResp,
  TenantCommonResp,
  TenantDeleteAccountUserReq,
  RbacGenerateAPIKeyResp,
  RbacGenerateAPIKeyReq,
  RbacUpdateAPIKeyRoleBindingsReq,
  ApprovalGetApprovalResp,
  AuditListAuditLogResp,
  AuditControllerListAuditLogParams,
  AuditListChangeAuditLogResp,
  AuditControllerListChangeAuditLogParams,
  AuditListClusterMaintenanceAuditLogResp,
  AuditControllerListClusterMaintenanceAuditLogParams,
  AuditListCtlAuditLogResp,
  AuditControllerListCtlAuditLogParams,
  AuditListJumpAuditLogResp,
  AuditControllerListJumpAuditLogParams,
  AuditListOperationAuditLogResp,
  AuditControllerListOperationAuditLogParams,
  CloudconfigCloneParamAttrReq,
  CloudconfigCreateParamAttrReq,
  CloudconfigUpdateParamAttrReq,
  CloudconfigGetConfigurationChangeResp,
  CloudconfigApplyConfigurationChangeReq,
  CloudconfigListConfigurationChangeResp,
  CloudConfigControllerListClusterConfigurationChangesParams,
  CloudconfigCreateConfigurationChangeResp,
  CloudconfigCreateClusterConfigurationChangeReq,
  CloudconfigPreviewConfigurationChangeResp,
  CloudconfigPreviewClusterConfigurationChangeReq,
  CloudconfigGetConfigurationResp,
  CloudConfigControllerGetClusterConfigurationParams,
  CloudconfigListConfigTypesResp,
  CloudconfigListInstanceTypesResp,
  CloudConfigControllerListInstanceTypesParams,
  CloudconfigListParamAttrResp,
  CloudConfigControllerListParameterAttributesParams,
  CloudconfigGetParamAttrResp,
  CloudconfigListParamVersionsResp,
  CloudconfigListTemplateChangesResp,
  CloudConfigControllerListTemplateChangesParams,
  CloudconfigCreateTemplateChangeResp,
  CloudconfigCreateTemplateChangeReq,
  CloudconfigGetTemplateChangeResp,
  CloudconfigUpdateTemplateChangeReq,
  CloudconfigCloseTemplateChangeReq,
  CloudconfigDiffTemplateChangeReleaseResp,
  CloudConfigControllerDiffTemplateChangeReleaseParams,
  CloudconfigReleaseTemplateChangeReq,
  CloudconfigListTemplateDefaultCasesResp,
  CloudConfigControllerListTemplateDefaultCasesParams,
  CloudconfigListTemplatesResp,
  CloudConfigControllerListTemplatesParams,
  CloudconfigCreateTemplateResp,
  CloudconfigCreateTemplateReq,
  CloudconfigGetTemplateResp,
  CloudConfigControllerGetTemplateParams,
  CloudConfigControllerListTenantInitConfigurationChangesParams,
  CloudconfigCreateTenantInitConfigurationChangeReq,
  CloudconfigPreviewTenantInitConfigurationChangeReq,
  CloudConfigControllerGetTenantInitConfigurationParams,
  CloudconfigListVersionsResp,
  K8supgradeListK8SInformationResp,
  ListK8SInformationParams,
  K8supgradeGetK8SInformationResp,
  K8supgradeListTasksResp,
  ListK8sUpgradeTasksParams,
  K8supgradeCreateTaskResp,
  K8supgradeCreateTaskReq,
  K8supgradeGetTaskResp,
  K8supgradeUpdateTaskReq,
  K8supgradeCloseTaskReq,
  K8supgradeGetTaskK8sClusterResp,
  K8supgradeUpdateTaskK8sClusterResp,
  K8supgradeUpdateTaskK8sClusterReq,
  K8supgradeUpdateK8sUpgradeTaskClusterAutoGenerationResp,
  K8supgradeUpdateK8sUpgradeTaskClusterAutoGenerationReq,
  K8supgradeCancelUpgradeK8sClusterResp,
  K8supgradeExcludeK8sUpgradeTaskClusterResp,
  ClusterListMaintenancesResp,
  ListMaintenancesParams,
  ClusterCreateMaintenanceResp,
  ClusterCreateMaintenanceReq,
  ClusterGetMaintenanceResp,
  GetMaintenanceParams,
  ClusterListClustersResp,
  ListClustersParams,
  ClusterGetClusterResp,
  ClusterGetAlertSummaryResp,
  GetAlertSummaryParams,
  ClusterListAlertsResp,
  ListAlertsParams,
  ClusterGetClusterComponentsResp,
  ConfigcenterAddAttributeReq,
  ConfigcenterCopyFromVersionReq,
  ConfigcenterListAttributesResp,
  ListConfigAttributeParams,
  ConfigcenterModifyReq,
  DashboardCloudOncallInfoResp,
  GetCloudOncallInfoParams,
  DashboardCriticalAlertInfoResp,
  GetCriticalAlertInfoParams,
  DashboardIncidentInfoResp,
  GetIncidentInfoParams,
  DashboardReleaseInfoResp,
  GetReleaseInfoParams,
  DashboardSLAInfoResp,
  GetSLAInfoParams,
  DashboardGetClusterDetailOfDedicatedSLAResp,
  GetClusterDetailOfDedicatedSLAParams,
  DashboardGetMonthlySummaryOfDedicatedSLAResp,
  GetMonthlySummaryOfDedicatedSLAParams,
  DashboardSLOInfoResp,
  DashboardGetTop10QPSTenantsResp,
  DashboardGetTop10TiFlashStorageTenantsResp,
  DashboardGetTop10TiKVStorageTenantsResp,
  GithubListPullRequestsResp,
  GithubControllerListPullRequestsParams,
  GithubGetPullRequestResp,
  GithubRawListLabelsResp,
  GithubControllerRawListLabelsParams,
  GithubRawGetLabelResp,
  GithubRawListPullRequestsResp,
  GithubControllerRawListPullRequestsParams,
  GithubRawGetPullRequestResp,
  GithubRawListReleasesResp,
  GithubControllerRawListReleasesParams,
  GithubRawDiffReleaseResp,
  GithubControllerRawDiffReleaseParams,
  GithubRawGetReleaseResp,
  GithubListRepositoriesResp,
  GithubControllerListRepositoriesParams,
  GithubGetRepositoryResp,
  IncidentListIncidentsResp,
  IncidentCreateIncidentResp,
  IncidentCreateIncidentReq,
  EntityIncident,
  IncidentUpdateIncidentResp,
  IncidentUpdateIncidentReq,
  IncidentDeleteIncidentResp,
  ApiEmptyResp,
  EntityIncidentLogItemBody,
  IncidentListIncidentLogsResp,
  EntityIncidentLogItem,
  IncidentDeleteIncidentLogItemResp,
  IncidentListIncidentPostMortemItemsResp,
  IncidentCreateIncidentPostMortemResp,
  IncidentCreateIncidentPostMortemReq,
  IncidentGetIncidentPostMortemResp,
  IncidentUpdateIncidentPostMortemResp,
  IncidentUpdateIncidentPostMortemReq,
  IncidentDeleteIncidentPostMortemResp,
  IncidentListIncidentPostMortemTimelinesResp,
  IncidentCreateIncidentPostMortemTimelineResp,
  IncidentCreateIncidentPostMortemTimelineReq,
  IncidentGetIncidentPostMortemTimelineResp,
  IncidentUpdateIncidentPostMortemTimelineResp,
  IncidentUpdateIncidentPostMortemTimelineReq,
  IncidentDeleteIncidentPostMortemTimelineResp,
  IncidentListIncidentPostMortemToDoActionsResp,
  IncidentCreateIncidentPostMortemToDoActionResp,
  IncidentCreateIncidentPostMortemToDoActionReq,
  IncidentGetIncidentPostMortemToDoActionResp,
  IncidentUpdateIncidentPostMortemToDoActionResp,
  IncidentUpdateIncidentPostMortemToDoActionReq,
  IncidentDeleteIncidentPostMortemToDoActionResp,
  JumpInviteUserResp,
  JumpInviteUserReq,
  JumpListAuthorizationsResp,
  JumpControllerListAuthorizationsParams,
  JumpCreateAuthorizationResp,
  JumpCreateAuthorizationReq,
  JumpGetControlPlaneOptionsResp,
  JumpGetServerlessOptionsResp,
  JumpGetAuthorizationResp,
  JumpGetConnectJumpPodParamsResp,
  JumpGetUserResp,
  UserUserProfileResp,
  OperationListActionResp,
  OperationControllerListActionsParams,
  OperationGetActionResp,
  OperationListInstanceResp,
  OperationControllerListInstancesParams,
  OperationCreateInstanceResp,
  OperationCreateInstanceReq,
  OperationGetInstanceResp,
  OperationFinishInstanceActionReq,
  OperationListOperationResp,
  OperationControllerListOperationsParams,
  OperationListOperationCategoryResp,
  OperationGetOperationResp,
  OperationControllerGetOperationParams,
  ReleaseCreateChangeResp,
  ReleaseCreateChangeReq,
  ReleaseDefaultChangeResp,
  ReleaseControllerDefaultChangeParams,
  ReleaseGetChangeResp,
  ReleaseControllerGetChangeParams,
  ReleaseUpdateChangeReq,
  ReleaseCloseChangeReq,
  ReleaseExecuteDeploymentReq,
  ReleaseIsDeploymentReadyResp,
  ReleaseVerifyDeploymentReq,
  ReleaseListReleaseResp,
  ReleaseControllerListReleasesParams,
  ReleaseGetReleaseResp,
  ReleaseControllerGetReleaseParams,
  ReleaseListChangeResp,
  ReleaseListTicketsResp,
  ReleaseControllerListTicketsParams,
  ReleaseGetTicketResp,
  ReleaseControllerGetTicketParams,
  ReleaseUpdateTicketReq,
  RemediationticketListRemediationTicketsResp,
  RemediationticketCreateRemediationTicketResp,
  RemediationticketCreateRemediationTicketReq,
  RemediationticketGetRemediationTicketResp,
  RemediationticketApproveTicketPhaseReq,
  RemediationticketGenerateFinalDocReq,
  RemediationticketGetProgressResp,
  RemediationticketRejectTicketPhaseReq,
  RemediationticketSubmitTicketPhaseReq,
  RemediationticketGetTicketApprovalsResp,
  RbacListRolesResp,
  RbacCreateRoleReq,
  RbacGetRoleResp,
  RbacUpdateRoleReq,
  SecondaryconfirmApplyReq,
  SecondaryconfirmDiffResp,
  GetSecondaryConfirmDiffParams,
  SecondaryconfirmListResp,
  ListSecondaryConfirmsParams,
  ListTenantsParams,
  TenantGetImpersonateResp,
  TenantAddImpersonateResp,
  TenantAddImpersonateReq,
  TenantUpdateTenantReq,
  TenantDeleteTenantReq,
  TenantGetUsersResp,
  GetTenantUsersParams,
  TenantAddUserToTenantReq,
  TenantRemoveUserFromTenantReq,
  TenantAddCreditsReq,
  TenantDeleteProjectReq,
  GetProjectUsersParams,
  TenantAddUserToProjectReq,
  TenantRemoveUserFromProjectReq,
  TenantListTenantQuotaResp,
  UserListGroupsResp,
  UserControllerListGroupsParams,
  UserCreateGroupReq,
  UserGetGroupResp,
  UserUpdateGroupReq,
  UserDeleteGroupReq,
  UserMapUsersToGroupReq,
  UserUnmapUsersFromGroupReq,
  UserbehaviorListSubscriptionsResp,
  UserBehaviorControllerListSubscriptionsParams,
  UserbehaviorCreateSubscriptionResp,
  UserbehaviorCreateSubscriptionReq,
  UserbehaviorAddExternalLarkChatReq,
  UserbehaviorListLarkChatOptionsResp,
  UserbehaviorGetSubscriptionResp,
  UserbehaviorEditSubscriptionResp,
  UserbehaviorEditSubscriptionReq,
  UserListUserResp,
  ListUsersParams,
  UserGetUserResp,
  GetUserParams,
  UserUpdateUserRoleBindingsReq
} from './interface'
import { customInstance } from '../../axios';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * sync all pull requests manually
 */
export const githubControllerSyncPullRequests = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops-internal/github/pulls/sync`, method: 'post'
    },
      options);
    }
  


export const getGithubControllerSyncPullRequestsMutationOptions = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof githubControllerSyncPullRequests>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof githubControllerSyncPullRequests>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof githubControllerSyncPullRequests>>, TVariables> = () => {
          

          return  githubControllerSyncPullRequests(requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type GithubControllerSyncPullRequestsMutationResult = NonNullable<Awaited<ReturnType<typeof githubControllerSyncPullRequests>>>
    
    export type GithubControllerSyncPullRequestsMutationError = unknown

    export const useGithubControllerSyncPullRequests = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof githubControllerSyncPullRequests>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getGithubControllerSyncPullRequestsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * sync all repos manually
 */
export const githubControllerSyncRepositories = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops-internal/github/repos/sync`, method: 'post'
    },
      options);
    }
  


export const getGithubControllerSyncRepositoriesMutationOptions = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof githubControllerSyncRepositories>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof githubControllerSyncRepositories>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof githubControllerSyncRepositories>>, TVariables> = () => {
          

          return  githubControllerSyncRepositories(requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type GithubControllerSyncRepositoriesMutationResult = NonNullable<Awaited<ReturnType<typeof githubControllerSyncRepositories>>>
    
    export type GithubControllerSyncRepositoriesMutationError = unknown

    export const useGithubControllerSyncRepositories = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof githubControllerSyncRepositories>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getGithubControllerSyncRepositoriesMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * sync all releases manually
 */
export const releaseControllerSyncReleaseManually = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops-internal/release/releases/sync`, method: 'post'
    },
      options);
    }
  


export const getReleaseControllerSyncReleaseManuallyMutationOptions = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerSyncReleaseManually>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof releaseControllerSyncReleaseManually>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof releaseControllerSyncReleaseManually>>, TVariables> = () => {
          

          return  releaseControllerSyncReleaseManually(requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ReleaseControllerSyncReleaseManuallyMutationResult = NonNullable<Awaited<ReturnType<typeof releaseControllerSyncReleaseManually>>>
    
    export type ReleaseControllerSyncReleaseManuallyMutationError = unknown

    export const useReleaseControllerSyncReleaseManually = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerSyncReleaseManually>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getReleaseControllerSyncReleaseManuallyMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * sync all tickets manually
 */
export const releaseControllerSyncTicketManually = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops-internal/release/tickets/sync`, method: 'post'
    },
      options);
    }
  


export const getReleaseControllerSyncTicketManuallyMutationOptions = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerSyncTicketManually>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof releaseControllerSyncTicketManually>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof releaseControllerSyncTicketManually>>, TVariables> = () => {
          

          return  releaseControllerSyncTicketManually(requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ReleaseControllerSyncTicketManuallyMutationResult = NonNullable<Awaited<ReturnType<typeof releaseControllerSyncTicketManually>>>
    
    export type ReleaseControllerSyncTicketManuallyMutationError = unknown

    export const useReleaseControllerSyncTicketManually = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerSyncTicketManually>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getReleaseControllerSyncTicketManuallyMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list tenants internal
 */
export const listTenantsInternal = (
    params?: ListTenantsInternalParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantListTenantResp>(
      {url: `/api/v1/devops-internal/tenants`, method: 'get',
        params
    },
      options);
    }
  

export const getListTenantsInternalQueryKey = (params?: ListTenantsInternalParams,) => [`/api/v1/devops-internal/tenants`, ...(params ? [params]: [])] as const;
  

    
export const getListTenantsInternalQueryOptions = <TData = Awaited<ReturnType<typeof listTenantsInternal>>, TError = ApiBaseResp>(params?: ListTenantsInternalParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantsInternal>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listTenantsInternal>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantsInternalQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenantsInternal>>> = () => listTenantsInternal(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListTenantsInternalQueryResult = NonNullable<Awaited<ReturnType<typeof listTenantsInternal>>>
export type ListTenantsInternalQueryError = ApiBaseResp

export const useListTenantsInternal = <TData = Awaited<ReturnType<typeof listTenantsInternal>>, TError = ApiBaseResp>(
 params?: ListTenantsInternalParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantsInternal>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTenantsInternalQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get tenant internal
 */
export const getTenantInternal = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantGetTenantResp>(
      {url: `/api/v1/devops-internal/tenants/${tenantId}`, method: 'get'
    },
      options);
    }
  

export const getGetTenantInternalQueryKey = (tenantId: string,) => [`/api/v1/devops-internal/tenants/${tenantId}`] as const;
  

    
export const getGetTenantInternalQueryOptions = <TData = Awaited<ReturnType<typeof getTenantInternal>>, TError = ApiBaseResp>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantInternal>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantInternal>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantInternalQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantInternal>>> = () => getTenantInternal(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetTenantInternalQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantInternal>>>
export type GetTenantInternalQueryError = ApiBaseResp

export const useGetTenantInternal = <TData = Awaited<ReturnType<typeof getTenantInternal>>, TError = ApiBaseResp>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantInternal>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantInternalQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * delete account user
 */
export const deleteAccountUser = (
    userId: string,
    tenantDeleteAccountUserReq: TenantDeleteAccountUserReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantCommonResp>(
      {url: `/api/v1/devops/accountusers/${userId}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: tenantDeleteAccountUserReq
    },
      options);
    }
  


export const getDeleteAccountUserMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAccountUser>>, TError,{userId: string;data: TenantDeleteAccountUserReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAccountUser>>, TError,{userId: string;data: TenantDeleteAccountUserReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAccountUser>>, {userId: string;data: TenantDeleteAccountUserReq}> = (props) => {
          const {userId,data} = props ?? {};

          return  deleteAccountUser(userId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteAccountUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAccountUser>>>
    export type DeleteAccountUserMutationBody = TenantDeleteAccountUserReq
    export type DeleteAccountUserMutationError = unknown

    export const useDeleteAccountUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAccountUser>>, TError,{userId: string;data: TenantDeleteAccountUserReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteAccountUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * generate a api key
 */
export const generateAPIKey = (
    rbacGenerateAPIKeyReq: RbacGenerateAPIKeyReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<RbacGenerateAPIKeyResp>(
      {url: `/api/v1/devops/api-keys`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: rbacGenerateAPIKeyReq
    },
      options);
    }
  


export const getGenerateAPIKeyMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof generateAPIKey>>, TError,{data: RbacGenerateAPIKeyReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof generateAPIKey>>, TError,{data: RbacGenerateAPIKeyReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof generateAPIKey>>, {data: RbacGenerateAPIKeyReq}> = (props) => {
          const {data} = props ?? {};

          return  generateAPIKey(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type GenerateAPIKeyMutationResult = NonNullable<Awaited<ReturnType<typeof generateAPIKey>>>
    export type GenerateAPIKeyMutationBody = RbacGenerateAPIKeyReq
    export type GenerateAPIKeyMutationError = ApiBaseResp

    export const useGenerateAPIKey = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof generateAPIKey>>, TError,{data: RbacGenerateAPIKeyReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getGenerateAPIKeyMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * update api key role bindings
 */
export const updateAPIKeyRoleBindings = (
    rbacUpdateAPIKeyRoleBindingsReq: RbacUpdateAPIKeyRoleBindingsReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/api-keys/roles`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: rbacUpdateAPIKeyRoleBindingsReq
    },
      options);
    }
  


export const getUpdateAPIKeyRoleBindingsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAPIKeyRoleBindings>>, TError,{data: RbacUpdateAPIKeyRoleBindingsReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateAPIKeyRoleBindings>>, TError,{data: RbacUpdateAPIKeyRoleBindingsReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAPIKeyRoleBindings>>, {data: RbacUpdateAPIKeyRoleBindingsReq}> = (props) => {
          const {data} = props ?? {};

          return  updateAPIKeyRoleBindings(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateAPIKeyRoleBindingsMutationResult = NonNullable<Awaited<ReturnType<typeof updateAPIKeyRoleBindings>>>
    export type UpdateAPIKeyRoleBindingsMutationBody = RbacUpdateAPIKeyRoleBindingsReq
    export type UpdateAPIKeyRoleBindingsMutationError = unknown

    export const useUpdateAPIKeyRoleBindings = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAPIKeyRoleBindings>>, TError,{data: RbacUpdateAPIKeyRoleBindingsReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateAPIKeyRoleBindingsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get a approval
 */
export const approvalControllerGetApproval = (
    apprId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApprovalGetApprovalResp>(
      {url: `/api/v1/devops/approval/approvals/${apprId}`, method: 'get'
    },
      options);
    }
  

export const getApprovalControllerGetApprovalQueryKey = (apprId: number,) => [`/api/v1/devops/approval/approvals/${apprId}`] as const;
  

    
export const getApprovalControllerGetApprovalQueryOptions = <TData = Awaited<ReturnType<typeof approvalControllerGetApproval>>, TError = unknown>(apprId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof approvalControllerGetApproval>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof approvalControllerGetApproval>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getApprovalControllerGetApprovalQueryKey(apprId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof approvalControllerGetApproval>>> = () => approvalControllerGetApproval(apprId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(apprId), ...queryOptions}}

export type ApprovalControllerGetApprovalQueryResult = NonNullable<Awaited<ReturnType<typeof approvalControllerGetApproval>>>
export type ApprovalControllerGetApprovalQueryError = unknown

export const useApprovalControllerGetApproval = <TData = Awaited<ReturnType<typeof approvalControllerGetApproval>>, TError = unknown>(
 apprId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof approvalControllerGetApproval>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApprovalControllerGetApprovalQueryOptions(apprId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * approve a approval
 */
export const approvalControllerUserApprove = (
    apprId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/approval/approvals/${apprId}/approve`, method: 'post'
    },
      options);
    }
  


export const getApprovalControllerUserApproveMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof approvalControllerUserApprove>>, TError,{apprId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof approvalControllerUserApprove>>, TError,{apprId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof approvalControllerUserApprove>>, {apprId: number}> = (props) => {
          const {apprId} = props ?? {};

          return  approvalControllerUserApprove(apprId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ApprovalControllerUserApproveMutationResult = NonNullable<Awaited<ReturnType<typeof approvalControllerUserApprove>>>
    
    export type ApprovalControllerUserApproveMutationError = unknown

    export const useApprovalControllerUserApprove = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof approvalControllerUserApprove>>, TError,{apprId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getApprovalControllerUserApproveMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * approve a approval
 */
export const approvalControllerUserReject = (
    apprId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/approval/approvals/${apprId}/reject`, method: 'post'
    },
      options);
    }
  


export const getApprovalControllerUserRejectMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof approvalControllerUserReject>>, TError,{apprId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof approvalControllerUserReject>>, TError,{apprId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof approvalControllerUserReject>>, {apprId: number}> = (props) => {
          const {apprId} = props ?? {};

          return  approvalControllerUserReject(apprId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ApprovalControllerUserRejectMutationResult = NonNullable<Awaited<ReturnType<typeof approvalControllerUserReject>>>
    
    export type ApprovalControllerUserRejectMutationError = unknown

    export const useApprovalControllerUserReject = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof approvalControllerUserReject>>, TError,{apprId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getApprovalControllerUserRejectMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list audit logs
 */
export const auditControllerListAuditLog = (
    params?: AuditControllerListAuditLogParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AuditListAuditLogResp>(
      {url: `/api/v1/devops/audit`, method: 'get',
        params
    },
      options);
    }
  

export const getAuditControllerListAuditLogQueryKey = (params?: AuditControllerListAuditLogParams,) => [`/api/v1/devops/audit`, ...(params ? [params]: [])] as const;
  

    
export const getAuditControllerListAuditLogQueryOptions = <TData = Awaited<ReturnType<typeof auditControllerListAuditLog>>, TError = unknown>(params?: AuditControllerListAuditLogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof auditControllerListAuditLog>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof auditControllerListAuditLog>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuditControllerListAuditLogQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof auditControllerListAuditLog>>> = () => auditControllerListAuditLog(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type AuditControllerListAuditLogQueryResult = NonNullable<Awaited<ReturnType<typeof auditControllerListAuditLog>>>
export type AuditControllerListAuditLogQueryError = unknown

export const useAuditControllerListAuditLog = <TData = Awaited<ReturnType<typeof auditControllerListAuditLog>>, TError = unknown>(
 params?: AuditControllerListAuditLogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof auditControllerListAuditLog>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAuditControllerListAuditLogQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list audit logs for changes
 */
export const auditControllerListChangeAuditLog = (
    params?: AuditControllerListChangeAuditLogParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AuditListChangeAuditLogResp>(
      {url: `/api/v1/devops/audit/changes`, method: 'get',
        params
    },
      options);
    }
  

export const getAuditControllerListChangeAuditLogQueryKey = (params?: AuditControllerListChangeAuditLogParams,) => [`/api/v1/devops/audit/changes`, ...(params ? [params]: [])] as const;
  

    
export const getAuditControllerListChangeAuditLogQueryOptions = <TData = Awaited<ReturnType<typeof auditControllerListChangeAuditLog>>, TError = unknown>(params?: AuditControllerListChangeAuditLogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof auditControllerListChangeAuditLog>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof auditControllerListChangeAuditLog>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuditControllerListChangeAuditLogQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof auditControllerListChangeAuditLog>>> = () => auditControllerListChangeAuditLog(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type AuditControllerListChangeAuditLogQueryResult = NonNullable<Awaited<ReturnType<typeof auditControllerListChangeAuditLog>>>
export type AuditControllerListChangeAuditLogQueryError = unknown

export const useAuditControllerListChangeAuditLog = <TData = Awaited<ReturnType<typeof auditControllerListChangeAuditLog>>, TError = unknown>(
 params?: AuditControllerListChangeAuditLogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof auditControllerListChangeAuditLog>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAuditControllerListChangeAuditLogQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list audit logs for cluster maintenance
 */
export const auditControllerListClusterMaintenanceAuditLog = (
    params?: AuditControllerListClusterMaintenanceAuditLogParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AuditListClusterMaintenanceAuditLogResp>(
      {url: `/api/v1/devops/audit/cluster-maintenance`, method: 'get',
        params
    },
      options);
    }
  

export const getAuditControllerListClusterMaintenanceAuditLogQueryKey = (params?: AuditControllerListClusterMaintenanceAuditLogParams,) => [`/api/v1/devops/audit/cluster-maintenance`, ...(params ? [params]: [])] as const;
  

    
export const getAuditControllerListClusterMaintenanceAuditLogQueryOptions = <TData = Awaited<ReturnType<typeof auditControllerListClusterMaintenanceAuditLog>>, TError = unknown>(params?: AuditControllerListClusterMaintenanceAuditLogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof auditControllerListClusterMaintenanceAuditLog>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof auditControllerListClusterMaintenanceAuditLog>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuditControllerListClusterMaintenanceAuditLogQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof auditControllerListClusterMaintenanceAuditLog>>> = () => auditControllerListClusterMaintenanceAuditLog(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type AuditControllerListClusterMaintenanceAuditLogQueryResult = NonNullable<Awaited<ReturnType<typeof auditControllerListClusterMaintenanceAuditLog>>>
export type AuditControllerListClusterMaintenanceAuditLogQueryError = unknown

export const useAuditControllerListClusterMaintenanceAuditLog = <TData = Awaited<ReturnType<typeof auditControllerListClusterMaintenanceAuditLog>>, TError = unknown>(
 params?: AuditControllerListClusterMaintenanceAuditLogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof auditControllerListClusterMaintenanceAuditLog>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAuditControllerListClusterMaintenanceAuditLogQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list audit logs for ctl
 */
export const auditControllerListCtlAuditLog = (
    params?: AuditControllerListCtlAuditLogParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AuditListCtlAuditLogResp>(
      {url: `/api/v1/devops/audit/ctl`, method: 'get',
        params
    },
      options);
    }
  

export const getAuditControllerListCtlAuditLogQueryKey = (params?: AuditControllerListCtlAuditLogParams,) => [`/api/v1/devops/audit/ctl`, ...(params ? [params]: [])] as const;
  

    
export const getAuditControllerListCtlAuditLogQueryOptions = <TData = Awaited<ReturnType<typeof auditControllerListCtlAuditLog>>, TError = unknown>(params?: AuditControllerListCtlAuditLogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof auditControllerListCtlAuditLog>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof auditControllerListCtlAuditLog>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuditControllerListCtlAuditLogQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof auditControllerListCtlAuditLog>>> = () => auditControllerListCtlAuditLog(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type AuditControllerListCtlAuditLogQueryResult = NonNullable<Awaited<ReturnType<typeof auditControllerListCtlAuditLog>>>
export type AuditControllerListCtlAuditLogQueryError = unknown

export const useAuditControllerListCtlAuditLog = <TData = Awaited<ReturnType<typeof auditControllerListCtlAuditLog>>, TError = unknown>(
 params?: AuditControllerListCtlAuditLogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof auditControllerListCtlAuditLog>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAuditControllerListCtlAuditLogQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list audit logs for jump authorizations
 */
export const auditControllerListJumpAuditLog = (
    params?: AuditControllerListJumpAuditLogParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AuditListJumpAuditLogResp>(
      {url: `/api/v1/devops/audit/jumps`, method: 'get',
        params
    },
      options);
    }
  

export const getAuditControllerListJumpAuditLogQueryKey = (params?: AuditControllerListJumpAuditLogParams,) => [`/api/v1/devops/audit/jumps`, ...(params ? [params]: [])] as const;
  

    
export const getAuditControllerListJumpAuditLogQueryOptions = <TData = Awaited<ReturnType<typeof auditControllerListJumpAuditLog>>, TError = unknown>(params?: AuditControllerListJumpAuditLogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof auditControllerListJumpAuditLog>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof auditControllerListJumpAuditLog>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuditControllerListJumpAuditLogQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof auditControllerListJumpAuditLog>>> = () => auditControllerListJumpAuditLog(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type AuditControllerListJumpAuditLogQueryResult = NonNullable<Awaited<ReturnType<typeof auditControllerListJumpAuditLog>>>
export type AuditControllerListJumpAuditLogQueryError = unknown

export const useAuditControllerListJumpAuditLog = <TData = Awaited<ReturnType<typeof auditControllerListJumpAuditLog>>, TError = unknown>(
 params?: AuditControllerListJumpAuditLogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof auditControllerListJumpAuditLog>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAuditControllerListJumpAuditLogQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list audit logs for operation instances
 */
export const auditControllerListOperationAuditLog = (
    params?: AuditControllerListOperationAuditLogParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AuditListOperationAuditLogResp>(
      {url: `/api/v1/devops/audit/operations`, method: 'get',
        params
    },
      options);
    }
  

export const getAuditControllerListOperationAuditLogQueryKey = (params?: AuditControllerListOperationAuditLogParams,) => [`/api/v1/devops/audit/operations`, ...(params ? [params]: [])] as const;
  

    
export const getAuditControllerListOperationAuditLogQueryOptions = <TData = Awaited<ReturnType<typeof auditControllerListOperationAuditLog>>, TError = unknown>(params?: AuditControllerListOperationAuditLogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof auditControllerListOperationAuditLog>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof auditControllerListOperationAuditLog>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuditControllerListOperationAuditLogQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof auditControllerListOperationAuditLog>>> = () => auditControllerListOperationAuditLog(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type AuditControllerListOperationAuditLogQueryResult = NonNullable<Awaited<ReturnType<typeof auditControllerListOperationAuditLog>>>
export type AuditControllerListOperationAuditLogQueryError = unknown

export const useAuditControllerListOperationAuditLog = <TData = Awaited<ReturnType<typeof auditControllerListOperationAuditLog>>, TError = unknown>(
 params?: AuditControllerListOperationAuditLogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof auditControllerListOperationAuditLog>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAuditControllerListOperationAuditLogQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * clone paramter attributes from old version
 */
export const cloudConfigControllerCloneParameterAttribute = (
    cloudconfigCloneParamAttrReq: CloudconfigCloneParamAttrReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cloudconfig-admin/parameter/attribute/clone`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: cloudconfigCloneParamAttrReq
    },
      options);
    }
  


export const getCloudConfigControllerCloneParameterAttributeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCloneParameterAttribute>>, TError,{data: CloudconfigCloneParamAttrReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCloneParameterAttribute>>, TError,{data: CloudconfigCloneParamAttrReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudConfigControllerCloneParameterAttribute>>, {data: CloudconfigCloneParamAttrReq}> = (props) => {
          const {data} = props ?? {};

          return  cloudConfigControllerCloneParameterAttribute(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloudConfigControllerCloneParameterAttributeMutationResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerCloneParameterAttribute>>>
    export type CloudConfigControllerCloneParameterAttributeMutationBody = CloudconfigCloneParamAttrReq
    export type CloudConfigControllerCloneParameterAttributeMutationError = unknown

    export const useCloudConfigControllerCloneParameterAttribute = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCloneParameterAttribute>>, TError,{data: CloudconfigCloneParamAttrReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloudConfigControllerCloneParameterAttributeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * create a paramter attribute
 */
export const cloudConfigControllerCreateParameterAttribute = (
    cloudconfigCreateParamAttrReq: CloudconfigCreateParamAttrReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cloudconfig-admin/parameter/attribute/create`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: cloudconfigCreateParamAttrReq
    },
      options);
    }
  


export const getCloudConfigControllerCreateParameterAttributeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCreateParameterAttribute>>, TError,{data: CloudconfigCreateParamAttrReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCreateParameterAttribute>>, TError,{data: CloudconfigCreateParamAttrReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudConfigControllerCreateParameterAttribute>>, {data: CloudconfigCreateParamAttrReq}> = (props) => {
          const {data} = props ?? {};

          return  cloudConfigControllerCreateParameterAttribute(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloudConfigControllerCreateParameterAttributeMutationResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerCreateParameterAttribute>>>
    export type CloudConfigControllerCreateParameterAttributeMutationBody = CloudconfigCreateParamAttrReq
    export type CloudConfigControllerCreateParameterAttributeMutationError = unknown

    export const useCloudConfigControllerCreateParameterAttribute = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCreateParameterAttribute>>, TError,{data: CloudconfigCreateParamAttrReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloudConfigControllerCreateParameterAttributeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * update a parameter attribute
 */
export const cloudConfigControllerUpdateParameterAttribute = (
    configType: string,
    version: string,
    paramName: string,
    cloudconfigUpdateParamAttrReq: CloudconfigUpdateParamAttrReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cloudconfig-admin/parameter/attribute/${configType}/${version}/${paramName}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: cloudconfigUpdateParamAttrReq
    },
      options);
    }
  


export const getCloudConfigControllerUpdateParameterAttributeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerUpdateParameterAttribute>>, TError,{configType: string;version: string;paramName: string;data: CloudconfigUpdateParamAttrReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerUpdateParameterAttribute>>, TError,{configType: string;version: string;paramName: string;data: CloudconfigUpdateParamAttrReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudConfigControllerUpdateParameterAttribute>>, {configType: string;version: string;paramName: string;data: CloudconfigUpdateParamAttrReq}> = (props) => {
          const {configType,version,paramName,data} = props ?? {};

          return  cloudConfigControllerUpdateParameterAttribute(configType,version,paramName,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloudConfigControllerUpdateParameterAttributeMutationResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerUpdateParameterAttribute>>>
    export type CloudConfigControllerUpdateParameterAttributeMutationBody = CloudconfigUpdateParamAttrReq
    export type CloudConfigControllerUpdateParameterAttributeMutationError = unknown

    export const useCloudConfigControllerUpdateParameterAttribute = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerUpdateParameterAttribute>>, TError,{configType: string;version: string;paramName: string;data: CloudconfigUpdateParamAttrReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloudConfigControllerUpdateParameterAttributeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get a config change of a cluster
 */
export const cloudConfigControllerGetConfigChange = (
    changeId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigGetConfigurationChangeResp>(
      {url: `/api/v1/devops/cloudconfig/changes/${changeId}`, method: 'get'
    },
      options);
    }
  

export const getCloudConfigControllerGetConfigChangeQueryKey = (changeId: string,) => [`/api/v1/devops/cloudconfig/changes/${changeId}`] as const;
  

    
export const getCloudConfigControllerGetConfigChangeQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerGetConfigChange>>, TError = ApiBaseResp>(changeId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetConfigChange>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetConfigChange>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerGetConfigChangeQueryKey(changeId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerGetConfigChange>>> = () => cloudConfigControllerGetConfigChange(changeId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(changeId), ...queryOptions}}

export type CloudConfigControllerGetConfigChangeQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerGetConfigChange>>>
export type CloudConfigControllerGetConfigChangeQueryError = ApiBaseResp

export const useCloudConfigControllerGetConfigChange = <TData = Awaited<ReturnType<typeof cloudConfigControllerGetConfigChange>>, TError = ApiBaseResp>(
 changeId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetConfigChange>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerGetConfigChangeQueryOptions(changeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * aplly a config change
 */
export const cloudConfigControllerApplyConfigurationChange = (
    changeId: string,
    cloudconfigApplyConfigurationChangeReq: CloudconfigApplyConfigurationChangeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cloudconfig/changes/${changeId}/apply`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: cloudconfigApplyConfigurationChangeReq
    },
      options);
    }
  


export const getCloudConfigControllerApplyConfigurationChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerApplyConfigurationChange>>, TError,{changeId: string;data: CloudconfigApplyConfigurationChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerApplyConfigurationChange>>, TError,{changeId: string;data: CloudconfigApplyConfigurationChangeReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudConfigControllerApplyConfigurationChange>>, {changeId: string;data: CloudconfigApplyConfigurationChangeReq}> = (props) => {
          const {changeId,data} = props ?? {};

          return  cloudConfigControllerApplyConfigurationChange(changeId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloudConfigControllerApplyConfigurationChangeMutationResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerApplyConfigurationChange>>>
    export type CloudConfigControllerApplyConfigurationChangeMutationBody = CloudconfigApplyConfigurationChangeReq
    export type CloudConfigControllerApplyConfigurationChangeMutationError = unknown

    export const useCloudConfigControllerApplyConfigurationChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerApplyConfigurationChange>>, TError,{changeId: string;data: CloudconfigApplyConfigurationChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloudConfigControllerApplyConfigurationChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * cancel a config change
 */
export const cloudConfigControllerCancelConfigurationChange = (
    changeId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cloudconfig/changes/${changeId}/cancel`, method: 'post'
    },
      options);
    }
  


export const getCloudConfigControllerCancelConfigurationChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCancelConfigurationChange>>, TError,{changeId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCancelConfigurationChange>>, TError,{changeId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudConfigControllerCancelConfigurationChange>>, {changeId: string}> = (props) => {
          const {changeId} = props ?? {};

          return  cloudConfigControllerCancelConfigurationChange(changeId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloudConfigControllerCancelConfigurationChangeMutationResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerCancelConfigurationChange>>>
    
    export type CloudConfigControllerCancelConfigurationChangeMutationError = unknown

    export const useCloudConfigControllerCancelConfigurationChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCancelConfigurationChange>>, TError,{changeId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloudConfigControllerCancelConfigurationChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list config changes
 */
export const cloudConfigControllerListClusterConfigurationChanges = (
    params: CloudConfigControllerListClusterConfigurationChangesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigListConfigurationChangeResp>(
      {url: `/api/v1/devops/cloudconfig/cluster/changes`, method: 'get',
        params
    },
      options);
    }
  

export const getCloudConfigControllerListClusterConfigurationChangesQueryKey = (params: CloudConfigControllerListClusterConfigurationChangesParams,) => [`/api/v1/devops/cloudconfig/cluster/changes`, ...(params ? [params]: [])] as const;
  

    
export const getCloudConfigControllerListClusterConfigurationChangesQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerListClusterConfigurationChanges>>, TError = ApiBaseResp>(params: CloudConfigControllerListClusterConfigurationChangesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListClusterConfigurationChanges>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListClusterConfigurationChanges>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerListClusterConfigurationChangesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerListClusterConfigurationChanges>>> = () => cloudConfigControllerListClusterConfigurationChanges(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type CloudConfigControllerListClusterConfigurationChangesQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerListClusterConfigurationChanges>>>
export type CloudConfigControllerListClusterConfigurationChangesQueryError = ApiBaseResp

export const useCloudConfigControllerListClusterConfigurationChanges = <TData = Awaited<ReturnType<typeof cloudConfigControllerListClusterConfigurationChanges>>, TError = ApiBaseResp>(
 params: CloudConfigControllerListClusterConfigurationChangesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListClusterConfigurationChanges>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerListClusterConfigurationChangesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * change the configuration of a cluster
 */
export const cloudConfigControllerCreateClusterConfigurationChange = (
    cloudconfigCreateClusterConfigurationChangeReq: CloudconfigCreateClusterConfigurationChangeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigCreateConfigurationChangeResp>(
      {url: `/api/v1/devops/cloudconfig/cluster/changes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: cloudconfigCreateClusterConfigurationChangeReq
    },
      options);
    }
  


export const getCloudConfigControllerCreateClusterConfigurationChangeMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCreateClusterConfigurationChange>>, TError,{data: CloudconfigCreateClusterConfigurationChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCreateClusterConfigurationChange>>, TError,{data: CloudconfigCreateClusterConfigurationChangeReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudConfigControllerCreateClusterConfigurationChange>>, {data: CloudconfigCreateClusterConfigurationChangeReq}> = (props) => {
          const {data} = props ?? {};

          return  cloudConfigControllerCreateClusterConfigurationChange(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloudConfigControllerCreateClusterConfigurationChangeMutationResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerCreateClusterConfigurationChange>>>
    export type CloudConfigControllerCreateClusterConfigurationChangeMutationBody = CloudconfigCreateClusterConfigurationChangeReq
    export type CloudConfigControllerCreateClusterConfigurationChangeMutationError = ApiBaseResp

    export const useCloudConfigControllerCreateClusterConfigurationChange = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCreateClusterConfigurationChange>>, TError,{data: CloudconfigCreateClusterConfigurationChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloudConfigControllerCreateClusterConfigurationChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * change the configuration of a cluster
 */
export const cloudConfigControllerPreviewClusterConfigurationChange = (
    cloudconfigPreviewClusterConfigurationChangeReq: CloudconfigPreviewClusterConfigurationChangeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigPreviewConfigurationChangeResp>(
      {url: `/api/v1/devops/cloudconfig/cluster/changes/preview`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: cloudconfigPreviewClusterConfigurationChangeReq
    },
      options);
    }
  


export const getCloudConfigControllerPreviewClusterConfigurationChangeMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerPreviewClusterConfigurationChange>>, TError,{data: CloudconfigPreviewClusterConfigurationChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerPreviewClusterConfigurationChange>>, TError,{data: CloudconfigPreviewClusterConfigurationChangeReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudConfigControllerPreviewClusterConfigurationChange>>, {data: CloudconfigPreviewClusterConfigurationChangeReq}> = (props) => {
          const {data} = props ?? {};

          return  cloudConfigControllerPreviewClusterConfigurationChange(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloudConfigControllerPreviewClusterConfigurationChangeMutationResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerPreviewClusterConfigurationChange>>>
    export type CloudConfigControllerPreviewClusterConfigurationChangeMutationBody = CloudconfigPreviewClusterConfigurationChangeReq
    export type CloudConfigControllerPreviewClusterConfigurationChangeMutationError = ApiBaseResp

    export const useCloudConfigControllerPreviewClusterConfigurationChange = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerPreviewClusterConfigurationChange>>, TError,{data: CloudconfigPreviewClusterConfigurationChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloudConfigControllerPreviewClusterConfigurationChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get the current configuration of a cluster
 */
export const cloudConfigControllerGetClusterConfiguration = (
    params: CloudConfigControllerGetClusterConfigurationParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigGetConfigurationResp>(
      {url: `/api/v1/devops/cloudconfig/cluster/config`, method: 'get',
        params
    },
      options);
    }
  

export const getCloudConfigControllerGetClusterConfigurationQueryKey = (params: CloudConfigControllerGetClusterConfigurationParams,) => [`/api/v1/devops/cloudconfig/cluster/config`, ...(params ? [params]: [])] as const;
  

    
export const getCloudConfigControllerGetClusterConfigurationQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerGetClusterConfiguration>>, TError = ApiBaseResp>(params: CloudConfigControllerGetClusterConfigurationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetClusterConfiguration>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetClusterConfiguration>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerGetClusterConfigurationQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerGetClusterConfiguration>>> = () => cloudConfigControllerGetClusterConfiguration(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type CloudConfigControllerGetClusterConfigurationQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerGetClusterConfiguration>>>
export type CloudConfigControllerGetClusterConfigurationQueryError = ApiBaseResp

export const useCloudConfigControllerGetClusterConfiguration = <TData = Awaited<ReturnType<typeof cloudConfigControllerGetClusterConfiguration>>, TError = ApiBaseResp>(
 params: CloudConfigControllerGetClusterConfigurationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetClusterConfiguration>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerGetClusterConfigurationQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list available config types
 */
export const cloudConfigControllerListConfigTypes = (
    clusterType: 'DEDICATED_TIDB' | 'TICDC' | 'TIPROXY',
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigListConfigTypesResp>(
      {url: `/api/v1/devops/cloudconfig/config-types/${clusterType}`, method: 'get'
    },
      options);
    }
  

export const getCloudConfigControllerListConfigTypesQueryKey = (clusterType: 'DEDICATED_TIDB' | 'TICDC' | 'TIPROXY',) => [`/api/v1/devops/cloudconfig/config-types/${clusterType}`] as const;
  

    
export const getCloudConfigControllerListConfigTypesQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerListConfigTypes>>, TError = unknown>(clusterType: 'DEDICATED_TIDB' | 'TICDC' | 'TIPROXY', options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListConfigTypes>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListConfigTypes>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerListConfigTypesQueryKey(clusterType);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerListConfigTypes>>> = () => cloudConfigControllerListConfigTypes(clusterType, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(clusterType), ...queryOptions}}

export type CloudConfigControllerListConfigTypesQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerListConfigTypes>>>
export type CloudConfigControllerListConfigTypesQueryError = unknown

export const useCloudConfigControllerListConfigTypes = <TData = Awaited<ReturnType<typeof cloudConfigControllerListConfigTypes>>, TError = unknown>(
 clusterType: 'DEDICATED_TIDB' | 'TICDC' | 'TIPROXY', options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListConfigTypes>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerListConfigTypesQueryOptions(clusterType,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list available instance types
 */
export const cloudConfigControllerListInstanceTypes = (
    clusterType: 'DEDICATED_TIDB' | 'TICDC' | 'TIPROXY',
    params?: CloudConfigControllerListInstanceTypesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigListInstanceTypesResp>(
      {url: `/api/v1/devops/cloudconfig/instance-types/${clusterType}`, method: 'get',
        params
    },
      options);
    }
  

export const getCloudConfigControllerListInstanceTypesQueryKey = (clusterType: 'DEDICATED_TIDB' | 'TICDC' | 'TIPROXY',
    params?: CloudConfigControllerListInstanceTypesParams,) => [`/api/v1/devops/cloudconfig/instance-types/${clusterType}`, ...(params ? [params]: [])] as const;
  

    
export const getCloudConfigControllerListInstanceTypesQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerListInstanceTypes>>, TError = unknown>(clusterType: 'DEDICATED_TIDB' | 'TICDC' | 'TIPROXY',
    params?: CloudConfigControllerListInstanceTypesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListInstanceTypes>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListInstanceTypes>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerListInstanceTypesQueryKey(clusterType,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerListInstanceTypes>>> = () => cloudConfigControllerListInstanceTypes(clusterType,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(clusterType), ...queryOptions}}

export type CloudConfigControllerListInstanceTypesQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerListInstanceTypes>>>
export type CloudConfigControllerListInstanceTypesQueryError = unknown

export const useCloudConfigControllerListInstanceTypes = <TData = Awaited<ReturnType<typeof cloudConfigControllerListInstanceTypes>>, TError = unknown>(
 clusterType: 'DEDICATED_TIDB' | 'TICDC' | 'TIPROXY',
    params?: CloudConfigControllerListInstanceTypesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListInstanceTypes>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerListInstanceTypesQueryOptions(clusterType,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list parameter attributes
 */
export const cloudConfigControllerListParameterAttributes = (
    configtype: string,
    version: string,
    params?: CloudConfigControllerListParameterAttributesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigListParamAttrResp>(
      {url: `/api/v1/devops/cloudconfig/parameter/attribute/${configtype}/${version}`, method: 'get',
        params
    },
      options);
    }
  

export const getCloudConfigControllerListParameterAttributesQueryKey = (configtype: string,
    version: string,
    params?: CloudConfigControllerListParameterAttributesParams,) => [`/api/v1/devops/cloudconfig/parameter/attribute/${configtype}/${version}`, ...(params ? [params]: [])] as const;
  

    
export const getCloudConfigControllerListParameterAttributesQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerListParameterAttributes>>, TError = unknown>(configtype: string,
    version: string,
    params?: CloudConfigControllerListParameterAttributesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListParameterAttributes>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListParameterAttributes>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerListParameterAttributesQueryKey(configtype,version,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerListParameterAttributes>>> = () => cloudConfigControllerListParameterAttributes(configtype,version,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(configtype && version), ...queryOptions}}

export type CloudConfigControllerListParameterAttributesQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerListParameterAttributes>>>
export type CloudConfigControllerListParameterAttributesQueryError = unknown

export const useCloudConfigControllerListParameterAttributes = <TData = Awaited<ReturnType<typeof cloudConfigControllerListParameterAttributes>>, TError = unknown>(
 configtype: string,
    version: string,
    params?: CloudConfigControllerListParameterAttributesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListParameterAttributes>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerListParameterAttributesQueryOptions(configtype,version,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get a parameter attribute
 */
export const cloudConfigControllerGetParameterAttribute = (
    configtype: string,
    version: string,
    paramName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigGetParamAttrResp>(
      {url: `/api/v1/devops/cloudconfig/parameter/attribute/${configtype}/${version}/${paramName}`, method: 'get'
    },
      options);
    }
  

export const getCloudConfigControllerGetParameterAttributeQueryKey = (configtype: string,
    version: string,
    paramName: string,) => [`/api/v1/devops/cloudconfig/parameter/attribute/${configtype}/${version}/${paramName}`] as const;
  

    
export const getCloudConfigControllerGetParameterAttributeQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerGetParameterAttribute>>, TError = unknown>(configtype: string,
    version: string,
    paramName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetParameterAttribute>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetParameterAttribute>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerGetParameterAttributeQueryKey(configtype,version,paramName);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerGetParameterAttribute>>> = () => cloudConfigControllerGetParameterAttribute(configtype,version,paramName, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(configtype && version && paramName), ...queryOptions}}

export type CloudConfigControllerGetParameterAttributeQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerGetParameterAttribute>>>
export type CloudConfigControllerGetParameterAttributeQueryError = unknown

export const useCloudConfigControllerGetParameterAttribute = <TData = Awaited<ReturnType<typeof cloudConfigControllerGetParameterAttribute>>, TError = unknown>(
 configtype: string,
    version: string,
    paramName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetParameterAttribute>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerGetParameterAttributeQueryOptions(configtype,version,paramName,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list available versions from dict
 */
export const cloudConfigControllerListParameterVersions = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigListParamVersionsResp>(
      {url: `/api/v1/devops/cloudconfig/parameter/versions`, method: 'get'
    },
      options);
    }
  

export const getCloudConfigControllerListParameterVersionsQueryKey = () => [`/api/v1/devops/cloudconfig/parameter/versions`] as const;
  

    
export const getCloudConfigControllerListParameterVersionsQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerListParameterVersions>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListParameterVersions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListParameterVersions>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerListParameterVersionsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerListParameterVersions>>> = () => cloudConfigControllerListParameterVersions(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type CloudConfigControllerListParameterVersionsQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerListParameterVersions>>>
export type CloudConfigControllerListParameterVersionsQueryError = unknown

export const useCloudConfigControllerListParameterVersions = <TData = Awaited<ReturnType<typeof cloudConfigControllerListParameterVersions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListParameterVersions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerListParameterVersionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list template changes
 */
export const cloudConfigControllerListTemplateChanges = (
    params?: CloudConfigControllerListTemplateChangesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigListTemplateChangesResp>(
      {url: `/api/v1/devops/cloudconfig/template/changes`, method: 'get',
        params
    },
      options);
    }
  

export const getCloudConfigControllerListTemplateChangesQueryKey = (params?: CloudConfigControllerListTemplateChangesParams,) => [`/api/v1/devops/cloudconfig/template/changes`, ...(params ? [params]: [])] as const;
  

    
export const getCloudConfigControllerListTemplateChangesQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerListTemplateChanges>>, TError = ApiBaseResp>(params?: CloudConfigControllerListTemplateChangesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListTemplateChanges>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListTemplateChanges>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerListTemplateChangesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerListTemplateChanges>>> = () => cloudConfigControllerListTemplateChanges(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type CloudConfigControllerListTemplateChangesQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerListTemplateChanges>>>
export type CloudConfigControllerListTemplateChangesQueryError = ApiBaseResp

export const useCloudConfigControllerListTemplateChanges = <TData = Awaited<ReturnType<typeof cloudConfigControllerListTemplateChanges>>, TError = ApiBaseResp>(
 params?: CloudConfigControllerListTemplateChangesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListTemplateChanges>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerListTemplateChangesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create a template change
 */
export const cloudConfigControllerCreateTemplateChange = (
    cloudconfigCreateTemplateChangeReq: CloudconfigCreateTemplateChangeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigCreateTemplateChangeResp>(
      {url: `/api/v1/devops/cloudconfig/template/changes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: cloudconfigCreateTemplateChangeReq
    },
      options);
    }
  


export const getCloudConfigControllerCreateTemplateChangeMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCreateTemplateChange>>, TError,{data: CloudconfigCreateTemplateChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCreateTemplateChange>>, TError,{data: CloudconfigCreateTemplateChangeReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudConfigControllerCreateTemplateChange>>, {data: CloudconfigCreateTemplateChangeReq}> = (props) => {
          const {data} = props ?? {};

          return  cloudConfigControllerCreateTemplateChange(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloudConfigControllerCreateTemplateChangeMutationResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerCreateTemplateChange>>>
    export type CloudConfigControllerCreateTemplateChangeMutationBody = CloudconfigCreateTemplateChangeReq
    export type CloudConfigControllerCreateTemplateChangeMutationError = ApiBaseResp

    export const useCloudConfigControllerCreateTemplateChange = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCreateTemplateChange>>, TError,{data: CloudconfigCreateTemplateChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloudConfigControllerCreateTemplateChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get a template change
 */
export const cloudConfigControllerGetTemplateChange = (
    changeId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigGetTemplateChangeResp>(
      {url: `/api/v1/devops/cloudconfig/template/changes/${changeId}`, method: 'get'
    },
      options);
    }
  

export const getCloudConfigControllerGetTemplateChangeQueryKey = (changeId: string,) => [`/api/v1/devops/cloudconfig/template/changes/${changeId}`] as const;
  

    
export const getCloudConfigControllerGetTemplateChangeQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerGetTemplateChange>>, TError = ApiBaseResp>(changeId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetTemplateChange>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetTemplateChange>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerGetTemplateChangeQueryKey(changeId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerGetTemplateChange>>> = () => cloudConfigControllerGetTemplateChange(changeId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(changeId), ...queryOptions}}

export type CloudConfigControllerGetTemplateChangeQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerGetTemplateChange>>>
export type CloudConfigControllerGetTemplateChangeQueryError = ApiBaseResp

export const useCloudConfigControllerGetTemplateChange = <TData = Awaited<ReturnType<typeof cloudConfigControllerGetTemplateChange>>, TError = ApiBaseResp>(
 changeId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetTemplateChange>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerGetTemplateChangeQueryOptions(changeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update a template change
 */
export const cloudConfigControllerUpdateTemplateChange = (
    changeId: string,
    cloudconfigUpdateTemplateChangeReq: CloudconfigUpdateTemplateChangeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cloudconfig/template/changes/${changeId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: cloudconfigUpdateTemplateChangeReq
    },
      options);
    }
  


export const getCloudConfigControllerUpdateTemplateChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerUpdateTemplateChange>>, TError,{changeId: string;data: CloudconfigUpdateTemplateChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerUpdateTemplateChange>>, TError,{changeId: string;data: CloudconfigUpdateTemplateChangeReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudConfigControllerUpdateTemplateChange>>, {changeId: string;data: CloudconfigUpdateTemplateChangeReq}> = (props) => {
          const {changeId,data} = props ?? {};

          return  cloudConfigControllerUpdateTemplateChange(changeId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloudConfigControllerUpdateTemplateChangeMutationResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerUpdateTemplateChange>>>
    export type CloudConfigControllerUpdateTemplateChangeMutationBody = CloudconfigUpdateTemplateChangeReq
    export type CloudConfigControllerUpdateTemplateChangeMutationError = unknown

    export const useCloudConfigControllerUpdateTemplateChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerUpdateTemplateChange>>, TError,{changeId: string;data: CloudconfigUpdateTemplateChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloudConfigControllerUpdateTemplateChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * close a template change
 */
export const cloudConfigControllerCloseTemplateChange = (
    changeId: string,
    cloudconfigCloseTemplateChangeReq: CloudconfigCloseTemplateChangeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cloudconfig/template/changes/${changeId}/close`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: cloudconfigCloseTemplateChangeReq
    },
      options);
    }
  


export const getCloudConfigControllerCloseTemplateChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCloseTemplateChange>>, TError,{changeId: string;data: CloudconfigCloseTemplateChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCloseTemplateChange>>, TError,{changeId: string;data: CloudconfigCloseTemplateChangeReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudConfigControllerCloseTemplateChange>>, {changeId: string;data: CloudconfigCloseTemplateChangeReq}> = (props) => {
          const {changeId,data} = props ?? {};

          return  cloudConfigControllerCloseTemplateChange(changeId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloudConfigControllerCloseTemplateChangeMutationResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerCloseTemplateChange>>>
    export type CloudConfigControllerCloseTemplateChangeMutationBody = CloudconfigCloseTemplateChangeReq
    export type CloudConfigControllerCloseTemplateChangeMutationError = unknown

    export const useCloudConfigControllerCloseTemplateChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCloseTemplateChange>>, TError,{changeId: string;data: CloudconfigCloseTemplateChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloudConfigControllerCloseTemplateChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * diff a template change release for a environment
 */
export const cloudConfigControllerDiffTemplateChangeRelease = (
    changeId: string,
    params: CloudConfigControllerDiffTemplateChangeReleaseParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigDiffTemplateChangeReleaseResp>(
      {url: `/api/v1/devops/cloudconfig/template/changes/${changeId}/diff-release`, method: 'get',
        params
    },
      options);
    }
  

export const getCloudConfigControllerDiffTemplateChangeReleaseQueryKey = (changeId: string,
    params: CloudConfigControllerDiffTemplateChangeReleaseParams,) => [`/api/v1/devops/cloudconfig/template/changes/${changeId}/diff-release`, ...(params ? [params]: [])] as const;
  

    
export const getCloudConfigControllerDiffTemplateChangeReleaseQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerDiffTemplateChangeRelease>>, TError = ApiBaseResp>(changeId: string,
    params: CloudConfigControllerDiffTemplateChangeReleaseParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerDiffTemplateChangeRelease>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerDiffTemplateChangeRelease>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerDiffTemplateChangeReleaseQueryKey(changeId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerDiffTemplateChangeRelease>>> = () => cloudConfigControllerDiffTemplateChangeRelease(changeId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(changeId), ...queryOptions}}

export type CloudConfigControllerDiffTemplateChangeReleaseQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerDiffTemplateChangeRelease>>>
export type CloudConfigControllerDiffTemplateChangeReleaseQueryError = ApiBaseResp

export const useCloudConfigControllerDiffTemplateChangeRelease = <TData = Awaited<ReturnType<typeof cloudConfigControllerDiffTemplateChangeRelease>>, TError = ApiBaseResp>(
 changeId: string,
    params: CloudConfigControllerDiffTemplateChangeReleaseParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerDiffTemplateChangeRelease>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerDiffTemplateChangeReleaseQueryOptions(changeId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * release a template change to a environment
 */
export const cloudConfigControllerReleaseTemplateChange = (
    changeId: string,
    cloudconfigReleaseTemplateChangeReq: CloudconfigReleaseTemplateChangeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cloudconfig/template/changes/${changeId}/release`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: cloudconfigReleaseTemplateChangeReq
    },
      options);
    }
  


export const getCloudConfigControllerReleaseTemplateChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerReleaseTemplateChange>>, TError,{changeId: string;data: CloudconfigReleaseTemplateChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerReleaseTemplateChange>>, TError,{changeId: string;data: CloudconfigReleaseTemplateChangeReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudConfigControllerReleaseTemplateChange>>, {changeId: string;data: CloudconfigReleaseTemplateChangeReq}> = (props) => {
          const {changeId,data} = props ?? {};

          return  cloudConfigControllerReleaseTemplateChange(changeId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloudConfigControllerReleaseTemplateChangeMutationResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerReleaseTemplateChange>>>
    export type CloudConfigControllerReleaseTemplateChangeMutationBody = CloudconfigReleaseTemplateChangeReq
    export type CloudConfigControllerReleaseTemplateChangeMutationError = unknown

    export const useCloudConfigControllerReleaseTemplateChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerReleaseTemplateChange>>, TError,{changeId: string;data: CloudconfigReleaseTemplateChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloudConfigControllerReleaseTemplateChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list template default cases
 */
export const cloudConfigControllerListTemplateDefaultCases = (
    params: CloudConfigControllerListTemplateDefaultCasesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigListTemplateDefaultCasesResp>(
      {url: `/api/v1/devops/cloudconfig/template/defaultcases`, method: 'get',
        params
    },
      options);
    }
  

export const getCloudConfigControllerListTemplateDefaultCasesQueryKey = (params: CloudConfigControllerListTemplateDefaultCasesParams,) => [`/api/v1/devops/cloudconfig/template/defaultcases`, ...(params ? [params]: [])] as const;
  

    
export const getCloudConfigControllerListTemplateDefaultCasesQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerListTemplateDefaultCases>>, TError = unknown>(params: CloudConfigControllerListTemplateDefaultCasesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListTemplateDefaultCases>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListTemplateDefaultCases>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerListTemplateDefaultCasesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerListTemplateDefaultCases>>> = () => cloudConfigControllerListTemplateDefaultCases(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type CloudConfigControllerListTemplateDefaultCasesQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerListTemplateDefaultCases>>>
export type CloudConfigControllerListTemplateDefaultCasesQueryError = unknown

export const useCloudConfigControllerListTemplateDefaultCases = <TData = Awaited<ReturnType<typeof cloudConfigControllerListTemplateDefaultCases>>, TError = unknown>(
 params: CloudConfigControllerListTemplateDefaultCasesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListTemplateDefaultCases>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerListTemplateDefaultCasesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update template default cases
 */
export const cloudConfigControllerUpdateTemplateDefaultCases = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cloudconfig/template/defaultcases`, method: 'patch'
    },
      options);
    }
  


export const getCloudConfigControllerUpdateTemplateDefaultCasesMutationOptions = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerUpdateTemplateDefaultCases>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerUpdateTemplateDefaultCases>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudConfigControllerUpdateTemplateDefaultCases>>, TVariables> = () => {
          

          return  cloudConfigControllerUpdateTemplateDefaultCases(requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloudConfigControllerUpdateTemplateDefaultCasesMutationResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerUpdateTemplateDefaultCases>>>
    
    export type CloudConfigControllerUpdateTemplateDefaultCasesMutationError = unknown

    export const useCloudConfigControllerUpdateTemplateDefaultCases = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerUpdateTemplateDefaultCases>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloudConfigControllerUpdateTemplateDefaultCasesMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list templates
 */
export const cloudConfigControllerListTemplates = (
    params: CloudConfigControllerListTemplatesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigListTemplatesResp>(
      {url: `/api/v1/devops/cloudconfig/template/templates`, method: 'get',
        params
    },
      options);
    }
  

export const getCloudConfigControllerListTemplatesQueryKey = (params: CloudConfigControllerListTemplatesParams,) => [`/api/v1/devops/cloudconfig/template/templates`, ...(params ? [params]: [])] as const;
  

    
export const getCloudConfigControllerListTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerListTemplates>>, TError = unknown>(params: CloudConfigControllerListTemplatesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListTemplates>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListTemplates>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerListTemplatesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerListTemplates>>> = () => cloudConfigControllerListTemplates(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type CloudConfigControllerListTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerListTemplates>>>
export type CloudConfigControllerListTemplatesQueryError = unknown

export const useCloudConfigControllerListTemplates = <TData = Awaited<ReturnType<typeof cloudConfigControllerListTemplates>>, TError = unknown>(
 params: CloudConfigControllerListTemplatesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListTemplates>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerListTemplatesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create a template
 */
export const cloudConfigControllerCreateTemplate = (
    cloudconfigCreateTemplateReq: CloudconfigCreateTemplateReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigCreateTemplateResp>(
      {url: `/api/v1/devops/cloudconfig/template/templates`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: cloudconfigCreateTemplateReq
    },
      options);
    }
  


export const getCloudConfigControllerCreateTemplateMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCreateTemplate>>, TError,{data: CloudconfigCreateTemplateReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCreateTemplate>>, TError,{data: CloudconfigCreateTemplateReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudConfigControllerCreateTemplate>>, {data: CloudconfigCreateTemplateReq}> = (props) => {
          const {data} = props ?? {};

          return  cloudConfigControllerCreateTemplate(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloudConfigControllerCreateTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerCreateTemplate>>>
    export type CloudConfigControllerCreateTemplateMutationBody = CloudconfigCreateTemplateReq
    export type CloudConfigControllerCreateTemplateMutationError = ApiBaseResp

    export const useCloudConfigControllerCreateTemplate = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCreateTemplate>>, TError,{data: CloudconfigCreateTemplateReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloudConfigControllerCreateTemplateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get a template
 */
export const cloudConfigControllerGetTemplate = (
    templateId: string,
    params: CloudConfigControllerGetTemplateParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigGetTemplateResp>(
      {url: `/api/v1/devops/cloudconfig/template/templates/${templateId}`, method: 'get',
        params
    },
      options);
    }
  

export const getCloudConfigControllerGetTemplateQueryKey = (templateId: string,
    params: CloudConfigControllerGetTemplateParams,) => [`/api/v1/devops/cloudconfig/template/templates/${templateId}`, ...(params ? [params]: [])] as const;
  

    
export const getCloudConfigControllerGetTemplateQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerGetTemplate>>, TError = unknown>(templateId: string,
    params: CloudConfigControllerGetTemplateParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetTemplate>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetTemplate>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerGetTemplateQueryKey(templateId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerGetTemplate>>> = () => cloudConfigControllerGetTemplate(templateId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(templateId), ...queryOptions}}

export type CloudConfigControllerGetTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerGetTemplate>>>
export type CloudConfigControllerGetTemplateQueryError = unknown

export const useCloudConfigControllerGetTemplate = <TData = Awaited<ReturnType<typeof cloudConfigControllerGetTemplate>>, TError = unknown>(
 templateId: string,
    params: CloudConfigControllerGetTemplateParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetTemplate>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerGetTemplateQueryOptions(templateId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list config changes
 */
export const cloudConfigControllerListTenantInitConfigurationChanges = (
    params: CloudConfigControllerListTenantInitConfigurationChangesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigListConfigurationChangeResp>(
      {url: `/api/v1/devops/cloudconfig/tenantinit/changes`, method: 'get',
        params
    },
      options);
    }
  

export const getCloudConfigControllerListTenantInitConfigurationChangesQueryKey = (params: CloudConfigControllerListTenantInitConfigurationChangesParams,) => [`/api/v1/devops/cloudconfig/tenantinit/changes`, ...(params ? [params]: [])] as const;
  

    
export const getCloudConfigControllerListTenantInitConfigurationChangesQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerListTenantInitConfigurationChanges>>, TError = unknown>(params: CloudConfigControllerListTenantInitConfigurationChangesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListTenantInitConfigurationChanges>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListTenantInitConfigurationChanges>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerListTenantInitConfigurationChangesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerListTenantInitConfigurationChanges>>> = () => cloudConfigControllerListTenantInitConfigurationChanges(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type CloudConfigControllerListTenantInitConfigurationChangesQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerListTenantInitConfigurationChanges>>>
export type CloudConfigControllerListTenantInitConfigurationChangesQueryError = unknown

export const useCloudConfigControllerListTenantInitConfigurationChanges = <TData = Awaited<ReturnType<typeof cloudConfigControllerListTenantInitConfigurationChanges>>, TError = unknown>(
 params: CloudConfigControllerListTenantInitConfigurationChangesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListTenantInitConfigurationChanges>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerListTenantInitConfigurationChangesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * change a tenant init configuration
 */
export const cloudConfigControllerCreateTenantInitConfigurationChange = (
    cloudconfigCreateTenantInitConfigurationChangeReq: CloudconfigCreateTenantInitConfigurationChangeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigCreateConfigurationChangeResp>(
      {url: `/api/v1/devops/cloudconfig/tenantinit/changes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: cloudconfigCreateTenantInitConfigurationChangeReq
    },
      options);
    }
  


export const getCloudConfigControllerCreateTenantInitConfigurationChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCreateTenantInitConfigurationChange>>, TError,{data: CloudconfigCreateTenantInitConfigurationChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCreateTenantInitConfigurationChange>>, TError,{data: CloudconfigCreateTenantInitConfigurationChangeReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudConfigControllerCreateTenantInitConfigurationChange>>, {data: CloudconfigCreateTenantInitConfigurationChangeReq}> = (props) => {
          const {data} = props ?? {};

          return  cloudConfigControllerCreateTenantInitConfigurationChange(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloudConfigControllerCreateTenantInitConfigurationChangeMutationResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerCreateTenantInitConfigurationChange>>>
    export type CloudConfigControllerCreateTenantInitConfigurationChangeMutationBody = CloudconfigCreateTenantInitConfigurationChangeReq
    export type CloudConfigControllerCreateTenantInitConfigurationChangeMutationError = unknown

    export const useCloudConfigControllerCreateTenantInitConfigurationChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerCreateTenantInitConfigurationChange>>, TError,{data: CloudconfigCreateTenantInitConfigurationChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloudConfigControllerCreateTenantInitConfigurationChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * change the configuration of a cluster
 */
export const cloudConfigControllerPreviewTenantInitConfigurationChange = (
    cloudconfigPreviewTenantInitConfigurationChangeReq: CloudconfigPreviewTenantInitConfigurationChangeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigPreviewConfigurationChangeResp>(
      {url: `/api/v1/devops/cloudconfig/tenantinit/changes/preview`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: cloudconfigPreviewTenantInitConfigurationChangeReq
    },
      options);
    }
  


export const getCloudConfigControllerPreviewTenantInitConfigurationChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerPreviewTenantInitConfigurationChange>>, TError,{data: CloudconfigPreviewTenantInitConfigurationChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerPreviewTenantInitConfigurationChange>>, TError,{data: CloudconfigPreviewTenantInitConfigurationChangeReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloudConfigControllerPreviewTenantInitConfigurationChange>>, {data: CloudconfigPreviewTenantInitConfigurationChangeReq}> = (props) => {
          const {data} = props ?? {};

          return  cloudConfigControllerPreviewTenantInitConfigurationChange(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloudConfigControllerPreviewTenantInitConfigurationChangeMutationResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerPreviewTenantInitConfigurationChange>>>
    export type CloudConfigControllerPreviewTenantInitConfigurationChangeMutationBody = CloudconfigPreviewTenantInitConfigurationChangeReq
    export type CloudConfigControllerPreviewTenantInitConfigurationChangeMutationError = unknown

    export const useCloudConfigControllerPreviewTenantInitConfigurationChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloudConfigControllerPreviewTenantInitConfigurationChange>>, TError,{data: CloudconfigPreviewTenantInitConfigurationChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloudConfigControllerPreviewTenantInitConfigurationChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get a tenant init config
 */
export const cloudConfigControllerGetTenantInitConfiguration = (
    params: CloudConfigControllerGetTenantInitConfigurationParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigGetConfigurationResp>(
      {url: `/api/v1/devops/cloudconfig/tenantinit/config`, method: 'get',
        params
    },
      options);
    }
  

export const getCloudConfigControllerGetTenantInitConfigurationQueryKey = (params: CloudConfigControllerGetTenantInitConfigurationParams,) => [`/api/v1/devops/cloudconfig/tenantinit/config`, ...(params ? [params]: [])] as const;
  

    
export const getCloudConfigControllerGetTenantInitConfigurationQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerGetTenantInitConfiguration>>, TError = unknown>(params: CloudConfigControllerGetTenantInitConfigurationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetTenantInitConfiguration>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetTenantInitConfiguration>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerGetTenantInitConfigurationQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerGetTenantInitConfiguration>>> = () => cloudConfigControllerGetTenantInitConfiguration(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type CloudConfigControllerGetTenantInitConfigurationQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerGetTenantInitConfiguration>>>
export type CloudConfigControllerGetTenantInitConfigurationQueryError = unknown

export const useCloudConfigControllerGetTenantInitConfiguration = <TData = Awaited<ReturnType<typeof cloudConfigControllerGetTenantInitConfiguration>>, TError = unknown>(
 params: CloudConfigControllerGetTenantInitConfigurationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerGetTenantInitConfiguration>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerGetTenantInitConfigurationQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list available versions
 */
export const cloudConfigControllerListVersions = (
    component: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CloudconfigListVersionsResp>(
      {url: `/api/v1/devops/cloudconfig/versions/${component}`, method: 'get'
    },
      options);
    }
  

export const getCloudConfigControllerListVersionsQueryKey = (component: string,) => [`/api/v1/devops/cloudconfig/versions/${component}`] as const;
  

    
export const getCloudConfigControllerListVersionsQueryOptions = <TData = Awaited<ReturnType<typeof cloudConfigControllerListVersions>>, TError = unknown>(component: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListVersions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListVersions>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCloudConfigControllerListVersionsQueryKey(component);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof cloudConfigControllerListVersions>>> = () => cloudConfigControllerListVersions(component, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(component), ...queryOptions}}

export type CloudConfigControllerListVersionsQueryResult = NonNullable<Awaited<ReturnType<typeof cloudConfigControllerListVersions>>>
export type CloudConfigControllerListVersionsQueryError = unknown

export const useCloudConfigControllerListVersions = <TData = Awaited<ReturnType<typeof cloudConfigControllerListVersions>>, TError = unknown>(
 component: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof cloudConfigControllerListVersions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCloudConfigControllerListVersionsQueryOptions(component,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list k8s infra information
 */
export const listK8SInformation = (
    params?: ListK8SInformationParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<K8supgradeListK8SInformationResp>(
      {url: `/api/v1/devops/cluster/k8sInfos`, method: 'get',
        params
    },
      options);
    }
  

export const getListK8SInformationQueryKey = (params?: ListK8SInformationParams,) => [`/api/v1/devops/cluster/k8sInfos`, ...(params ? [params]: [])] as const;
  

    
export const getListK8SInformationQueryOptions = <TData = Awaited<ReturnType<typeof listK8SInformation>>, TError = ApiBaseResp>(params?: ListK8SInformationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listK8SInformation>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listK8SInformation>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListK8SInformationQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listK8SInformation>>> = () => listK8SInformation(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListK8SInformationQueryResult = NonNullable<Awaited<ReturnType<typeof listK8SInformation>>>
export type ListK8SInformationQueryError = ApiBaseResp

export const useListK8SInformation = <TData = Awaited<ReturnType<typeof listK8SInformation>>, TError = ApiBaseResp>(
 params?: ListK8SInformationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listK8SInformation>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListK8SInformationQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get specific k8s infra information
 */
export const getK8SInformation = (
    cloudProvider: string,
    k8sName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<K8supgradeGetK8SInformationResp>(
      {url: `/api/v1/devops/cluster/k8sInfos/${cloudProvider}/${k8sName}`, method: 'get'
    },
      options);
    }
  

export const getGetK8SInformationQueryKey = (cloudProvider: string,
    k8sName: string,) => [`/api/v1/devops/cluster/k8sInfos/${cloudProvider}/${k8sName}`] as const;
  

    
export const getGetK8SInformationQueryOptions = <TData = Awaited<ReturnType<typeof getK8SInformation>>, TError = ApiBaseResp>(cloudProvider: string,
    k8sName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getK8SInformation>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getK8SInformation>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetK8SInformationQueryKey(cloudProvider,k8sName);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getK8SInformation>>> = () => getK8SInformation(cloudProvider,k8sName, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(cloudProvider && k8sName), ...queryOptions}}

export type GetK8SInformationQueryResult = NonNullable<Awaited<ReturnType<typeof getK8SInformation>>>
export type GetK8SInformationQueryError = ApiBaseResp

export const useGetK8SInformation = <TData = Awaited<ReturnType<typeof getK8SInformation>>, TError = ApiBaseResp>(
 cloudProvider: string,
    k8sName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getK8SInformation>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetK8SInformationQueryOptions(cloudProvider,k8sName,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list k8s upgrade tasks
 */
export const listK8sUpgradeTasks = (
    params?: ListK8sUpgradeTasksParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<K8supgradeListTasksResp>(
      {url: `/api/v1/devops/cluster/k8sUpgradeTasks`, method: 'get',
        params
    },
      options);
    }
  

export const getListK8sUpgradeTasksQueryKey = (params?: ListK8sUpgradeTasksParams,) => [`/api/v1/devops/cluster/k8sUpgradeTasks`, ...(params ? [params]: [])] as const;
  

    
export const getListK8sUpgradeTasksQueryOptions = <TData = Awaited<ReturnType<typeof listK8sUpgradeTasks>>, TError = ApiBaseResp>(params?: ListK8sUpgradeTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listK8sUpgradeTasks>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listK8sUpgradeTasks>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListK8sUpgradeTasksQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listK8sUpgradeTasks>>> = () => listK8sUpgradeTasks(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListK8sUpgradeTasksQueryResult = NonNullable<Awaited<ReturnType<typeof listK8sUpgradeTasks>>>
export type ListK8sUpgradeTasksQueryError = ApiBaseResp

export const useListK8sUpgradeTasks = <TData = Awaited<ReturnType<typeof listK8sUpgradeTasks>>, TError = ApiBaseResp>(
 params?: ListK8sUpgradeTasksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listK8sUpgradeTasks>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListK8sUpgradeTasksQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create a k8s upgrade task
 */
export const createK8sUpgradeTask = (
    k8supgradeCreateTaskReq: K8supgradeCreateTaskReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<K8supgradeCreateTaskResp>(
      {url: `/api/v1/devops/cluster/k8sUpgradeTasks`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: k8supgradeCreateTaskReq
    },
      options);
    }
  


export const getCreateK8sUpgradeTaskMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createK8sUpgradeTask>>, TError,{data: K8supgradeCreateTaskReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createK8sUpgradeTask>>, TError,{data: K8supgradeCreateTaskReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createK8sUpgradeTask>>, {data: K8supgradeCreateTaskReq}> = (props) => {
          const {data} = props ?? {};

          return  createK8sUpgradeTask(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateK8sUpgradeTaskMutationResult = NonNullable<Awaited<ReturnType<typeof createK8sUpgradeTask>>>
    export type CreateK8sUpgradeTaskMutationBody = K8supgradeCreateTaskReq
    export type CreateK8sUpgradeTaskMutationError = unknown

    export const useCreateK8sUpgradeTask = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createK8sUpgradeTask>>, TError,{data: K8supgradeCreateTaskReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateK8sUpgradeTaskMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get specific k8s upgrade task
 */
export const getK8sUpgradeTask = (
    taskId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<K8supgradeGetTaskResp>(
      {url: `/api/v1/devops/cluster/k8sUpgradeTasks/${taskId}`, method: 'get'
    },
      options);
    }
  

export const getGetK8sUpgradeTaskQueryKey = (taskId: string,) => [`/api/v1/devops/cluster/k8sUpgradeTasks/${taskId}`] as const;
  

    
export const getGetK8sUpgradeTaskQueryOptions = <TData = Awaited<ReturnType<typeof getK8sUpgradeTask>>, TError = ApiBaseResp>(taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getK8sUpgradeTask>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getK8sUpgradeTask>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetK8sUpgradeTaskQueryKey(taskId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getK8sUpgradeTask>>> = () => getK8sUpgradeTask(taskId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(taskId), ...queryOptions}}

export type GetK8sUpgradeTaskQueryResult = NonNullable<Awaited<ReturnType<typeof getK8sUpgradeTask>>>
export type GetK8sUpgradeTaskQueryError = ApiBaseResp

export const useGetK8sUpgradeTask = <TData = Awaited<ReturnType<typeof getK8sUpgradeTask>>, TError = ApiBaseResp>(
 taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getK8sUpgradeTask>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetK8sUpgradeTaskQueryOptions(taskId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update a k8s upgrade task
 */
export const updateK8sUpgradeTask = (
    taskId: string,
    k8supgradeUpdateTaskReq: K8supgradeUpdateTaskReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cluster/k8sUpgradeTasks/${taskId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: k8supgradeUpdateTaskReq
    },
      options);
    }
  


export const getUpdateK8sUpgradeTaskMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateK8sUpgradeTask>>, TError,{taskId: string;data: K8supgradeUpdateTaskReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateK8sUpgradeTask>>, TError,{taskId: string;data: K8supgradeUpdateTaskReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateK8sUpgradeTask>>, {taskId: string;data: K8supgradeUpdateTaskReq}> = (props) => {
          const {taskId,data} = props ?? {};

          return  updateK8sUpgradeTask(taskId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateK8sUpgradeTaskMutationResult = NonNullable<Awaited<ReturnType<typeof updateK8sUpgradeTask>>>
    export type UpdateK8sUpgradeTaskMutationBody = K8supgradeUpdateTaskReq
    export type UpdateK8sUpgradeTaskMutationError = unknown

    export const useUpdateK8sUpgradeTask = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateK8sUpgradeTask>>, TError,{taskId: string;data: K8supgradeUpdateTaskReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateK8sUpgradeTaskMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * delete a k8s upgrade task
 */
export const deleteK8sUpgradeTask = (
    taskId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cluster/k8sUpgradeTasks/${taskId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteK8sUpgradeTaskMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteK8sUpgradeTask>>, TError,{taskId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteK8sUpgradeTask>>, TError,{taskId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteK8sUpgradeTask>>, {taskId: string}> = (props) => {
          const {taskId} = props ?? {};

          return  deleteK8sUpgradeTask(taskId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteK8sUpgradeTaskMutationResult = NonNullable<Awaited<ReturnType<typeof deleteK8sUpgradeTask>>>
    
    export type DeleteK8sUpgradeTaskMutationError = unknown

    export const useDeleteK8sUpgradeTask = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteK8sUpgradeTask>>, TError,{taskId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteK8sUpgradeTaskMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * close a k8s upgrade task
 */
export const closeK8sUpgradeTask = (
    taskId: string,
    k8supgradeCloseTaskReq: K8supgradeCloseTaskReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cluster/k8sUpgradeTasks/${taskId}/close`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: k8supgradeCloseTaskReq
    },
      options);
    }
  


export const getCloseK8sUpgradeTaskMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof closeK8sUpgradeTask>>, TError,{taskId: string;data: K8supgradeCloseTaskReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof closeK8sUpgradeTask>>, TError,{taskId: string;data: K8supgradeCloseTaskReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof closeK8sUpgradeTask>>, {taskId: string;data: K8supgradeCloseTaskReq}> = (props) => {
          const {taskId,data} = props ?? {};

          return  closeK8sUpgradeTask(taskId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloseK8sUpgradeTaskMutationResult = NonNullable<Awaited<ReturnType<typeof closeK8sUpgradeTask>>>
    export type CloseK8sUpgradeTaskMutationBody = K8supgradeCloseTaskReq
    export type CloseK8sUpgradeTaskMutationError = unknown

    export const useCloseK8sUpgradeTask = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof closeK8sUpgradeTask>>, TError,{taskId: string;data: K8supgradeCloseTaskReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloseK8sUpgradeTaskMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * submit a k8s upgrade task
 */
export const submitK8sUpgradeTask = (
    taskId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cluster/k8sUpgradeTasks/${taskId}/submit`, method: 'post'
    },
      options);
    }
  


export const getSubmitK8sUpgradeTaskMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitK8sUpgradeTask>>, TError,{taskId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof submitK8sUpgradeTask>>, TError,{taskId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitK8sUpgradeTask>>, {taskId: string}> = (props) => {
          const {taskId} = props ?? {};

          return  submitK8sUpgradeTask(taskId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SubmitK8sUpgradeTaskMutationResult = NonNullable<Awaited<ReturnType<typeof submitK8sUpgradeTask>>>
    
    export type SubmitK8sUpgradeTaskMutationError = unknown

    export const useSubmitK8sUpgradeTask = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitK8sUpgradeTask>>, TError,{taskId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getSubmitK8sUpgradeTaskMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get specific k8s cluster of an upgrade task
 */
export const getK8sUpgradeTaskCluster = (
    taskId: string,
    k8sName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<K8supgradeGetTaskK8sClusterResp>(
      {url: `/api/v1/devops/cluster/k8sUpgradeTasks/${taskId}/${k8sName}`, method: 'get'
    },
      options);
    }
  

export const getGetK8sUpgradeTaskClusterQueryKey = (taskId: string,
    k8sName: string,) => [`/api/v1/devops/cluster/k8sUpgradeTasks/${taskId}/${k8sName}`] as const;
  

    
export const getGetK8sUpgradeTaskClusterQueryOptions = <TData = Awaited<ReturnType<typeof getK8sUpgradeTaskCluster>>, TError = ApiBaseResp>(taskId: string,
    k8sName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getK8sUpgradeTaskCluster>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getK8sUpgradeTaskCluster>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetK8sUpgradeTaskClusterQueryKey(taskId,k8sName);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getK8sUpgradeTaskCluster>>> = () => getK8sUpgradeTaskCluster(taskId,k8sName, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(taskId && k8sName), ...queryOptions}}

export type GetK8sUpgradeTaskClusterQueryResult = NonNullable<Awaited<ReturnType<typeof getK8sUpgradeTaskCluster>>>
export type GetK8sUpgradeTaskClusterQueryError = ApiBaseResp

export const useGetK8sUpgradeTaskCluster = <TData = Awaited<ReturnType<typeof getK8sUpgradeTaskCluster>>, TError = ApiBaseResp>(
 taskId: string,
    k8sName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getK8sUpgradeTaskCluster>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetK8sUpgradeTaskClusterQueryOptions(taskId,k8sName,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update specific k8s cluster of an upgrade task
 */
export const updateK8sUpgradeTaskCluster = (
    taskId: string,
    k8sName: string,
    k8supgradeUpdateTaskK8sClusterReq: K8supgradeUpdateTaskK8sClusterReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<K8supgradeUpdateTaskK8sClusterResp>(
      {url: `/api/v1/devops/cluster/k8sUpgradeTasks/${taskId}/${k8sName}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: k8supgradeUpdateTaskK8sClusterReq
    },
      options);
    }
  


export const getUpdateK8sUpgradeTaskClusterMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateK8sUpgradeTaskCluster>>, TError,{taskId: string;k8sName: string;data: K8supgradeUpdateTaskK8sClusterReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateK8sUpgradeTaskCluster>>, TError,{taskId: string;k8sName: string;data: K8supgradeUpdateTaskK8sClusterReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateK8sUpgradeTaskCluster>>, {taskId: string;k8sName: string;data: K8supgradeUpdateTaskK8sClusterReq}> = (props) => {
          const {taskId,k8sName,data} = props ?? {};

          return  updateK8sUpgradeTaskCluster(taskId,k8sName,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateK8sUpgradeTaskClusterMutationResult = NonNullable<Awaited<ReturnType<typeof updateK8sUpgradeTaskCluster>>>
    export type UpdateK8sUpgradeTaskClusterMutationBody = K8supgradeUpdateTaskK8sClusterReq
    export type UpdateK8sUpgradeTaskClusterMutationError = ApiBaseResp

    export const useUpdateK8sUpgradeTaskCluster = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateK8sUpgradeTaskCluster>>, TError,{taskId: string;k8sName: string;data: K8supgradeUpdateTaskK8sClusterReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateK8sUpgradeTaskClusterMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * update the DisableAutoGeneration field of a specific k8s cluster of an upgrade task
 */
export const updateK8sUpgradeTaskClusterAutoGeneration = (
    taskId: string,
    k8sName: string,
    k8supgradeUpdateK8sUpgradeTaskClusterAutoGenerationReq: K8supgradeUpdateK8sUpgradeTaskClusterAutoGenerationReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<K8supgradeUpdateK8sUpgradeTaskClusterAutoGenerationResp>(
      {url: `/api/v1/devops/cluster/k8sUpgradeTasks/${taskId}/${k8sName}/autoGeneration`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: k8supgradeUpdateK8sUpgradeTaskClusterAutoGenerationReq
    },
      options);
    }
  


export const getUpdateK8sUpgradeTaskClusterAutoGenerationMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateK8sUpgradeTaskClusterAutoGeneration>>, TError,{taskId: string;k8sName: string;data: K8supgradeUpdateK8sUpgradeTaskClusterAutoGenerationReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateK8sUpgradeTaskClusterAutoGeneration>>, TError,{taskId: string;k8sName: string;data: K8supgradeUpdateK8sUpgradeTaskClusterAutoGenerationReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateK8sUpgradeTaskClusterAutoGeneration>>, {taskId: string;k8sName: string;data: K8supgradeUpdateK8sUpgradeTaskClusterAutoGenerationReq}> = (props) => {
          const {taskId,k8sName,data} = props ?? {};

          return  updateK8sUpgradeTaskClusterAutoGeneration(taskId,k8sName,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateK8sUpgradeTaskClusterAutoGenerationMutationResult = NonNullable<Awaited<ReturnType<typeof updateK8sUpgradeTaskClusterAutoGeneration>>>
    export type UpdateK8sUpgradeTaskClusterAutoGenerationMutationBody = K8supgradeUpdateK8sUpgradeTaskClusterAutoGenerationReq
    export type UpdateK8sUpgradeTaskClusterAutoGenerationMutationError = ApiBaseResp

    export const useUpdateK8sUpgradeTaskClusterAutoGeneration = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateK8sUpgradeTaskClusterAutoGeneration>>, TError,{taskId: string;k8sName: string;data: K8supgradeUpdateK8sUpgradeTaskClusterAutoGenerationReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateK8sUpgradeTaskClusterAutoGenerationMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * cancel the upgrade of a specific k8s cluster for a running task
 */
export const cancelUpgradeK8sCluster = (
    taskId: string,
    k8sName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<K8supgradeCancelUpgradeK8sClusterResp>(
      {url: `/api/v1/devops/cluster/k8sUpgradeTasks/${taskId}/${k8sName}/cancel`, method: 'put'
    },
      options);
    }
  


export const getCancelUpgradeK8sClusterMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelUpgradeK8sCluster>>, TError,{taskId: string;k8sName: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cancelUpgradeK8sCluster>>, TError,{taskId: string;k8sName: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelUpgradeK8sCluster>>, {taskId: string;k8sName: string}> = (props) => {
          const {taskId,k8sName} = props ?? {};

          return  cancelUpgradeK8sCluster(taskId,k8sName,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CancelUpgradeK8sClusterMutationResult = NonNullable<Awaited<ReturnType<typeof cancelUpgradeK8sCluster>>>
    
    export type CancelUpgradeK8sClusterMutationError = ApiBaseResp

    export const useCancelUpgradeK8sCluster = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelUpgradeK8sCluster>>, TError,{taskId: string;k8sName: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCancelUpgradeK8sClusterMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * exclude a specific k8s cluster of an upgrade task
 */
export const excludeK8sUpgradeTaskCluster = (
    taskId: string,
    k8sName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<K8supgradeExcludeK8sUpgradeTaskClusterResp>(
      {url: `/api/v1/devops/cluster/k8sUpgradeTasks/${taskId}/${k8sName}/exclude`, method: 'put'
    },
      options);
    }
  


export const getExcludeK8sUpgradeTaskClusterMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof excludeK8sUpgradeTaskCluster>>, TError,{taskId: string;k8sName: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof excludeK8sUpgradeTaskCluster>>, TError,{taskId: string;k8sName: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof excludeK8sUpgradeTaskCluster>>, {taskId: string;k8sName: string}> = (props) => {
          const {taskId,k8sName} = props ?? {};

          return  excludeK8sUpgradeTaskCluster(taskId,k8sName,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ExcludeK8sUpgradeTaskClusterMutationResult = NonNullable<Awaited<ReturnType<typeof excludeK8sUpgradeTaskCluster>>>
    
    export type ExcludeK8sUpgradeTaskClusterMutationError = ApiBaseResp

    export const useExcludeK8sUpgradeTaskCluster = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof excludeK8sUpgradeTaskCluster>>, TError,{taskId: string;k8sName: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getExcludeK8sUpgradeTaskClusterMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list maintenances
 */
export const listMaintenances = (
    params?: ListMaintenancesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ClusterListMaintenancesResp>(
      {url: `/api/v1/devops/cluster/maintenances`, method: 'get',
        params
    },
      options);
    }
  

export const getListMaintenancesQueryKey = (params?: ListMaintenancesParams,) => [`/api/v1/devops/cluster/maintenances`, ...(params ? [params]: [])] as const;
  

    
export const getListMaintenancesQueryOptions = <TData = Awaited<ReturnType<typeof listMaintenances>>, TError = unknown>(params?: ListMaintenancesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listMaintenances>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listMaintenances>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListMaintenancesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listMaintenances>>> = () => listMaintenances(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListMaintenancesQueryResult = NonNullable<Awaited<ReturnType<typeof listMaintenances>>>
export type ListMaintenancesQueryError = unknown

export const useListMaintenances = <TData = Awaited<ReturnType<typeof listMaintenances>>, TError = unknown>(
 params?: ListMaintenancesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listMaintenances>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListMaintenancesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create a maintenances
 */
export const createMaintenance = (
    clusterCreateMaintenanceReq: ClusterCreateMaintenanceReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ClusterCreateMaintenanceResp>(
      {url: `/api/v1/devops/cluster/maintenances`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: clusterCreateMaintenanceReq
    },
      options);
    }
  


export const getCreateMaintenanceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMaintenance>>, TError,{data: ClusterCreateMaintenanceReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createMaintenance>>, TError,{data: ClusterCreateMaintenanceReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMaintenance>>, {data: ClusterCreateMaintenanceReq}> = (props) => {
          const {data} = props ?? {};

          return  createMaintenance(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateMaintenanceMutationResult = NonNullable<Awaited<ReturnType<typeof createMaintenance>>>
    export type CreateMaintenanceMutationBody = ClusterCreateMaintenanceReq
    export type CreateMaintenanceMutationError = unknown

    export const useCreateMaintenance = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMaintenance>>, TError,{data: ClusterCreateMaintenanceReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateMaintenanceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get a maintenances
 */
export const getMaintenance = (
    maintenanceId: number,
    params?: GetMaintenanceParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ClusterGetMaintenanceResp>(
      {url: `/api/v1/devops/cluster/maintenances/${maintenanceId}`, method: 'get',
        params
    },
      options);
    }
  

export const getGetMaintenanceQueryKey = (maintenanceId: number,
    params?: GetMaintenanceParams,) => [`/api/v1/devops/cluster/maintenances/${maintenanceId}`, ...(params ? [params]: [])] as const;
  

    
export const getGetMaintenanceQueryOptions = <TData = Awaited<ReturnType<typeof getMaintenance>>, TError = unknown>(maintenanceId: number,
    params?: GetMaintenanceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMaintenance>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getMaintenance>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMaintenanceQueryKey(maintenanceId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMaintenance>>> = () => getMaintenance(maintenanceId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(maintenanceId), ...queryOptions}}

export type GetMaintenanceQueryResult = NonNullable<Awaited<ReturnType<typeof getMaintenance>>>
export type GetMaintenanceQueryError = unknown

export const useGetMaintenance = <TData = Awaited<ReturnType<typeof getMaintenance>>, TError = unknown>(
 maintenanceId: number,
    params?: GetMaintenanceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMaintenance>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMaintenanceQueryOptions(maintenanceId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * close a maintenances
 */
export const closeMaintenance = (
    maintenanceId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cluster/maintenances/${maintenanceId}/close`, method: 'post'
    },
      options);
    }
  


export const getCloseMaintenanceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof closeMaintenance>>, TError,{maintenanceId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof closeMaintenance>>, TError,{maintenanceId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof closeMaintenance>>, {maintenanceId: number}> = (props) => {
          const {maintenanceId} = props ?? {};

          return  closeMaintenance(maintenanceId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloseMaintenanceMutationResult = NonNullable<Awaited<ReturnType<typeof closeMaintenance>>>
    
    export type CloseMaintenanceMutationError = unknown

    export const useCloseMaintenance = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof closeMaintenance>>, TError,{maintenanceId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloseMaintenanceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * cancel a maintenance executions
 */
export const cancelMaintenanceExecution = (
    maintenanceId: number,
    executionId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cluster/maintenances/${maintenanceId}/executions/${executionId}/cancel`, method: 'post'
    },
      options);
    }
  


export const getCancelMaintenanceExecutionMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelMaintenanceExecution>>, TError,{maintenanceId: number;executionId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cancelMaintenanceExecution>>, TError,{maintenanceId: number;executionId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelMaintenanceExecution>>, {maintenanceId: number;executionId: number}> = (props) => {
          const {maintenanceId,executionId} = props ?? {};

          return  cancelMaintenanceExecution(maintenanceId,executionId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CancelMaintenanceExecutionMutationResult = NonNullable<Awaited<ReturnType<typeof cancelMaintenanceExecution>>>
    
    export type CancelMaintenanceExecutionMutationError = unknown

    export const useCancelMaintenanceExecution = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelMaintenanceExecution>>, TError,{maintenanceId: number;executionId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCancelMaintenanceExecutionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * finish a maintenance executions
 */
export const finishMaintenanceExecution = (
    maintenanceId: number,
    executionId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cluster/maintenances/${maintenanceId}/executions/${executionId}/finish`, method: 'post'
    },
      options);
    }
  


export const getFinishMaintenanceExecutionMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof finishMaintenanceExecution>>, TError,{maintenanceId: number;executionId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof finishMaintenanceExecution>>, TError,{maintenanceId: number;executionId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof finishMaintenanceExecution>>, {maintenanceId: number;executionId: number}> = (props) => {
          const {maintenanceId,executionId} = props ?? {};

          return  finishMaintenanceExecution(maintenanceId,executionId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type FinishMaintenanceExecutionMutationResult = NonNullable<Awaited<ReturnType<typeof finishMaintenanceExecution>>>
    
    export type FinishMaintenanceExecutionMutationError = unknown

    export const useFinishMaintenanceExecution = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof finishMaintenanceExecution>>, TError,{maintenanceId: number;executionId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getFinishMaintenanceExecutionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * start a maintenance executions
 */
export const startMaintenanceExecution = (
    maintenanceId: number,
    executionId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/cluster/maintenances/${maintenanceId}/executions/${executionId}/start`, method: 'post'
    },
      options);
    }
  


export const getStartMaintenanceExecutionMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startMaintenanceExecution>>, TError,{maintenanceId: number;executionId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof startMaintenanceExecution>>, TError,{maintenanceId: number;executionId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof startMaintenanceExecution>>, {maintenanceId: number;executionId: number}> = (props) => {
          const {maintenanceId,executionId} = props ?? {};

          return  startMaintenanceExecution(maintenanceId,executionId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type StartMaintenanceExecutionMutationResult = NonNullable<Awaited<ReturnType<typeof startMaintenanceExecution>>>
    
    export type StartMaintenanceExecutionMutationError = unknown

    export const useStartMaintenanceExecution = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startMaintenanceExecution>>, TError,{maintenanceId: number;executionId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getStartMaintenanceExecutionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list cluster
 */
export const listClusters = (
    params?: ListClustersParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ClusterListClustersResp>(
      {url: `/api/v1/devops/clusters`, method: 'get',
        params
    },
      options);
    }
  

export const getListClustersQueryKey = (params?: ListClustersParams,) => [`/api/v1/devops/clusters`, ...(params ? [params]: [])] as const;
  

    
export const getListClustersQueryOptions = <TData = Awaited<ReturnType<typeof listClusters>>, TError = ApiBaseResp>(params?: ListClustersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listClusters>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listClusters>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListClustersQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listClusters>>> = () => listClusters(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListClustersQueryResult = NonNullable<Awaited<ReturnType<typeof listClusters>>>
export type ListClustersQueryError = ApiBaseResp

export const useListClusters = <TData = Awaited<ReturnType<typeof listClusters>>, TError = ApiBaseResp>(
 params?: ListClustersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listClusters>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListClustersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get cluster
 */
export const getCluster = (
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ClusterGetClusterResp>(
      {url: `/api/v1/devops/clusters/${clusterId}`, method: 'get'
    },
      options);
    }
  

export const getGetClusterQueryKey = (clusterId: string,) => [`/api/v1/devops/clusters/${clusterId}`] as const;
  

    
export const getGetClusterQueryOptions = <TData = Awaited<ReturnType<typeof getCluster>>, TError = ApiBaseResp>(clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCluster>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getCluster>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClusterQueryKey(clusterId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCluster>>> = () => getCluster(clusterId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(clusterId), ...queryOptions}}

export type GetClusterQueryResult = NonNullable<Awaited<ReturnType<typeof getCluster>>>
export type GetClusterQueryError = ApiBaseResp

export const useGetCluster = <TData = Awaited<ReturnType<typeof getCluster>>, TError = ApiBaseResp>(
 clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCluster>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetClusterQueryOptions(clusterId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get alert summary
 */
export const getAlertSummary = (
    clusterId: string,
    params: GetAlertSummaryParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ClusterGetAlertSummaryResp>(
      {url: `/api/v1/devops/clusters/${clusterId}/alert-summary`, method: 'get',
        params
    },
      options);
    }
  

export const getGetAlertSummaryQueryKey = (clusterId: string,
    params: GetAlertSummaryParams,) => [`/api/v1/devops/clusters/${clusterId}/alert-summary`, ...(params ? [params]: [])] as const;
  

    
export const getGetAlertSummaryQueryOptions = <TData = Awaited<ReturnType<typeof getAlertSummary>>, TError = unknown>(clusterId: string,
    params: GetAlertSummaryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAlertSummary>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getAlertSummary>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAlertSummaryQueryKey(clusterId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlertSummary>>> = () => getAlertSummary(clusterId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(clusterId), ...queryOptions}}

export type GetAlertSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof getAlertSummary>>>
export type GetAlertSummaryQueryError = unknown

export const useGetAlertSummary = <TData = Awaited<ReturnType<typeof getAlertSummary>>, TError = unknown>(
 clusterId: string,
    params: GetAlertSummaryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAlertSummary>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAlertSummaryQueryOptions(clusterId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list alerts
 */
export const listAlerts = (
    clusterId: string,
    params: ListAlertsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ClusterListAlertsResp>(
      {url: `/api/v1/devops/clusters/${clusterId}/alerts`, method: 'get',
        params
    },
      options);
    }
  

export const getListAlertsQueryKey = (clusterId: string,
    params: ListAlertsParams,) => [`/api/v1/devops/clusters/${clusterId}/alerts`, ...(params ? [params]: [])] as const;
  

    
export const getListAlertsQueryOptions = <TData = Awaited<ReturnType<typeof listAlerts>>, TError = unknown>(clusterId: string,
    params: ListAlertsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAlerts>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listAlerts>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAlertsQueryKey(clusterId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAlerts>>> = () => listAlerts(clusterId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(clusterId), ...queryOptions}}

export type ListAlertsQueryResult = NonNullable<Awaited<ReturnType<typeof listAlerts>>>
export type ListAlertsQueryError = unknown

export const useListAlerts = <TData = Awaited<ReturnType<typeof listAlerts>>, TError = unknown>(
 clusterId: string,
    params: ListAlertsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAlerts>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAlertsQueryOptions(clusterId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get cluster components
 */
export const getClusterComponents = (
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ClusterGetClusterComponentsResp>(
      {url: `/api/v1/devops/clusters/${clusterId}/components`, method: 'get'
    },
      options);
    }
  

export const getGetClusterComponentsQueryKey = (clusterId: string,) => [`/api/v1/devops/clusters/${clusterId}/components`] as const;
  

    
export const getGetClusterComponentsQueryOptions = <TData = Awaited<ReturnType<typeof getClusterComponents>>, TError = ApiBaseResp>(clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getClusterComponents>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getClusterComponents>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClusterComponentsQueryKey(clusterId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getClusterComponents>>> = () => getClusterComponents(clusterId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(clusterId), ...queryOptions}}

export type GetClusterComponentsQueryResult = NonNullable<Awaited<ReturnType<typeof getClusterComponents>>>
export type GetClusterComponentsQueryError = ApiBaseResp

export const useGetClusterComponents = <TData = Awaited<ReturnType<typeof getClusterComponents>>, TError = ApiBaseResp>(
 clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getClusterComponents>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetClusterComponentsQueryOptions(clusterId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Add Config Attribute for Some Version
 */
export const addConfigAttribute = (
    configcenterAddAttributeReq: ConfigcenterAddAttributeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/configcenter/add`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: configcenterAddAttributeReq
    },
      options);
    }
  


export const getAddConfigAttributeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addConfigAttribute>>, TError,{data: ConfigcenterAddAttributeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof addConfigAttribute>>, TError,{data: ConfigcenterAddAttributeReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addConfigAttribute>>, {data: ConfigcenterAddAttributeReq}> = (props) => {
          const {data} = props ?? {};

          return  addConfigAttribute(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AddConfigAttributeMutationResult = NonNullable<Awaited<ReturnType<typeof addConfigAttribute>>>
    export type AddConfigAttributeMutationBody = ConfigcenterAddAttributeReq
    export type AddConfigAttributeMutationError = unknown

    export const useAddConfigAttribute = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addConfigAttribute>>, TError,{data: ConfigcenterAddAttributeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getAddConfigAttributeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Copy All Config Attribute From Some Version
 */
export const copyAttributeFromVersion = (
    configcenterCopyFromVersionReq: ConfigcenterCopyFromVersionReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/configcenter/copy`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: configcenterCopyFromVersionReq
    },
      options);
    }
  


export const getCopyAttributeFromVersionMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof copyAttributeFromVersion>>, TError,{data: ConfigcenterCopyFromVersionReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof copyAttributeFromVersion>>, TError,{data: ConfigcenterCopyFromVersionReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof copyAttributeFromVersion>>, {data: ConfigcenterCopyFromVersionReq}> = (props) => {
          const {data} = props ?? {};

          return  copyAttributeFromVersion(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CopyAttributeFromVersionMutationResult = NonNullable<Awaited<ReturnType<typeof copyAttributeFromVersion>>>
    export type CopyAttributeFromVersionMutationBody = ConfigcenterCopyFromVersionReq
    export type CopyAttributeFromVersionMutationError = unknown

    export const useCopyAttributeFromVersion = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof copyAttributeFromVersion>>, TError,{data: ConfigcenterCopyFromVersionReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCopyAttributeFromVersionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * List Config Attribute in Some Version
 */
export const listConfigAttribute = (
    params?: ListConfigAttributeParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ConfigcenterListAttributesResp>(
      {url: `/api/v1/devops/configcenter/list`, method: 'get',
        params
    },
      options);
    }
  

export const getListConfigAttributeQueryKey = (params?: ListConfigAttributeParams,) => [`/api/v1/devops/configcenter/list`, ...(params ? [params]: [])] as const;
  

    
export const getListConfigAttributeQueryOptions = <TData = Awaited<ReturnType<typeof listConfigAttribute>>, TError = ApiBaseResp>(params?: ListConfigAttributeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listConfigAttribute>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listConfigAttribute>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListConfigAttributeQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listConfigAttribute>>> = () => listConfigAttribute(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListConfigAttributeQueryResult = NonNullable<Awaited<ReturnType<typeof listConfigAttribute>>>
export type ListConfigAttributeQueryError = ApiBaseResp

export const useListConfigAttribute = <TData = Awaited<ReturnType<typeof listConfigAttribute>>, TError = ApiBaseResp>(
 params?: ListConfigAttributeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listConfigAttribute>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListConfigAttributeQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Modify some attribute
 */
export const modifyAttribute = (
    configcenterModifyReq: ConfigcenterModifyReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/configcenter/modify`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: configcenterModifyReq
    },
      options);
    }
  


export const getModifyAttributeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof modifyAttribute>>, TError,{data: ConfigcenterModifyReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof modifyAttribute>>, TError,{data: ConfigcenterModifyReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof modifyAttribute>>, {data: ConfigcenterModifyReq}> = (props) => {
          const {data} = props ?? {};

          return  modifyAttribute(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ModifyAttributeMutationResult = NonNullable<Awaited<ReturnType<typeof modifyAttribute>>>
    export type ModifyAttributeMutationBody = ConfigcenterModifyReq
    export type ModifyAttributeMutationError = unknown

    export const useModifyAttribute = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof modifyAttribute>>, TError,{data: ConfigcenterModifyReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getModifyAttributeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get cloud oncall info
 */
export const getCloudOncallInfo = (
    params: GetCloudOncallInfoParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DashboardCloudOncallInfoResp>(
      {url: `/api/v1/devops/dashboard/cloud-oncall`, method: 'get',
        params
    },
      options);
    }
  

export const getGetCloudOncallInfoQueryKey = (params: GetCloudOncallInfoParams,) => [`/api/v1/devops/dashboard/cloud-oncall`, ...(params ? [params]: [])] as const;
  

    
export const getGetCloudOncallInfoQueryOptions = <TData = Awaited<ReturnType<typeof getCloudOncallInfo>>, TError = ApiBaseResp>(params: GetCloudOncallInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCloudOncallInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getCloudOncallInfo>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCloudOncallInfoQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCloudOncallInfo>>> = () => getCloudOncallInfo(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetCloudOncallInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getCloudOncallInfo>>>
export type GetCloudOncallInfoQueryError = ApiBaseResp

export const useGetCloudOncallInfo = <TData = Awaited<ReturnType<typeof getCloudOncallInfo>>, TError = ApiBaseResp>(
 params: GetCloudOncallInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCloudOncallInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCloudOncallInfoQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get critical alert info
 */
export const getCriticalAlertInfo = (
    params: GetCriticalAlertInfoParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DashboardCriticalAlertInfoResp>(
      {url: `/api/v1/devops/dashboard/critical-alert`, method: 'get',
        params
    },
      options);
    }
  

export const getGetCriticalAlertInfoQueryKey = (params: GetCriticalAlertInfoParams,) => [`/api/v1/devops/dashboard/critical-alert`, ...(params ? [params]: [])] as const;
  

    
export const getGetCriticalAlertInfoQueryOptions = <TData = Awaited<ReturnType<typeof getCriticalAlertInfo>>, TError = ApiBaseResp>(params: GetCriticalAlertInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCriticalAlertInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getCriticalAlertInfo>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCriticalAlertInfoQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCriticalAlertInfo>>> = () => getCriticalAlertInfo(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetCriticalAlertInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getCriticalAlertInfo>>>
export type GetCriticalAlertInfoQueryError = ApiBaseResp

export const useGetCriticalAlertInfo = <TData = Awaited<ReturnType<typeof getCriticalAlertInfo>>, TError = ApiBaseResp>(
 params: GetCriticalAlertInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCriticalAlertInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCriticalAlertInfoQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get incident info
 */
export const getIncidentInfo = (
    params: GetIncidentInfoParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DashboardIncidentInfoResp>(
      {url: `/api/v1/devops/dashboard/incident`, method: 'get',
        params
    },
      options);
    }
  

export const getGetIncidentInfoQueryKey = (params: GetIncidentInfoParams,) => [`/api/v1/devops/dashboard/incident`, ...(params ? [params]: [])] as const;
  

    
export const getGetIncidentInfoQueryOptions = <TData = Awaited<ReturnType<typeof getIncidentInfo>>, TError = ApiBaseResp>(params: GetIncidentInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncidentInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getIncidentInfo>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIncidentInfoQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIncidentInfo>>> = () => getIncidentInfo(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetIncidentInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getIncidentInfo>>>
export type GetIncidentInfoQueryError = ApiBaseResp

export const useGetIncidentInfo = <TData = Awaited<ReturnType<typeof getIncidentInfo>>, TError = ApiBaseResp>(
 params: GetIncidentInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncidentInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetIncidentInfoQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get release info
 */
export const getReleaseInfo = (
    params: GetReleaseInfoParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DashboardReleaseInfoResp>(
      {url: `/api/v1/devops/dashboard/release`, method: 'get',
        params
    },
      options);
    }
  

export const getGetReleaseInfoQueryKey = (params: GetReleaseInfoParams,) => [`/api/v1/devops/dashboard/release`, ...(params ? [params]: [])] as const;
  

    
export const getGetReleaseInfoQueryOptions = <TData = Awaited<ReturnType<typeof getReleaseInfo>>, TError = ApiBaseResp>(params: GetReleaseInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReleaseInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getReleaseInfo>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetReleaseInfoQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getReleaseInfo>>> = () => getReleaseInfo(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetReleaseInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getReleaseInfo>>>
export type GetReleaseInfoQueryError = ApiBaseResp

export const useGetReleaseInfo = <TData = Awaited<ReturnType<typeof getReleaseInfo>>, TError = ApiBaseResp>(
 params: GetReleaseInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getReleaseInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetReleaseInfoQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get sla info
 */
export const getSLAInfo = (
    params: GetSLAInfoParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DashboardSLAInfoResp>(
      {url: `/api/v1/devops/dashboard/sla`, method: 'get',
        params
    },
      options);
    }
  

export const getGetSLAInfoQueryKey = (params: GetSLAInfoParams,) => [`/api/v1/devops/dashboard/sla`, ...(params ? [params]: [])] as const;
  

    
export const getGetSLAInfoQueryOptions = <TData = Awaited<ReturnType<typeof getSLAInfo>>, TError = ApiBaseResp>(params: GetSLAInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSLAInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getSLAInfo>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSLAInfoQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSLAInfo>>> = () => getSLAInfo(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetSLAInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getSLAInfo>>>
export type GetSLAInfoQueryError = ApiBaseResp

export const useGetSLAInfo = <TData = Awaited<ReturnType<typeof getSLAInfo>>, TError = ApiBaseResp>(
 params: GetSLAInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSLAInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSLAInfoQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get cluster detail of dedicated sla
 */
export const getClusterDetailOfDedicatedSLA = (
    params: GetClusterDetailOfDedicatedSLAParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DashboardGetClusterDetailOfDedicatedSLAResp>(
      {url: `/api/v1/devops/dashboard/sla-dedicated-detail`, method: 'get',
        params
    },
      options);
    }
  

export const getGetClusterDetailOfDedicatedSLAQueryKey = (params: GetClusterDetailOfDedicatedSLAParams,) => [`/api/v1/devops/dashboard/sla-dedicated-detail`, ...(params ? [params]: [])] as const;
  

    
export const getGetClusterDetailOfDedicatedSLAQueryOptions = <TData = Awaited<ReturnType<typeof getClusterDetailOfDedicatedSLA>>, TError = ApiBaseResp>(params: GetClusterDetailOfDedicatedSLAParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getClusterDetailOfDedicatedSLA>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getClusterDetailOfDedicatedSLA>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClusterDetailOfDedicatedSLAQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getClusterDetailOfDedicatedSLA>>> = () => getClusterDetailOfDedicatedSLA(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetClusterDetailOfDedicatedSLAQueryResult = NonNullable<Awaited<ReturnType<typeof getClusterDetailOfDedicatedSLA>>>
export type GetClusterDetailOfDedicatedSLAQueryError = ApiBaseResp

export const useGetClusterDetailOfDedicatedSLA = <TData = Awaited<ReturnType<typeof getClusterDetailOfDedicatedSLA>>, TError = ApiBaseResp>(
 params: GetClusterDetailOfDedicatedSLAParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getClusterDetailOfDedicatedSLA>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetClusterDetailOfDedicatedSLAQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get monthly summary of dedicated sla
 */
export const getMonthlySummaryOfDedicatedSLA = (
    params: GetMonthlySummaryOfDedicatedSLAParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DashboardGetMonthlySummaryOfDedicatedSLAResp>(
      {url: `/api/v1/devops/dashboard/sla-dedicated-monthly`, method: 'get',
        params
    },
      options);
    }
  

export const getGetMonthlySummaryOfDedicatedSLAQueryKey = (params: GetMonthlySummaryOfDedicatedSLAParams,) => [`/api/v1/devops/dashboard/sla-dedicated-monthly`, ...(params ? [params]: [])] as const;
  

    
export const getGetMonthlySummaryOfDedicatedSLAQueryOptions = <TData = Awaited<ReturnType<typeof getMonthlySummaryOfDedicatedSLA>>, TError = ApiBaseResp>(params: GetMonthlySummaryOfDedicatedSLAParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMonthlySummaryOfDedicatedSLA>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getMonthlySummaryOfDedicatedSLA>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMonthlySummaryOfDedicatedSLAQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMonthlySummaryOfDedicatedSLA>>> = () => getMonthlySummaryOfDedicatedSLA(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetMonthlySummaryOfDedicatedSLAQueryResult = NonNullable<Awaited<ReturnType<typeof getMonthlySummaryOfDedicatedSLA>>>
export type GetMonthlySummaryOfDedicatedSLAQueryError = ApiBaseResp

export const useGetMonthlySummaryOfDedicatedSLA = <TData = Awaited<ReturnType<typeof getMonthlySummaryOfDedicatedSLA>>, TError = ApiBaseResp>(
 params: GetMonthlySummaryOfDedicatedSLAParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMonthlySummaryOfDedicatedSLA>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMonthlySummaryOfDedicatedSLAQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get slo info
 */
export const getSLOInfo = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DashboardSLOInfoResp>(
      {url: `/api/v1/devops/dashboard/slo`, method: 'get'
    },
      options);
    }
  

export const getGetSLOInfoQueryKey = () => [`/api/v1/devops/dashboard/slo`] as const;
  

    
export const getGetSLOInfoQueryOptions = <TData = Awaited<ReturnType<typeof getSLOInfo>>, TError = ApiBaseResp>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSLOInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getSLOInfo>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSLOInfoQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSLOInfo>>> = () => getSLOInfo(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetSLOInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getSLOInfo>>>
export type GetSLOInfoQueryError = ApiBaseResp

export const useGetSLOInfo = <TData = Awaited<ReturnType<typeof getSLOInfo>>, TError = ApiBaseResp>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSLOInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSLOInfoQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get top10 qps tenants
 */
export const getTop10QPSTenants = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DashboardGetTop10QPSTenantsResp>(
      {url: `/api/v1/devops/dashboard/top-qps-tenants`, method: 'get'
    },
      options);
    }
  

export const getGetTop10QPSTenantsQueryKey = () => [`/api/v1/devops/dashboard/top-qps-tenants`] as const;
  

    
export const getGetTop10QPSTenantsQueryOptions = <TData = Awaited<ReturnType<typeof getTop10QPSTenants>>, TError = ApiBaseResp>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTop10QPSTenants>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTop10QPSTenants>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTop10QPSTenantsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTop10QPSTenants>>> = () => getTop10QPSTenants(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetTop10QPSTenantsQueryResult = NonNullable<Awaited<ReturnType<typeof getTop10QPSTenants>>>
export type GetTop10QPSTenantsQueryError = ApiBaseResp

export const useGetTop10QPSTenants = <TData = Awaited<ReturnType<typeof getTop10QPSTenants>>, TError = ApiBaseResp>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTop10QPSTenants>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTop10QPSTenantsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get top10 tiflash storage tenants
 */
export const getTop10TiFlashStorageTenants = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DashboardGetTop10TiFlashStorageTenantsResp>(
      {url: `/api/v1/devops/dashboard/top-tiflash-storage-tenants`, method: 'get'
    },
      options);
    }
  

export const getGetTop10TiFlashStorageTenantsQueryKey = () => [`/api/v1/devops/dashboard/top-tiflash-storage-tenants`] as const;
  

    
export const getGetTop10TiFlashStorageTenantsQueryOptions = <TData = Awaited<ReturnType<typeof getTop10TiFlashStorageTenants>>, TError = ApiBaseResp>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTop10TiFlashStorageTenants>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTop10TiFlashStorageTenants>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTop10TiFlashStorageTenantsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTop10TiFlashStorageTenants>>> = () => getTop10TiFlashStorageTenants(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetTop10TiFlashStorageTenantsQueryResult = NonNullable<Awaited<ReturnType<typeof getTop10TiFlashStorageTenants>>>
export type GetTop10TiFlashStorageTenantsQueryError = ApiBaseResp

export const useGetTop10TiFlashStorageTenants = <TData = Awaited<ReturnType<typeof getTop10TiFlashStorageTenants>>, TError = ApiBaseResp>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTop10TiFlashStorageTenants>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTop10TiFlashStorageTenantsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get top10 tikv storage tenants
 */
export const getTop10TiKVStorageTenants = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DashboardGetTop10TiKVStorageTenantsResp>(
      {url: `/api/v1/devops/dashboard/top-tikv-storage-tenants`, method: 'get'
    },
      options);
    }
  

export const getGetTop10TiKVStorageTenantsQueryKey = () => [`/api/v1/devops/dashboard/top-tikv-storage-tenants`] as const;
  

    
export const getGetTop10TiKVStorageTenantsQueryOptions = <TData = Awaited<ReturnType<typeof getTop10TiKVStorageTenants>>, TError = ApiBaseResp>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTop10TiKVStorageTenants>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTop10TiKVStorageTenants>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTop10TiKVStorageTenantsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTop10TiKVStorageTenants>>> = () => getTop10TiKVStorageTenants(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetTop10TiKVStorageTenantsQueryResult = NonNullable<Awaited<ReturnType<typeof getTop10TiKVStorageTenants>>>
export type GetTop10TiKVStorageTenantsQueryError = ApiBaseResp

export const useGetTop10TiKVStorageTenants = <TData = Awaited<ReturnType<typeof getTop10TiKVStorageTenants>>, TError = ApiBaseResp>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTop10TiKVStorageTenants>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTop10TiKVStorageTenantsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list pull requests.
 */
export const githubControllerListPullRequests = (
    params?: GithubControllerListPullRequestsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GithubListPullRequestsResp>(
      {url: `/api/v1/devops/github/pulls`, method: 'get',
        params
    },
      options);
    }
  

export const getGithubControllerListPullRequestsQueryKey = (params?: GithubControllerListPullRequestsParams,) => [`/api/v1/devops/github/pulls`, ...(params ? [params]: [])] as const;
  

    
export const getGithubControllerListPullRequestsQueryOptions = <TData = Awaited<ReturnType<typeof githubControllerListPullRequests>>, TError = unknown>(params?: GithubControllerListPullRequestsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerListPullRequests>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof githubControllerListPullRequests>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGithubControllerListPullRequestsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof githubControllerListPullRequests>>> = () => githubControllerListPullRequests(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GithubControllerListPullRequestsQueryResult = NonNullable<Awaited<ReturnType<typeof githubControllerListPullRequests>>>
export type GithubControllerListPullRequestsQueryError = unknown

export const useGithubControllerListPullRequests = <TData = Awaited<ReturnType<typeof githubControllerListPullRequests>>, TError = unknown>(
 params?: GithubControllerListPullRequestsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerListPullRequests>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGithubControllerListPullRequestsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get a pull request.
 */
export const githubControllerGetPullRequest = (
    owner: string,
    repo: string,
    prNumber: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GithubGetPullRequestResp>(
      {url: `/api/v1/devops/github/pulls/${owner}/${repo}/${prNumber}`, method: 'get'
    },
      options);
    }
  

export const getGithubControllerGetPullRequestQueryKey = (owner: string,
    repo: string,
    prNumber: number,) => [`/api/v1/devops/github/pulls/${owner}/${repo}/${prNumber}`] as const;
  

    
export const getGithubControllerGetPullRequestQueryOptions = <TData = Awaited<ReturnType<typeof githubControllerGetPullRequest>>, TError = unknown>(owner: string,
    repo: string,
    prNumber: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerGetPullRequest>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof githubControllerGetPullRequest>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGithubControllerGetPullRequestQueryKey(owner,repo,prNumber);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof githubControllerGetPullRequest>>> = () => githubControllerGetPullRequest(owner,repo,prNumber, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(owner && repo && prNumber), ...queryOptions}}

export type GithubControllerGetPullRequestQueryResult = NonNullable<Awaited<ReturnType<typeof githubControllerGetPullRequest>>>
export type GithubControllerGetPullRequestQueryError = unknown

export const useGithubControllerGetPullRequest = <TData = Awaited<ReturnType<typeof githubControllerGetPullRequest>>, TError = unknown>(
 owner: string,
    repo: string,
    prNumber: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerGetPullRequest>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGithubControllerGetPullRequestQueryOptions(owner,repo,prNumber,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list labels of a repo from github directly
 */
export const githubControllerRawListLabels = (
    owner: string,
    repo: string,
    params?: GithubControllerRawListLabelsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GithubRawListLabelsResp>(
      {url: `/api/v1/devops/github/raw/repos/${owner}/${repo}/labels`, method: 'get',
        params
    },
      options);
    }
  

export const getGithubControllerRawListLabelsQueryKey = (owner: string,
    repo: string,
    params?: GithubControllerRawListLabelsParams,) => [`/api/v1/devops/github/raw/repos/${owner}/${repo}/labels`, ...(params ? [params]: [])] as const;
  

    
export const getGithubControllerRawListLabelsQueryOptions = <TData = Awaited<ReturnType<typeof githubControllerRawListLabels>>, TError = unknown>(owner: string,
    repo: string,
    params?: GithubControllerRawListLabelsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawListLabels>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawListLabels>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGithubControllerRawListLabelsQueryKey(owner,repo,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof githubControllerRawListLabels>>> = () => githubControllerRawListLabels(owner,repo,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(owner && repo), ...queryOptions}}

export type GithubControllerRawListLabelsQueryResult = NonNullable<Awaited<ReturnType<typeof githubControllerRawListLabels>>>
export type GithubControllerRawListLabelsQueryError = unknown

export const useGithubControllerRawListLabels = <TData = Awaited<ReturnType<typeof githubControllerRawListLabels>>, TError = unknown>(
 owner: string,
    repo: string,
    params?: GithubControllerRawListLabelsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawListLabels>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGithubControllerRawListLabelsQueryOptions(owner,repo,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get a label from github directly
 */
export const githubControllerRawGetLabel = (
    owner: string,
    repo: string,
    labelName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GithubRawGetLabelResp>(
      {url: `/api/v1/devops/github/raw/repos/${owner}/${repo}/labels/${labelName}`, method: 'get'
    },
      options);
    }
  

export const getGithubControllerRawGetLabelQueryKey = (owner: string,
    repo: string,
    labelName: string,) => [`/api/v1/devops/github/raw/repos/${owner}/${repo}/labels/${labelName}`] as const;
  

    
export const getGithubControllerRawGetLabelQueryOptions = <TData = Awaited<ReturnType<typeof githubControllerRawGetLabel>>, TError = unknown>(owner: string,
    repo: string,
    labelName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawGetLabel>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawGetLabel>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGithubControllerRawGetLabelQueryKey(owner,repo,labelName);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof githubControllerRawGetLabel>>> = () => githubControllerRawGetLabel(owner,repo,labelName, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(owner && repo && labelName), ...queryOptions}}

export type GithubControllerRawGetLabelQueryResult = NonNullable<Awaited<ReturnType<typeof githubControllerRawGetLabel>>>
export type GithubControllerRawGetLabelQueryError = unknown

export const useGithubControllerRawGetLabel = <TData = Awaited<ReturnType<typeof githubControllerRawGetLabel>>, TError = unknown>(
 owner: string,
    repo: string,
    labelName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawGetLabel>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGithubControllerRawGetLabelQueryOptions(owner,repo,labelName,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list pull requests from github directly
 */
export const githubControllerRawListPullRequests = (
    owner: string,
    repo: string,
    params?: GithubControllerRawListPullRequestsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GithubRawListPullRequestsResp>(
      {url: `/api/v1/devops/github/raw/repos/${owner}/${repo}/pulls`, method: 'get',
        params
    },
      options);
    }
  

export const getGithubControllerRawListPullRequestsQueryKey = (owner: string,
    repo: string,
    params?: GithubControllerRawListPullRequestsParams,) => [`/api/v1/devops/github/raw/repos/${owner}/${repo}/pulls`, ...(params ? [params]: [])] as const;
  

    
export const getGithubControllerRawListPullRequestsQueryOptions = <TData = Awaited<ReturnType<typeof githubControllerRawListPullRequests>>, TError = unknown>(owner: string,
    repo: string,
    params?: GithubControllerRawListPullRequestsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawListPullRequests>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawListPullRequests>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGithubControllerRawListPullRequestsQueryKey(owner,repo,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof githubControllerRawListPullRequests>>> = () => githubControllerRawListPullRequests(owner,repo,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(owner && repo), ...queryOptions}}

export type GithubControllerRawListPullRequestsQueryResult = NonNullable<Awaited<ReturnType<typeof githubControllerRawListPullRequests>>>
export type GithubControllerRawListPullRequestsQueryError = unknown

export const useGithubControllerRawListPullRequests = <TData = Awaited<ReturnType<typeof githubControllerRawListPullRequests>>, TError = unknown>(
 owner: string,
    repo: string,
    params?: GithubControllerRawListPullRequestsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawListPullRequests>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGithubControllerRawListPullRequestsQueryOptions(owner,repo,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get a pull request from github directly
 */
export const githubControllerRawGetPullRequest = (
    owner: string,
    repo: string,
    prNumber: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GithubRawGetPullRequestResp>(
      {url: `/api/v1/devops/github/raw/repos/${owner}/${repo}/pulls/${prNumber}`, method: 'get'
    },
      options);
    }
  

export const getGithubControllerRawGetPullRequestQueryKey = (owner: string,
    repo: string,
    prNumber: number,) => [`/api/v1/devops/github/raw/repos/${owner}/${repo}/pulls/${prNumber}`] as const;
  

    
export const getGithubControllerRawGetPullRequestQueryOptions = <TData = Awaited<ReturnType<typeof githubControllerRawGetPullRequest>>, TError = unknown>(owner: string,
    repo: string,
    prNumber: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawGetPullRequest>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawGetPullRequest>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGithubControllerRawGetPullRequestQueryKey(owner,repo,prNumber);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof githubControllerRawGetPullRequest>>> = () => githubControllerRawGetPullRequest(owner,repo,prNumber, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(owner && repo && prNumber), ...queryOptions}}

export type GithubControllerRawGetPullRequestQueryResult = NonNullable<Awaited<ReturnType<typeof githubControllerRawGetPullRequest>>>
export type GithubControllerRawGetPullRequestQueryError = unknown

export const useGithubControllerRawGetPullRequest = <TData = Awaited<ReturnType<typeof githubControllerRawGetPullRequest>>, TError = unknown>(
 owner: string,
    repo: string,
    prNumber: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawGetPullRequest>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGithubControllerRawGetPullRequestQueryOptions(owner,repo,prNumber,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list releases from github directly
 */
export const githubControllerRawListReleases = (
    owner: string,
    repo: string,
    params?: GithubControllerRawListReleasesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GithubRawListReleasesResp>(
      {url: `/api/v1/devops/github/raw/repos/${owner}/${repo}/releases`, method: 'get',
        params
    },
      options);
    }
  

export const getGithubControllerRawListReleasesQueryKey = (owner: string,
    repo: string,
    params?: GithubControllerRawListReleasesParams,) => [`/api/v1/devops/github/raw/repos/${owner}/${repo}/releases`, ...(params ? [params]: [])] as const;
  

    
export const getGithubControllerRawListReleasesQueryOptions = <TData = Awaited<ReturnType<typeof githubControllerRawListReleases>>, TError = unknown>(owner: string,
    repo: string,
    params?: GithubControllerRawListReleasesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawListReleases>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawListReleases>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGithubControllerRawListReleasesQueryKey(owner,repo,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof githubControllerRawListReleases>>> = () => githubControllerRawListReleases(owner,repo,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(owner && repo), ...queryOptions}}

export type GithubControllerRawListReleasesQueryResult = NonNullable<Awaited<ReturnType<typeof githubControllerRawListReleases>>>
export type GithubControllerRawListReleasesQueryError = unknown

export const useGithubControllerRawListReleases = <TData = Awaited<ReturnType<typeof githubControllerRawListReleases>>, TError = unknown>(
 owner: string,
    repo: string,
    params?: GithubControllerRawListReleasesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawListReleases>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGithubControllerRawListReleasesQueryOptions(owner,repo,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get pull requests between two releases from github directly
 */
export const githubControllerRawDiffRelease = (
    owner: string,
    repo: string,
    params: GithubControllerRawDiffReleaseParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GithubRawDiffReleaseResp>(
      {url: `/api/v1/devops/github/raw/repos/${owner}/${repo}/releases/diff`, method: 'get',
        params
    },
      options);
    }
  

export const getGithubControllerRawDiffReleaseQueryKey = (owner: string,
    repo: string,
    params: GithubControllerRawDiffReleaseParams,) => [`/api/v1/devops/github/raw/repos/${owner}/${repo}/releases/diff`, ...(params ? [params]: [])] as const;
  

    
export const getGithubControllerRawDiffReleaseQueryOptions = <TData = Awaited<ReturnType<typeof githubControllerRawDiffRelease>>, TError = unknown>(owner: string,
    repo: string,
    params: GithubControllerRawDiffReleaseParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawDiffRelease>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawDiffRelease>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGithubControllerRawDiffReleaseQueryKey(owner,repo,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof githubControllerRawDiffRelease>>> = () => githubControllerRawDiffRelease(owner,repo,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(owner && repo), ...queryOptions}}

export type GithubControllerRawDiffReleaseQueryResult = NonNullable<Awaited<ReturnType<typeof githubControllerRawDiffRelease>>>
export type GithubControllerRawDiffReleaseQueryError = unknown

export const useGithubControllerRawDiffRelease = <TData = Awaited<ReturnType<typeof githubControllerRawDiffRelease>>, TError = unknown>(
 owner: string,
    repo: string,
    params: GithubControllerRawDiffReleaseParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawDiffRelease>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGithubControllerRawDiffReleaseQueryOptions(owner,repo,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get a release from github directly
 */
export const githubControllerRawGetRelease = (
    owner: string,
    repo: string,
    releaseTag: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GithubRawGetReleaseResp>(
      {url: `/api/v1/devops/github/raw/repos/${owner}/${repo}/releases/${releaseTag}`, method: 'get'
    },
      options);
    }
  

export const getGithubControllerRawGetReleaseQueryKey = (owner: string,
    repo: string,
    releaseTag: string,) => [`/api/v1/devops/github/raw/repos/${owner}/${repo}/releases/${releaseTag}`] as const;
  

    
export const getGithubControllerRawGetReleaseQueryOptions = <TData = Awaited<ReturnType<typeof githubControllerRawGetRelease>>, TError = unknown>(owner: string,
    repo: string,
    releaseTag: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawGetRelease>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawGetRelease>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGithubControllerRawGetReleaseQueryKey(owner,repo,releaseTag);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof githubControllerRawGetRelease>>> = () => githubControllerRawGetRelease(owner,repo,releaseTag, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(owner && repo && releaseTag), ...queryOptions}}

export type GithubControllerRawGetReleaseQueryResult = NonNullable<Awaited<ReturnType<typeof githubControllerRawGetRelease>>>
export type GithubControllerRawGetReleaseQueryError = unknown

export const useGithubControllerRawGetRelease = <TData = Awaited<ReturnType<typeof githubControllerRawGetRelease>>, TError = unknown>(
 owner: string,
    repo: string,
    releaseTag: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerRawGetRelease>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGithubControllerRawGetReleaseQueryOptions(owner,repo,releaseTag,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list repositories of an org
 */
export const githubControllerListRepositories = (
    params?: GithubControllerListRepositoriesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GithubListRepositoriesResp>(
      {url: `/api/v1/devops/github/repos`, method: 'get',
        params
    },
      options);
    }
  

export const getGithubControllerListRepositoriesQueryKey = (params?: GithubControllerListRepositoriesParams,) => [`/api/v1/devops/github/repos`, ...(params ? [params]: [])] as const;
  

    
export const getGithubControllerListRepositoriesQueryOptions = <TData = Awaited<ReturnType<typeof githubControllerListRepositories>>, TError = unknown>(params?: GithubControllerListRepositoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerListRepositories>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof githubControllerListRepositories>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGithubControllerListRepositoriesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof githubControllerListRepositories>>> = () => githubControllerListRepositories(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GithubControllerListRepositoriesQueryResult = NonNullable<Awaited<ReturnType<typeof githubControllerListRepositories>>>
export type GithubControllerListRepositoriesQueryError = unknown

export const useGithubControllerListRepositories = <TData = Awaited<ReturnType<typeof githubControllerListRepositories>>, TError = unknown>(
 params?: GithubControllerListRepositoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerListRepositories>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGithubControllerListRepositoriesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get a repo
 */
export const githubControllerGetRepo = (
    owner: string,
    repo: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<GithubGetRepositoryResp>(
      {url: `/api/v1/devops/github/repos/${owner}/${repo}`, method: 'get'
    },
      options);
    }
  

export const getGithubControllerGetRepoQueryKey = (owner: string,
    repo: string,) => [`/api/v1/devops/github/repos/${owner}/${repo}`] as const;
  

    
export const getGithubControllerGetRepoQueryOptions = <TData = Awaited<ReturnType<typeof githubControllerGetRepo>>, TError = unknown>(owner: string,
    repo: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerGetRepo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof githubControllerGetRepo>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGithubControllerGetRepoQueryKey(owner,repo);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof githubControllerGetRepo>>> = () => githubControllerGetRepo(owner,repo, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(owner && repo), ...queryOptions}}

export type GithubControllerGetRepoQueryResult = NonNullable<Awaited<ReturnType<typeof githubControllerGetRepo>>>
export type GithubControllerGetRepoQueryError = unknown

export const useGithubControllerGetRepo = <TData = Awaited<ReturnType<typeof githubControllerGetRepo>>, TError = unknown>(
 owner: string,
    repo: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof githubControllerGetRepo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGithubControllerGetRepoQueryOptions(owner,repo,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list incidents
 */
export const listIncidents = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentListIncidentsResp>(
      {url: `/api/v1/devops/incidents`, method: 'get'
    },
      options);
    }
  

export const getListIncidentsQueryKey = () => [`/api/v1/devops/incidents`] as const;
  

    
export const getListIncidentsQueryOptions = <TData = Awaited<ReturnType<typeof listIncidents>>, TError = ApiBaseResp>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidents>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listIncidents>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListIncidentsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listIncidents>>> = () => listIncidents(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListIncidentsQueryResult = NonNullable<Awaited<ReturnType<typeof listIncidents>>>
export type ListIncidentsQueryError = ApiBaseResp

export const useListIncidents = <TData = Awaited<ReturnType<typeof listIncidents>>, TError = ApiBaseResp>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidents>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListIncidentsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create Incident
 */
export const createIncident = (
    incidentCreateIncidentReq: IncidentCreateIncidentReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentCreateIncidentResp>(
      {url: `/api/v1/devops/incidents`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: incidentCreateIncidentReq
    },
      options);
    }
  


export const getCreateIncidentMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIncident>>, TError,{data: IncidentCreateIncidentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createIncident>>, TError,{data: IncidentCreateIncidentReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createIncident>>, {data: IncidentCreateIncidentReq}> = (props) => {
          const {data} = props ?? {};

          return  createIncident(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateIncidentMutationResult = NonNullable<Awaited<ReturnType<typeof createIncident>>>
    export type CreateIncidentMutationBody = IncidentCreateIncidentReq
    export type CreateIncidentMutationError = ApiBaseResp

    export const useCreateIncident = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIncident>>, TError,{data: IncidentCreateIncidentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateIncidentMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get incident
 */
export const getIncident = (
    incidentId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<EntityIncident>(
      {url: `/api/v1/devops/incidents/${incidentId}`, method: 'get'
    },
      options);
    }
  

export const getGetIncidentQueryKey = (incidentId: number,) => [`/api/v1/devops/incidents/${incidentId}`] as const;
  

    
export const getGetIncidentQueryOptions = <TData = Awaited<ReturnType<typeof getIncident>>, TError = ApiBaseResp>(incidentId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncident>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getIncident>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIncidentQueryKey(incidentId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIncident>>> = () => getIncident(incidentId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(incidentId), ...queryOptions}}

export type GetIncidentQueryResult = NonNullable<Awaited<ReturnType<typeof getIncident>>>
export type GetIncidentQueryError = ApiBaseResp

export const useGetIncident = <TData = Awaited<ReturnType<typeof getIncident>>, TError = ApiBaseResp>(
 incidentId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncident>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetIncidentQueryOptions(incidentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update incident
 */
export const updateIncident = (
    incidentId: number,
    incidentUpdateIncidentReq: IncidentUpdateIncidentReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentUpdateIncidentResp>(
      {url: `/api/v1/devops/incidents/${incidentId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: incidentUpdateIncidentReq
    },
      options);
    }
  


export const getUpdateIncidentMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateIncident>>, TError,{incidentId: number;data: IncidentUpdateIncidentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateIncident>>, TError,{incidentId: number;data: IncidentUpdateIncidentReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateIncident>>, {incidentId: number;data: IncidentUpdateIncidentReq}> = (props) => {
          const {incidentId,data} = props ?? {};

          return  updateIncident(incidentId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateIncidentMutationResult = NonNullable<Awaited<ReturnType<typeof updateIncident>>>
    export type UpdateIncidentMutationBody = IncidentUpdateIncidentReq
    export type UpdateIncidentMutationError = ApiBaseResp

    export const useUpdateIncident = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateIncident>>, TError,{incidentId: number;data: IncidentUpdateIncidentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateIncidentMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * delete incident
 */
export const deleteIncident = (
    incidentId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentDeleteIncidentResp>(
      {url: `/api/v1/devops/incidents/${incidentId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteIncidentMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteIncident>>, TError,{incidentId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteIncident>>, TError,{incidentId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteIncident>>, {incidentId: number}> = (props) => {
          const {incidentId} = props ?? {};

          return  deleteIncident(incidentId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteIncidentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteIncident>>>
    
    export type DeleteIncidentMutationError = ApiBaseResp

    export const useDeleteIncident = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteIncident>>, TError,{incidentId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteIncidentMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * update incident log item
 */
export const updateIncidentLogItem = (
    incidentId: number,
    logItemId: number,
    entityIncidentLogItemBody: EntityIncidentLogItemBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiEmptyResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/log/${logItemId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: entityIncidentLogItemBody
    },
      options);
    }
  


export const getUpdateIncidentLogItemMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateIncidentLogItem>>, TError,{incidentId: number;logItemId: number;data: EntityIncidentLogItemBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateIncidentLogItem>>, TError,{incidentId: number;logItemId: number;data: EntityIncidentLogItemBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateIncidentLogItem>>, {incidentId: number;logItemId: number;data: EntityIncidentLogItemBody}> = (props) => {
          const {incidentId,logItemId,data} = props ?? {};

          return  updateIncidentLogItem(incidentId,logItemId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateIncidentLogItemMutationResult = NonNullable<Awaited<ReturnType<typeof updateIncidentLogItem>>>
    export type UpdateIncidentLogItemMutationBody = EntityIncidentLogItemBody
    export type UpdateIncidentLogItemMutationError = ApiBaseResp

    export const useUpdateIncidentLogItem = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateIncidentLogItem>>, TError,{incidentId: number;logItemId: number;data: EntityIncidentLogItemBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateIncidentLogItemMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list incident logs
 */
export const listIncidentLogs = (
    incidentId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentListIncidentLogsResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/logs`, method: 'get'
    },
      options);
    }
  

export const getListIncidentLogsQueryKey = (incidentId: number,) => [`/api/v1/devops/incidents/${incidentId}/logs`] as const;
  

    
export const getListIncidentLogsQueryOptions = <TData = Awaited<ReturnType<typeof listIncidentLogs>>, TError = ApiBaseResp>(incidentId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidentLogs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listIncidentLogs>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListIncidentLogsQueryKey(incidentId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listIncidentLogs>>> = () => listIncidentLogs(incidentId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(incidentId), ...queryOptions}}

export type ListIncidentLogsQueryResult = NonNullable<Awaited<ReturnType<typeof listIncidentLogs>>>
export type ListIncidentLogsQueryError = ApiBaseResp

export const useListIncidentLogs = <TData = Awaited<ReturnType<typeof listIncidentLogs>>, TError = ApiBaseResp>(
 incidentId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidentLogs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListIncidentLogsQueryOptions(incidentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create Incident log item
 */
export const createIncidentLogItem = (
    incidentId: number,
    entityIncidentLogItemBody: EntityIncidentLogItemBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiEmptyResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/logs`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: entityIncidentLogItemBody
    },
      options);
    }
  


export const getCreateIncidentLogItemMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIncidentLogItem>>, TError,{incidentId: number;data: EntityIncidentLogItemBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createIncidentLogItem>>, TError,{incidentId: number;data: EntityIncidentLogItemBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createIncidentLogItem>>, {incidentId: number;data: EntityIncidentLogItemBody}> = (props) => {
          const {incidentId,data} = props ?? {};

          return  createIncidentLogItem(incidentId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateIncidentLogItemMutationResult = NonNullable<Awaited<ReturnType<typeof createIncidentLogItem>>>
    export type CreateIncidentLogItemMutationBody = EntityIncidentLogItemBody
    export type CreateIncidentLogItemMutationError = ApiBaseResp

    export const useCreateIncidentLogItem = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIncidentLogItem>>, TError,{incidentId: number;data: EntityIncidentLogItemBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateIncidentLogItemMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get incident log item
 */
export const getIncidentLogItem = (
    incidentId: number,
    logItemId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<EntityIncidentLogItem>(
      {url: `/api/v1/devops/incidents/${incidentId}/logs/${logItemId}`, method: 'get'
    },
      options);
    }
  

export const getGetIncidentLogItemQueryKey = (incidentId: number,
    logItemId: number,) => [`/api/v1/devops/incidents/${incidentId}/logs/${logItemId}`] as const;
  

    
export const getGetIncidentLogItemQueryOptions = <TData = Awaited<ReturnType<typeof getIncidentLogItem>>, TError = ApiBaseResp>(incidentId: number,
    logItemId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncidentLogItem>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getIncidentLogItem>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIncidentLogItemQueryKey(incidentId,logItemId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIncidentLogItem>>> = () => getIncidentLogItem(incidentId,logItemId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(incidentId && logItemId), ...queryOptions}}

export type GetIncidentLogItemQueryResult = NonNullable<Awaited<ReturnType<typeof getIncidentLogItem>>>
export type GetIncidentLogItemQueryError = ApiBaseResp

export const useGetIncidentLogItem = <TData = Awaited<ReturnType<typeof getIncidentLogItem>>, TError = ApiBaseResp>(
 incidentId: number,
    logItemId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncidentLogItem>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetIncidentLogItemQueryOptions(incidentId,logItemId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * delete incident log item
 */
export const deleteIncidentLogItem = (
    incidentId: number,
    logItemId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentDeleteIncidentLogItemResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/logs/${logItemId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteIncidentLogItemMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteIncidentLogItem>>, TError,{incidentId: number;logItemId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteIncidentLogItem>>, TError,{incidentId: number;logItemId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteIncidentLogItem>>, {incidentId: number;logItemId: number}> = (props) => {
          const {incidentId,logItemId} = props ?? {};

          return  deleteIncidentLogItem(incidentId,logItemId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteIncidentLogItemMutationResult = NonNullable<Awaited<ReturnType<typeof deleteIncidentLogItem>>>
    
    export type DeleteIncidentLogItemMutationError = ApiBaseResp

    export const useDeleteIncidentLogItem = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteIncidentLogItem>>, TError,{incidentId: number;logItemId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteIncidentLogItemMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list incident postmortem items
 */
export const listIncidentPostMortemItems = (
    incidentId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentListIncidentPostMortemItemsResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems`, method: 'get'
    },
      options);
    }
  

export const getListIncidentPostMortemItemsQueryKey = (incidentId: number,) => [`/api/v1/devops/incidents/${incidentId}/postmortems`] as const;
  

    
export const getListIncidentPostMortemItemsQueryOptions = <TData = Awaited<ReturnType<typeof listIncidentPostMortemItems>>, TError = ApiBaseResp>(incidentId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidentPostMortemItems>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listIncidentPostMortemItems>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListIncidentPostMortemItemsQueryKey(incidentId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listIncidentPostMortemItems>>> = () => listIncidentPostMortemItems(incidentId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(incidentId), ...queryOptions}}

export type ListIncidentPostMortemItemsQueryResult = NonNullable<Awaited<ReturnType<typeof listIncidentPostMortemItems>>>
export type ListIncidentPostMortemItemsQueryError = ApiBaseResp

export const useListIncidentPostMortemItems = <TData = Awaited<ReturnType<typeof listIncidentPostMortemItems>>, TError = ApiBaseResp>(
 incidentId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidentPostMortemItems>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListIncidentPostMortemItemsQueryOptions(incidentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create incident postmortem
 */
export const createIncidentPostMortem = (
    incidentId: number,
    incidentCreateIncidentPostMortemReq: IncidentCreateIncidentPostMortemReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentCreateIncidentPostMortemResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: incidentCreateIncidentPostMortemReq
    },
      options);
    }
  


export const getCreateIncidentPostMortemMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIncidentPostMortem>>, TError,{incidentId: number;data: IncidentCreateIncidentPostMortemReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createIncidentPostMortem>>, TError,{incidentId: number;data: IncidentCreateIncidentPostMortemReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createIncidentPostMortem>>, {incidentId: number;data: IncidentCreateIncidentPostMortemReq}> = (props) => {
          const {incidentId,data} = props ?? {};

          return  createIncidentPostMortem(incidentId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateIncidentPostMortemMutationResult = NonNullable<Awaited<ReturnType<typeof createIncidentPostMortem>>>
    export type CreateIncidentPostMortemMutationBody = IncidentCreateIncidentPostMortemReq
    export type CreateIncidentPostMortemMutationError = ApiBaseResp

    export const useCreateIncidentPostMortem = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIncidentPostMortem>>, TError,{incidentId: number;data: IncidentCreateIncidentPostMortemReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateIncidentPostMortemMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get incident postmortem
 */
export const getIncidentPostMortem = (
    incidentId: number,
    postmortemId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentGetIncidentPostMortemResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}`, method: 'get'
    },
      options);
    }
  

export const getGetIncidentPostMortemQueryKey = (incidentId: number,
    postmortemId: number,) => [`/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}`] as const;
  

    
export const getGetIncidentPostMortemQueryOptions = <TData = Awaited<ReturnType<typeof getIncidentPostMortem>>, TError = ApiBaseResp>(incidentId: number,
    postmortemId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncidentPostMortem>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getIncidentPostMortem>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIncidentPostMortemQueryKey(incidentId,postmortemId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIncidentPostMortem>>> = () => getIncidentPostMortem(incidentId,postmortemId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(incidentId && postmortemId), ...queryOptions}}

export type GetIncidentPostMortemQueryResult = NonNullable<Awaited<ReturnType<typeof getIncidentPostMortem>>>
export type GetIncidentPostMortemQueryError = ApiBaseResp

export const useGetIncidentPostMortem = <TData = Awaited<ReturnType<typeof getIncidentPostMortem>>, TError = ApiBaseResp>(
 incidentId: number,
    postmortemId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncidentPostMortem>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetIncidentPostMortemQueryOptions(incidentId,postmortemId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update incident postmortem
 */
export const updateIncidentPostMortem = (
    incidentId: number,
    postmortemId: number,
    incidentUpdateIncidentPostMortemReq: IncidentUpdateIncidentPostMortemReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentUpdateIncidentPostMortemResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: incidentUpdateIncidentPostMortemReq
    },
      options);
    }
  


export const getUpdateIncidentPostMortemMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateIncidentPostMortem>>, TError,{incidentId: number;postmortemId: number;data: IncidentUpdateIncidentPostMortemReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateIncidentPostMortem>>, TError,{incidentId: number;postmortemId: number;data: IncidentUpdateIncidentPostMortemReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateIncidentPostMortem>>, {incidentId: number;postmortemId: number;data: IncidentUpdateIncidentPostMortemReq}> = (props) => {
          const {incidentId,postmortemId,data} = props ?? {};

          return  updateIncidentPostMortem(incidentId,postmortemId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateIncidentPostMortemMutationResult = NonNullable<Awaited<ReturnType<typeof updateIncidentPostMortem>>>
    export type UpdateIncidentPostMortemMutationBody = IncidentUpdateIncidentPostMortemReq
    export type UpdateIncidentPostMortemMutationError = ApiBaseResp

    export const useUpdateIncidentPostMortem = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateIncidentPostMortem>>, TError,{incidentId: number;postmortemId: number;data: IncidentUpdateIncidentPostMortemReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateIncidentPostMortemMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * delete incident postmortem
 */
export const deleteIncidentPostMortem = (
    incidentId: number,
    postmortemId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentDeleteIncidentPostMortemResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteIncidentPostMortemMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteIncidentPostMortem>>, TError,{incidentId: number;postmortemId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteIncidentPostMortem>>, TError,{incidentId: number;postmortemId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteIncidentPostMortem>>, {incidentId: number;postmortemId: number}> = (props) => {
          const {incidentId,postmortemId} = props ?? {};

          return  deleteIncidentPostMortem(incidentId,postmortemId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteIncidentPostMortemMutationResult = NonNullable<Awaited<ReturnType<typeof deleteIncidentPostMortem>>>
    
    export type DeleteIncidentPostMortemMutationError = ApiBaseResp

    export const useDeleteIncidentPostMortem = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteIncidentPostMortem>>, TError,{incidentId: number;postmortemId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteIncidentPostMortemMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list incident postmortem timelines
 */
export const listIncidentPostMortemTimelines = (
    incidentId: number,
    postmortemId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentListIncidentPostMortemTimelinesResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}/timelines`, method: 'get'
    },
      options);
    }
  

export const getListIncidentPostMortemTimelinesQueryKey = (incidentId: number,
    postmortemId: number,) => [`/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}/timelines`] as const;
  

    
export const getListIncidentPostMortemTimelinesQueryOptions = <TData = Awaited<ReturnType<typeof listIncidentPostMortemTimelines>>, TError = ApiBaseResp>(incidentId: number,
    postmortemId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidentPostMortemTimelines>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listIncidentPostMortemTimelines>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListIncidentPostMortemTimelinesQueryKey(incidentId,postmortemId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listIncidentPostMortemTimelines>>> = () => listIncidentPostMortemTimelines(incidentId,postmortemId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(incidentId && postmortemId), ...queryOptions}}

export type ListIncidentPostMortemTimelinesQueryResult = NonNullable<Awaited<ReturnType<typeof listIncidentPostMortemTimelines>>>
export type ListIncidentPostMortemTimelinesQueryError = ApiBaseResp

export const useListIncidentPostMortemTimelines = <TData = Awaited<ReturnType<typeof listIncidentPostMortemTimelines>>, TError = ApiBaseResp>(
 incidentId: number,
    postmortemId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidentPostMortemTimelines>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListIncidentPostMortemTimelinesQueryOptions(incidentId,postmortemId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create incident postmortem timeline
 */
export const createIncidentPostMortemTimeline = (
    incidentId: number,
    postmortemId: number,
    incidentCreateIncidentPostMortemTimelineReq: IncidentCreateIncidentPostMortemTimelineReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentCreateIncidentPostMortemTimelineResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}/timelines`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: incidentCreateIncidentPostMortemTimelineReq
    },
      options);
    }
  


export const getCreateIncidentPostMortemTimelineMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIncidentPostMortemTimeline>>, TError,{incidentId: number;postmortemId: number;data: IncidentCreateIncidentPostMortemTimelineReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createIncidentPostMortemTimeline>>, TError,{incidentId: number;postmortemId: number;data: IncidentCreateIncidentPostMortemTimelineReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createIncidentPostMortemTimeline>>, {incidentId: number;postmortemId: number;data: IncidentCreateIncidentPostMortemTimelineReq}> = (props) => {
          const {incidentId,postmortemId,data} = props ?? {};

          return  createIncidentPostMortemTimeline(incidentId,postmortemId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateIncidentPostMortemTimelineMutationResult = NonNullable<Awaited<ReturnType<typeof createIncidentPostMortemTimeline>>>
    export type CreateIncidentPostMortemTimelineMutationBody = IncidentCreateIncidentPostMortemTimelineReq
    export type CreateIncidentPostMortemTimelineMutationError = ApiBaseResp

    export const useCreateIncidentPostMortemTimeline = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIncidentPostMortemTimeline>>, TError,{incidentId: number;postmortemId: number;data: IncidentCreateIncidentPostMortemTimelineReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateIncidentPostMortemTimelineMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get incident postmortem timeline
 */
export const getIncidentPostMortemTimeline = (
    incidentId: number,
    postmortemId: number,
    timelineId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentGetIncidentPostMortemTimelineResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}/timelines/${timelineId}`, method: 'get'
    },
      options);
    }
  

export const getGetIncidentPostMortemTimelineQueryKey = (incidentId: number,
    postmortemId: number,
    timelineId: number,) => [`/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}/timelines/${timelineId}`] as const;
  

    
export const getGetIncidentPostMortemTimelineQueryOptions = <TData = Awaited<ReturnType<typeof getIncidentPostMortemTimeline>>, TError = ApiBaseResp>(incidentId: number,
    postmortemId: number,
    timelineId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncidentPostMortemTimeline>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getIncidentPostMortemTimeline>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIncidentPostMortemTimelineQueryKey(incidentId,postmortemId,timelineId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIncidentPostMortemTimeline>>> = () => getIncidentPostMortemTimeline(incidentId,postmortemId,timelineId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(incidentId && postmortemId && timelineId), ...queryOptions}}

export type GetIncidentPostMortemTimelineQueryResult = NonNullable<Awaited<ReturnType<typeof getIncidentPostMortemTimeline>>>
export type GetIncidentPostMortemTimelineQueryError = ApiBaseResp

export const useGetIncidentPostMortemTimeline = <TData = Awaited<ReturnType<typeof getIncidentPostMortemTimeline>>, TError = ApiBaseResp>(
 incidentId: number,
    postmortemId: number,
    timelineId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncidentPostMortemTimeline>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetIncidentPostMortemTimelineQueryOptions(incidentId,postmortemId,timelineId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update incident postmortem timeline
 */
export const updateIncidentPostMortemTimeline = (
    incidentId: number,
    postmortemId: number,
    timelineId: number,
    incidentUpdateIncidentPostMortemTimelineReq: IncidentUpdateIncidentPostMortemTimelineReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentUpdateIncidentPostMortemTimelineResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}/timelines/${timelineId}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: incidentUpdateIncidentPostMortemTimelineReq
    },
      options);
    }
  


export const getUpdateIncidentPostMortemTimelineMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateIncidentPostMortemTimeline>>, TError,{incidentId: number;postmortemId: number;timelineId: number;data: IncidentUpdateIncidentPostMortemTimelineReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateIncidentPostMortemTimeline>>, TError,{incidentId: number;postmortemId: number;timelineId: number;data: IncidentUpdateIncidentPostMortemTimelineReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateIncidentPostMortemTimeline>>, {incidentId: number;postmortemId: number;timelineId: number;data: IncidentUpdateIncidentPostMortemTimelineReq}> = (props) => {
          const {incidentId,postmortemId,timelineId,data} = props ?? {};

          return  updateIncidentPostMortemTimeline(incidentId,postmortemId,timelineId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateIncidentPostMortemTimelineMutationResult = NonNullable<Awaited<ReturnType<typeof updateIncidentPostMortemTimeline>>>
    export type UpdateIncidentPostMortemTimelineMutationBody = IncidentUpdateIncidentPostMortemTimelineReq
    export type UpdateIncidentPostMortemTimelineMutationError = ApiBaseResp

    export const useUpdateIncidentPostMortemTimeline = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateIncidentPostMortemTimeline>>, TError,{incidentId: number;postmortemId: number;timelineId: number;data: IncidentUpdateIncidentPostMortemTimelineReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateIncidentPostMortemTimelineMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * delete incident postmortem timeline
 */
export const deleteIncidentPostMortemTimeline = (
    incidentId: number,
    postmortemId: number,
    timelineId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentDeleteIncidentPostMortemTimelineResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}/timelines/${timelineId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteIncidentPostMortemTimelineMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteIncidentPostMortemTimeline>>, TError,{incidentId: number;postmortemId: number;timelineId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteIncidentPostMortemTimeline>>, TError,{incidentId: number;postmortemId: number;timelineId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteIncidentPostMortemTimeline>>, {incidentId: number;postmortemId: number;timelineId: number}> = (props) => {
          const {incidentId,postmortemId,timelineId} = props ?? {};

          return  deleteIncidentPostMortemTimeline(incidentId,postmortemId,timelineId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteIncidentPostMortemTimelineMutationResult = NonNullable<Awaited<ReturnType<typeof deleteIncidentPostMortemTimeline>>>
    
    export type DeleteIncidentPostMortemTimelineMutationError = ApiBaseResp

    export const useDeleteIncidentPostMortemTimeline = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteIncidentPostMortemTimeline>>, TError,{incidentId: number;postmortemId: number;timelineId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteIncidentPostMortemTimelineMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list incident postmortem todo actions
 */
export const listIncidentPostMortemToDoActions = (
    incidentId: number,
    postmortemId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentListIncidentPostMortemToDoActionsResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}/todoactions`, method: 'get'
    },
      options);
    }
  

export const getListIncidentPostMortemToDoActionsQueryKey = (incidentId: number,
    postmortemId: number,) => [`/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}/todoactions`] as const;
  

    
export const getListIncidentPostMortemToDoActionsQueryOptions = <TData = Awaited<ReturnType<typeof listIncidentPostMortemToDoActions>>, TError = ApiBaseResp>(incidentId: number,
    postmortemId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidentPostMortemToDoActions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listIncidentPostMortemToDoActions>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListIncidentPostMortemToDoActionsQueryKey(incidentId,postmortemId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listIncidentPostMortemToDoActions>>> = () => listIncidentPostMortemToDoActions(incidentId,postmortemId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(incidentId && postmortemId), ...queryOptions}}

export type ListIncidentPostMortemToDoActionsQueryResult = NonNullable<Awaited<ReturnType<typeof listIncidentPostMortemToDoActions>>>
export type ListIncidentPostMortemToDoActionsQueryError = ApiBaseResp

export const useListIncidentPostMortemToDoActions = <TData = Awaited<ReturnType<typeof listIncidentPostMortemToDoActions>>, TError = ApiBaseResp>(
 incidentId: number,
    postmortemId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidentPostMortemToDoActions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListIncidentPostMortemToDoActionsQueryOptions(incidentId,postmortemId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create incident postmortem todo action
 */
export const createIncidentPostMortemToDoAction = (
    incidentId: number,
    postmortemId: number,
    incidentCreateIncidentPostMortemToDoActionReq: IncidentCreateIncidentPostMortemToDoActionReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentCreateIncidentPostMortemToDoActionResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}/todoactions`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: incidentCreateIncidentPostMortemToDoActionReq
    },
      options);
    }
  


export const getCreateIncidentPostMortemToDoActionMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIncidentPostMortemToDoAction>>, TError,{incidentId: number;postmortemId: number;data: IncidentCreateIncidentPostMortemToDoActionReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createIncidentPostMortemToDoAction>>, TError,{incidentId: number;postmortemId: number;data: IncidentCreateIncidentPostMortemToDoActionReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createIncidentPostMortemToDoAction>>, {incidentId: number;postmortemId: number;data: IncidentCreateIncidentPostMortemToDoActionReq}> = (props) => {
          const {incidentId,postmortemId,data} = props ?? {};

          return  createIncidentPostMortemToDoAction(incidentId,postmortemId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateIncidentPostMortemToDoActionMutationResult = NonNullable<Awaited<ReturnType<typeof createIncidentPostMortemToDoAction>>>
    export type CreateIncidentPostMortemToDoActionMutationBody = IncidentCreateIncidentPostMortemToDoActionReq
    export type CreateIncidentPostMortemToDoActionMutationError = ApiBaseResp

    export const useCreateIncidentPostMortemToDoAction = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIncidentPostMortemToDoAction>>, TError,{incidentId: number;postmortemId: number;data: IncidentCreateIncidentPostMortemToDoActionReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateIncidentPostMortemToDoActionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * sync incident postmortem todo action status from lark
 */
export const syncIncidentPostMortemToDoActionStatus = (
    incidentId: number,
    postmortemId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}/todoactions/sync`, method: 'post'
    },
      options);
    }
  


export const getSyncIncidentPostMortemToDoActionStatusMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncIncidentPostMortemToDoActionStatus>>, TError,{incidentId: number;postmortemId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof syncIncidentPostMortemToDoActionStatus>>, TError,{incidentId: number;postmortemId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof syncIncidentPostMortemToDoActionStatus>>, {incidentId: number;postmortemId: number}> = (props) => {
          const {incidentId,postmortemId} = props ?? {};

          return  syncIncidentPostMortemToDoActionStatus(incidentId,postmortemId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SyncIncidentPostMortemToDoActionStatusMutationResult = NonNullable<Awaited<ReturnType<typeof syncIncidentPostMortemToDoActionStatus>>>
    
    export type SyncIncidentPostMortemToDoActionStatusMutationError = unknown

    export const useSyncIncidentPostMortemToDoActionStatus = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncIncidentPostMortemToDoActionStatus>>, TError,{incidentId: number;postmortemId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getSyncIncidentPostMortemToDoActionStatusMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get incident postmortem todo action
 */
export const getIncidentPostMortemToDoAction = (
    incidentId: number,
    postmortemId: number,
    actionId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentGetIncidentPostMortemToDoActionResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}/todoactions/${actionId}`, method: 'get'
    },
      options);
    }
  

export const getGetIncidentPostMortemToDoActionQueryKey = (incidentId: number,
    postmortemId: number,
    actionId: number,) => [`/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}/todoactions/${actionId}`] as const;
  

    
export const getGetIncidentPostMortemToDoActionQueryOptions = <TData = Awaited<ReturnType<typeof getIncidentPostMortemToDoAction>>, TError = ApiBaseResp>(incidentId: number,
    postmortemId: number,
    actionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncidentPostMortemToDoAction>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getIncidentPostMortemToDoAction>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIncidentPostMortemToDoActionQueryKey(incidentId,postmortemId,actionId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIncidentPostMortemToDoAction>>> = () => getIncidentPostMortemToDoAction(incidentId,postmortemId,actionId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(incidentId && postmortemId && actionId), ...queryOptions}}

export type GetIncidentPostMortemToDoActionQueryResult = NonNullable<Awaited<ReturnType<typeof getIncidentPostMortemToDoAction>>>
export type GetIncidentPostMortemToDoActionQueryError = ApiBaseResp

export const useGetIncidentPostMortemToDoAction = <TData = Awaited<ReturnType<typeof getIncidentPostMortemToDoAction>>, TError = ApiBaseResp>(
 incidentId: number,
    postmortemId: number,
    actionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncidentPostMortemToDoAction>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetIncidentPostMortemToDoActionQueryOptions(incidentId,postmortemId,actionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update incident postmortem todo action
 */
export const updateIncidentPostMortemToDoAction = (
    incidentId: number,
    postmortemId: number,
    actionId: number,
    incidentUpdateIncidentPostMortemToDoActionReq: IncidentUpdateIncidentPostMortemToDoActionReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentUpdateIncidentPostMortemToDoActionResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}/todoactions/${actionId}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: incidentUpdateIncidentPostMortemToDoActionReq
    },
      options);
    }
  


export const getUpdateIncidentPostMortemToDoActionMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateIncidentPostMortemToDoAction>>, TError,{incidentId: number;postmortemId: number;actionId: number;data: IncidentUpdateIncidentPostMortemToDoActionReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateIncidentPostMortemToDoAction>>, TError,{incidentId: number;postmortemId: number;actionId: number;data: IncidentUpdateIncidentPostMortemToDoActionReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateIncidentPostMortemToDoAction>>, {incidentId: number;postmortemId: number;actionId: number;data: IncidentUpdateIncidentPostMortemToDoActionReq}> = (props) => {
          const {incidentId,postmortemId,actionId,data} = props ?? {};

          return  updateIncidentPostMortemToDoAction(incidentId,postmortemId,actionId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateIncidentPostMortemToDoActionMutationResult = NonNullable<Awaited<ReturnType<typeof updateIncidentPostMortemToDoAction>>>
    export type UpdateIncidentPostMortemToDoActionMutationBody = IncidentUpdateIncidentPostMortemToDoActionReq
    export type UpdateIncidentPostMortemToDoActionMutationError = ApiBaseResp

    export const useUpdateIncidentPostMortemToDoAction = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateIncidentPostMortemToDoAction>>, TError,{incidentId: number;postmortemId: number;actionId: number;data: IncidentUpdateIncidentPostMortemToDoActionReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateIncidentPostMortemToDoActionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * delete incident postmortem todo action
 */
export const deleteIncidentPostMortemToDoAction = (
    incidentId: number,
    postmortemId: number,
    actionId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentDeleteIncidentPostMortemToDoActionResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortems/${postmortemId}/todoactions/${actionId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteIncidentPostMortemToDoActionMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteIncidentPostMortemToDoAction>>, TError,{incidentId: number;postmortemId: number;actionId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteIncidentPostMortemToDoAction>>, TError,{incidentId: number;postmortemId: number;actionId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteIncidentPostMortemToDoAction>>, {incidentId: number;postmortemId: number;actionId: number}> = (props) => {
          const {incidentId,postmortemId,actionId} = props ?? {};

          return  deleteIncidentPostMortemToDoAction(incidentId,postmortemId,actionId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteIncidentPostMortemToDoActionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteIncidentPostMortemToDoAction>>>
    
    export type DeleteIncidentPostMortemToDoActionMutationError = ApiBaseResp

    export const useDeleteIncidentPostMortemToDoAction = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteIncidentPostMortemToDoAction>>, TError,{incidentId: number;postmortemId: number;actionId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteIncidentPostMortemToDoActionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * init a user
 */
export const jumpControllerInviteUser = (
    jumpInviteUserReq: JumpInviteUserReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<JumpInviteUserResp>(
      {url: `/api/v1/devops/jump-admin/invite-user`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: jumpInviteUserReq
    },
      options);
    }
  


export const getJumpControllerInviteUserMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jumpControllerInviteUser>>, TError,{data: JumpInviteUserReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof jumpControllerInviteUser>>, TError,{data: JumpInviteUserReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jumpControllerInviteUser>>, {data: JumpInviteUserReq}> = (props) => {
          const {data} = props ?? {};

          return  jumpControllerInviteUser(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type JumpControllerInviteUserMutationResult = NonNullable<Awaited<ReturnType<typeof jumpControllerInviteUser>>>
    export type JumpControllerInviteUserMutationBody = JumpInviteUserReq
    export type JumpControllerInviteUserMutationError = unknown

    export const useJumpControllerInviteUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jumpControllerInviteUser>>, TError,{data: JumpInviteUserReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getJumpControllerInviteUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list authorizations
 */
export const jumpControllerListAuthorizations = (
    params?: JumpControllerListAuthorizationsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<JumpListAuthorizationsResp>(
      {url: `/api/v1/devops/jump/authorizations`, method: 'get',
        params
    },
      options);
    }
  

export const getJumpControllerListAuthorizationsQueryKey = (params?: JumpControllerListAuthorizationsParams,) => [`/api/v1/devops/jump/authorizations`, ...(params ? [params]: [])] as const;
  

    
export const getJumpControllerListAuthorizationsQueryOptions = <TData = Awaited<ReturnType<typeof jumpControllerListAuthorizations>>, TError = unknown>(params?: JumpControllerListAuthorizationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jumpControllerListAuthorizations>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof jumpControllerListAuthorizations>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJumpControllerListAuthorizationsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof jumpControllerListAuthorizations>>> = () => jumpControllerListAuthorizations(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type JumpControllerListAuthorizationsQueryResult = NonNullable<Awaited<ReturnType<typeof jumpControllerListAuthorizations>>>
export type JumpControllerListAuthorizationsQueryError = unknown

export const useJumpControllerListAuthorizations = <TData = Awaited<ReturnType<typeof jumpControllerListAuthorizations>>, TError = unknown>(
 params?: JumpControllerListAuthorizationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jumpControllerListAuthorizations>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getJumpControllerListAuthorizationsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create an authorization
 */
export const jumpControllerCreateInstance = (
    jumpCreateAuthorizationReq: JumpCreateAuthorizationReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<JumpCreateAuthorizationResp>(
      {url: `/api/v1/devops/jump/authorizations`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: jumpCreateAuthorizationReq
    },
      options);
    }
  


export const getJumpControllerCreateInstanceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jumpControllerCreateInstance>>, TError,{data: JumpCreateAuthorizationReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof jumpControllerCreateInstance>>, TError,{data: JumpCreateAuthorizationReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jumpControllerCreateInstance>>, {data: JumpCreateAuthorizationReq}> = (props) => {
          const {data} = props ?? {};

          return  jumpControllerCreateInstance(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type JumpControllerCreateInstanceMutationResult = NonNullable<Awaited<ReturnType<typeof jumpControllerCreateInstance>>>
    export type JumpControllerCreateInstanceMutationBody = JumpCreateAuthorizationReq
    export type JumpControllerCreateInstanceMutationError = unknown

    export const useJumpControllerCreateInstance = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jumpControllerCreateInstance>>, TError,{data: JumpCreateAuthorizationReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getJumpControllerCreateInstanceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get available options for control planejumps
 */
export const jumpControllerGetControlPlaneOptions = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<JumpGetControlPlaneOptionsResp>(
      {url: `/api/v1/devops/jump/authorizations/controlplane_options`, method: 'get'
    },
      options);
    }
  

export const getJumpControllerGetControlPlaneOptionsQueryKey = () => [`/api/v1/devops/jump/authorizations/controlplane_options`] as const;
  

    
export const getJumpControllerGetControlPlaneOptionsQueryOptions = <TData = Awaited<ReturnType<typeof jumpControllerGetControlPlaneOptions>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetControlPlaneOptions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetControlPlaneOptions>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJumpControllerGetControlPlaneOptionsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof jumpControllerGetControlPlaneOptions>>> = () => jumpControllerGetControlPlaneOptions(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type JumpControllerGetControlPlaneOptionsQueryResult = NonNullable<Awaited<ReturnType<typeof jumpControllerGetControlPlaneOptions>>>
export type JumpControllerGetControlPlaneOptionsQueryError = unknown

export const useJumpControllerGetControlPlaneOptions = <TData = Awaited<ReturnType<typeof jumpControllerGetControlPlaneOptions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetControlPlaneOptions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getJumpControllerGetControlPlaneOptionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get available options for serverless jumps
 */
export const jumpControllerGetServerlessOptions = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<JumpGetServerlessOptionsResp>(
      {url: `/api/v1/devops/jump/authorizations/serverless_options`, method: 'get'
    },
      options);
    }
  

export const getJumpControllerGetServerlessOptionsQueryKey = () => [`/api/v1/devops/jump/authorizations/serverless_options`] as const;
  

    
export const getJumpControllerGetServerlessOptionsQueryOptions = <TData = Awaited<ReturnType<typeof jumpControllerGetServerlessOptions>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetServerlessOptions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetServerlessOptions>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJumpControllerGetServerlessOptionsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof jumpControllerGetServerlessOptions>>> = () => jumpControllerGetServerlessOptions(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type JumpControllerGetServerlessOptionsQueryResult = NonNullable<Awaited<ReturnType<typeof jumpControllerGetServerlessOptions>>>
export type JumpControllerGetServerlessOptionsQueryError = unknown

export const useJumpControllerGetServerlessOptions = <TData = Awaited<ReturnType<typeof jumpControllerGetServerlessOptions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetServerlessOptions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getJumpControllerGetServerlessOptionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get an authorization
 */
export const jumpControllerGetAuthorization = (
    authorizationId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<JumpGetAuthorizationResp>(
      {url: `/api/v1/devops/jump/authorizations/${authorizationId}`, method: 'get'
    },
      options);
    }
  

export const getJumpControllerGetAuthorizationQueryKey = (authorizationId: number,) => [`/api/v1/devops/jump/authorizations/${authorizationId}`] as const;
  

    
export const getJumpControllerGetAuthorizationQueryOptions = <TData = Awaited<ReturnType<typeof jumpControllerGetAuthorization>>, TError = unknown>(authorizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetAuthorization>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetAuthorization>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJumpControllerGetAuthorizationQueryKey(authorizationId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof jumpControllerGetAuthorization>>> = () => jumpControllerGetAuthorization(authorizationId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(authorizationId), ...queryOptions}}

export type JumpControllerGetAuthorizationQueryResult = NonNullable<Awaited<ReturnType<typeof jumpControllerGetAuthorization>>>
export type JumpControllerGetAuthorizationQueryError = unknown

export const useJumpControllerGetAuthorization = <TData = Awaited<ReturnType<typeof jumpControllerGetAuthorization>>, TError = unknown>(
 authorizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetAuthorization>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getJumpControllerGetAuthorizationQueryOptions(authorizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * cancel an authorization
 */
export const jumpControllerCancelAuthorization = (
    authorizationId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/jump/authorizations/${authorizationId}/cancel`, method: 'post'
    },
      options);
    }
  


export const getJumpControllerCancelAuthorizationMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jumpControllerCancelAuthorization>>, TError,{authorizationId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof jumpControllerCancelAuthorization>>, TError,{authorizationId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jumpControllerCancelAuthorization>>, {authorizationId: number}> = (props) => {
          const {authorizationId} = props ?? {};

          return  jumpControllerCancelAuthorization(authorizationId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type JumpControllerCancelAuthorizationMutationResult = NonNullable<Awaited<ReturnType<typeof jumpControllerCancelAuthorization>>>
    
    export type JumpControllerCancelAuthorizationMutationError = unknown

    export const useJumpControllerCancelAuthorization = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jumpControllerCancelAuthorization>>, TError,{authorizationId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getJumpControllerCancelAuthorizationMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get connect jump pod params
 */
export const jumpControllerGetConnectJumpPodParams = (
    authorizationId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<JumpGetConnectJumpPodParamsResp>(
      {url: `/api/v1/devops/jump/authorizations/${authorizationId}/jumppod_param`, method: 'get'
    },
      options);
    }
  

export const getJumpControllerGetConnectJumpPodParamsQueryKey = (authorizationId: number,) => [`/api/v1/devops/jump/authorizations/${authorizationId}/jumppod_param`] as const;
  

    
export const getJumpControllerGetConnectJumpPodParamsQueryOptions = <TData = Awaited<ReturnType<typeof jumpControllerGetConnectJumpPodParams>>, TError = unknown>(authorizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetConnectJumpPodParams>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetConnectJumpPodParams>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJumpControllerGetConnectJumpPodParamsQueryKey(authorizationId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof jumpControllerGetConnectJumpPodParams>>> = () => jumpControllerGetConnectJumpPodParams(authorizationId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(authorizationId), ...queryOptions}}

export type JumpControllerGetConnectJumpPodParamsQueryResult = NonNullable<Awaited<ReturnType<typeof jumpControllerGetConnectJumpPodParams>>>
export type JumpControllerGetConnectJumpPodParamsQueryError = unknown

export const useJumpControllerGetConnectJumpPodParams = <TData = Awaited<ReturnType<typeof jumpControllerGetConnectJumpPodParams>>, TError = unknown>(
 authorizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetConnectJumpPodParams>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getJumpControllerGetConnectJumpPodParamsQueryOptions(authorizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * activate the applicant in the jump server.
 */
export const jumpControllerActivateUserOfApplicant = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/jump/jumpserver/users/activate`, method: 'post'
    },
      options);
    }
  


export const getJumpControllerActivateUserOfApplicantMutationOptions = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jumpControllerActivateUserOfApplicant>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof jumpControllerActivateUserOfApplicant>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jumpControllerActivateUserOfApplicant>>, TVariables> = () => {
          

          return  jumpControllerActivateUserOfApplicant(requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type JumpControllerActivateUserOfApplicantMutationResult = NonNullable<Awaited<ReturnType<typeof jumpControllerActivateUserOfApplicant>>>
    
    export type JumpControllerActivateUserOfApplicantMutationError = unknown

    export const useJumpControllerActivateUserOfApplicant = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jumpControllerActivateUserOfApplicant>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getJumpControllerActivateUserOfApplicantMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get the applicant from the jump server.
 */
export const jumpControllerGetUserOfApplicant = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<JumpGetUserResp>(
      {url: `/api/v1/devops/jump/jumpserver/users/me`, method: 'get'
    },
      options);
    }
  

export const getJumpControllerGetUserOfApplicantQueryKey = () => [`/api/v1/devops/jump/jumpserver/users/me`] as const;
  

    
export const getJumpControllerGetUserOfApplicantQueryOptions = <TData = Awaited<ReturnType<typeof jumpControllerGetUserOfApplicant>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetUserOfApplicant>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetUserOfApplicant>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJumpControllerGetUserOfApplicantQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof jumpControllerGetUserOfApplicant>>> = () => jumpControllerGetUserOfApplicant(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type JumpControllerGetUserOfApplicantQueryResult = NonNullable<Awaited<ReturnType<typeof jumpControllerGetUserOfApplicant>>>
export type JumpControllerGetUserOfApplicantQueryError = unknown

export const useJumpControllerGetUserOfApplicant = <TData = Awaited<ReturnType<typeof jumpControllerGetUserOfApplicant>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetUserOfApplicant>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getJumpControllerGetUserOfApplicantQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get the user from the jump server.
 */
export const jumpControllerGetUser = (
    email: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<JumpGetUserResp>(
      {url: `/api/v1/devops/jump/jumpserver/users/${email}`, method: 'get'
    },
      options);
    }
  

export const getJumpControllerGetUserQueryKey = (email: string,) => [`/api/v1/devops/jump/jumpserver/users/${email}`] as const;
  

    
export const getJumpControllerGetUserQueryOptions = <TData = Awaited<ReturnType<typeof jumpControllerGetUser>>, TError = unknown>(email: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetUser>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetUser>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJumpControllerGetUserQueryKey(email);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof jumpControllerGetUser>>> = () => jumpControllerGetUser(email, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(email), ...queryOptions}}

export type JumpControllerGetUserQueryResult = NonNullable<Awaited<ReturnType<typeof jumpControllerGetUser>>>
export type JumpControllerGetUserQueryError = unknown

export const useJumpControllerGetUser = <TData = Awaited<ReturnType<typeof jumpControllerGetUser>>, TError = unknown>(
 email: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jumpControllerGetUser>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getJumpControllerGetUserQueryOptions(email,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get current user profile
 */
export const currentUserProfile = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserUserProfileResp>(
      {url: `/api/v1/devops/me`, method: 'get'
    },
      options);
    }
  

export const getCurrentUserProfileQueryKey = () => [`/api/v1/devops/me`] as const;
  

    
export const getCurrentUserProfileQueryOptions = <TData = Awaited<ReturnType<typeof currentUserProfile>>, TError = ApiBaseResp>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof currentUserProfile>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof currentUserProfile>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCurrentUserProfileQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof currentUserProfile>>> = () => currentUserProfile(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type CurrentUserProfileQueryResult = NonNullable<Awaited<ReturnType<typeof currentUserProfile>>>
export type CurrentUserProfileQueryError = ApiBaseResp

export const useCurrentUserProfile = <TData = Awaited<ReturnType<typeof currentUserProfile>>, TError = ApiBaseResp>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof currentUserProfile>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCurrentUserProfileQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list actions
 */
export const operationControllerListActions = (
    params?: OperationControllerListActionsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OperationListActionResp>(
      {url: `/api/v1/devops/operation/actions`, method: 'get',
        params
    },
      options);
    }
  

export const getOperationControllerListActionsQueryKey = (params?: OperationControllerListActionsParams,) => [`/api/v1/devops/operation/actions`, ...(params ? [params]: [])] as const;
  

    
export const getOperationControllerListActionsQueryOptions = <TData = Awaited<ReturnType<typeof operationControllerListActions>>, TError = unknown>(params?: OperationControllerListActionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof operationControllerListActions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof operationControllerListActions>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOperationControllerListActionsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof operationControllerListActions>>> = () => operationControllerListActions(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type OperationControllerListActionsQueryResult = NonNullable<Awaited<ReturnType<typeof operationControllerListActions>>>
export type OperationControllerListActionsQueryError = unknown

export const useOperationControllerListActions = <TData = Awaited<ReturnType<typeof operationControllerListActions>>, TError = unknown>(
 params?: OperationControllerListActionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof operationControllerListActions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOperationControllerListActionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get a action
 */
export const operationControllerGetAction = (
    actionName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OperationGetActionResp>(
      {url: `/api/v1/devops/operation/actions/${actionName}`, method: 'get'
    },
      options);
    }
  

export const getOperationControllerGetActionQueryKey = (actionName: string,) => [`/api/v1/devops/operation/actions/${actionName}`] as const;
  

    
export const getOperationControllerGetActionQueryOptions = <TData = Awaited<ReturnType<typeof operationControllerGetAction>>, TError = unknown>(actionName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof operationControllerGetAction>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof operationControllerGetAction>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOperationControllerGetActionQueryKey(actionName);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof operationControllerGetAction>>> = () => operationControllerGetAction(actionName, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(actionName), ...queryOptions}}

export type OperationControllerGetActionQueryResult = NonNullable<Awaited<ReturnType<typeof operationControllerGetAction>>>
export type OperationControllerGetActionQueryError = unknown

export const useOperationControllerGetAction = <TData = Awaited<ReturnType<typeof operationControllerGetAction>>, TError = unknown>(
 actionName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof operationControllerGetAction>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOperationControllerGetActionQueryOptions(actionName,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list instances
 */
export const operationControllerListInstances = (
    params?: OperationControllerListInstancesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OperationListInstanceResp>(
      {url: `/api/v1/devops/operation/instances`, method: 'get',
        params
    },
      options);
    }
  

export const getOperationControllerListInstancesQueryKey = (params?: OperationControllerListInstancesParams,) => [`/api/v1/devops/operation/instances`, ...(params ? [params]: [])] as const;
  

    
export const getOperationControllerListInstancesQueryOptions = <TData = Awaited<ReturnType<typeof operationControllerListInstances>>, TError = unknown>(params?: OperationControllerListInstancesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof operationControllerListInstances>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof operationControllerListInstances>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOperationControllerListInstancesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof operationControllerListInstances>>> = () => operationControllerListInstances(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type OperationControllerListInstancesQueryResult = NonNullable<Awaited<ReturnType<typeof operationControllerListInstances>>>
export type OperationControllerListInstancesQueryError = unknown

export const useOperationControllerListInstances = <TData = Awaited<ReturnType<typeof operationControllerListInstances>>, TError = unknown>(
 params?: OperationControllerListInstancesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof operationControllerListInstances>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOperationControllerListInstancesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create a instance
 */
export const operationControllerCreateInstance = (
    operationCreateInstanceReq: OperationCreateInstanceReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OperationCreateInstanceResp>(
      {url: `/api/v1/devops/operation/instances`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: operationCreateInstanceReq
    },
      options);
    }
  


export const getOperationControllerCreateInstanceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof operationControllerCreateInstance>>, TError,{data: OperationCreateInstanceReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof operationControllerCreateInstance>>, TError,{data: OperationCreateInstanceReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof operationControllerCreateInstance>>, {data: OperationCreateInstanceReq}> = (props) => {
          const {data} = props ?? {};

          return  operationControllerCreateInstance(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OperationControllerCreateInstanceMutationResult = NonNullable<Awaited<ReturnType<typeof operationControllerCreateInstance>>>
    export type OperationControllerCreateInstanceMutationBody = OperationCreateInstanceReq
    export type OperationControllerCreateInstanceMutationError = unknown

    export const useOperationControllerCreateInstance = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof operationControllerCreateInstance>>, TError,{data: OperationCreateInstanceReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getOperationControllerCreateInstanceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get a instance
 */
export const operationControllerGetInstance = (
    instanceId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OperationGetInstanceResp>(
      {url: `/api/v1/devops/operation/instances/${instanceId}`, method: 'get'
    },
      options);
    }
  

export const getOperationControllerGetInstanceQueryKey = (instanceId: number,) => [`/api/v1/devops/operation/instances/${instanceId}`] as const;
  

    
export const getOperationControllerGetInstanceQueryOptions = <TData = Awaited<ReturnType<typeof operationControllerGetInstance>>, TError = unknown>(instanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof operationControllerGetInstance>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof operationControllerGetInstance>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOperationControllerGetInstanceQueryKey(instanceId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof operationControllerGetInstance>>> = () => operationControllerGetInstance(instanceId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(instanceId), ...queryOptions}}

export type OperationControllerGetInstanceQueryResult = NonNullable<Awaited<ReturnType<typeof operationControllerGetInstance>>>
export type OperationControllerGetInstanceQueryError = unknown

export const useOperationControllerGetInstance = <TData = Awaited<ReturnType<typeof operationControllerGetInstance>>, TError = unknown>(
 instanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof operationControllerGetInstance>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOperationControllerGetInstanceQueryOptions(instanceId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * finish a instance action
 */
export const operationControllerFinishInstanceAction = (
    instanceId: number,
    actionOrder: number,
    operationFinishInstanceActionReq: OperationFinishInstanceActionReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/operation/instances/${instanceId}/actions/${actionOrder}/finish`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: operationFinishInstanceActionReq
    },
      options);
    }
  


export const getOperationControllerFinishInstanceActionMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof operationControllerFinishInstanceAction>>, TError,{instanceId: number;actionOrder: number;data: OperationFinishInstanceActionReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof operationControllerFinishInstanceAction>>, TError,{instanceId: number;actionOrder: number;data: OperationFinishInstanceActionReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof operationControllerFinishInstanceAction>>, {instanceId: number;actionOrder: number;data: OperationFinishInstanceActionReq}> = (props) => {
          const {instanceId,actionOrder,data} = props ?? {};

          return  operationControllerFinishInstanceAction(instanceId,actionOrder,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OperationControllerFinishInstanceActionMutationResult = NonNullable<Awaited<ReturnType<typeof operationControllerFinishInstanceAction>>>
    export type OperationControllerFinishInstanceActionMutationBody = OperationFinishInstanceActionReq
    export type OperationControllerFinishInstanceActionMutationError = unknown

    export const useOperationControllerFinishInstanceAction = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof operationControllerFinishInstanceAction>>, TError,{instanceId: number;actionOrder: number;data: OperationFinishInstanceActionReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getOperationControllerFinishInstanceActionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * start a instance action maunally
 */
export const operationControllerStartInstanceAction = (
    instanceId: number,
    actionOrder: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/operation/instances/${instanceId}/actions/${actionOrder}/start`, method: 'post'
    },
      options);
    }
  


export const getOperationControllerStartInstanceActionMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof operationControllerStartInstanceAction>>, TError,{instanceId: number;actionOrder: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof operationControllerStartInstanceAction>>, TError,{instanceId: number;actionOrder: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof operationControllerStartInstanceAction>>, {instanceId: number;actionOrder: number}> = (props) => {
          const {instanceId,actionOrder} = props ?? {};

          return  operationControllerStartInstanceAction(instanceId,actionOrder,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OperationControllerStartInstanceActionMutationResult = NonNullable<Awaited<ReturnType<typeof operationControllerStartInstanceAction>>>
    
    export type OperationControllerStartInstanceActionMutationError = unknown

    export const useOperationControllerStartInstanceAction = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof operationControllerStartInstanceAction>>, TError,{instanceId: number;actionOrder: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getOperationControllerStartInstanceActionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * cancel a instance
 */
export const operationControllerCancelInstance = (
    instanceId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/operation/instances/${instanceId}/cancel`, method: 'post'
    },
      options);
    }
  


export const getOperationControllerCancelInstanceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof operationControllerCancelInstance>>, TError,{instanceId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof operationControllerCancelInstance>>, TError,{instanceId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof operationControllerCancelInstance>>, {instanceId: number}> = (props) => {
          const {instanceId} = props ?? {};

          return  operationControllerCancelInstance(instanceId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type OperationControllerCancelInstanceMutationResult = NonNullable<Awaited<ReturnType<typeof operationControllerCancelInstance>>>
    
    export type OperationControllerCancelInstanceMutationError = unknown

    export const useOperationControllerCancelInstance = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof operationControllerCancelInstance>>, TError,{instanceId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getOperationControllerCancelInstanceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list operations
 */
export const operationControllerListOperations = (
    params?: OperationControllerListOperationsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OperationListOperationResp>(
      {url: `/api/v1/devops/operation/operations`, method: 'get',
        params
    },
      options);
    }
  

export const getOperationControllerListOperationsQueryKey = (params?: OperationControllerListOperationsParams,) => [`/api/v1/devops/operation/operations`, ...(params ? [params]: [])] as const;
  

    
export const getOperationControllerListOperationsQueryOptions = <TData = Awaited<ReturnType<typeof operationControllerListOperations>>, TError = unknown>(params?: OperationControllerListOperationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof operationControllerListOperations>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof operationControllerListOperations>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOperationControllerListOperationsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof operationControllerListOperations>>> = () => operationControllerListOperations(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type OperationControllerListOperationsQueryResult = NonNullable<Awaited<ReturnType<typeof operationControllerListOperations>>>
export type OperationControllerListOperationsQueryError = unknown

export const useOperationControllerListOperations = <TData = Awaited<ReturnType<typeof operationControllerListOperations>>, TError = unknown>(
 params?: OperationControllerListOperationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof operationControllerListOperations>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOperationControllerListOperationsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list operation categories
 */
export const operationControllerListOperationCategories = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OperationListOperationCategoryResp>(
      {url: `/api/v1/devops/operation/operations/categories`, method: 'get'
    },
      options);
    }
  

export const getOperationControllerListOperationCategoriesQueryKey = () => [`/api/v1/devops/operation/operations/categories`] as const;
  

    
export const getOperationControllerListOperationCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof operationControllerListOperationCategories>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof operationControllerListOperationCategories>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof operationControllerListOperationCategories>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOperationControllerListOperationCategoriesQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof operationControllerListOperationCategories>>> = () => operationControllerListOperationCategories(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type OperationControllerListOperationCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof operationControllerListOperationCategories>>>
export type OperationControllerListOperationCategoriesQueryError = unknown

export const useOperationControllerListOperationCategories = <TData = Awaited<ReturnType<typeof operationControllerListOperationCategories>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof operationControllerListOperationCategories>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOperationControllerListOperationCategoriesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get a operation
 */
export const operationControllerGetOperation = (
    operationName: string,
    params?: OperationControllerGetOperationParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OperationGetOperationResp>(
      {url: `/api/v1/devops/operation/operations/${operationName}`, method: 'get',
        params
    },
      options);
    }
  

export const getOperationControllerGetOperationQueryKey = (operationName: string,
    params?: OperationControllerGetOperationParams,) => [`/api/v1/devops/operation/operations/${operationName}`, ...(params ? [params]: [])] as const;
  

    
export const getOperationControllerGetOperationQueryOptions = <TData = Awaited<ReturnType<typeof operationControllerGetOperation>>, TError = unknown>(operationName: string,
    params?: OperationControllerGetOperationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof operationControllerGetOperation>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof operationControllerGetOperation>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOperationControllerGetOperationQueryKey(operationName,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof operationControllerGetOperation>>> = () => operationControllerGetOperation(operationName,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(operationName), ...queryOptions}}

export type OperationControllerGetOperationQueryResult = NonNullable<Awaited<ReturnType<typeof operationControllerGetOperation>>>
export type OperationControllerGetOperationQueryError = unknown

export const useOperationControllerGetOperation = <TData = Awaited<ReturnType<typeof operationControllerGetOperation>>, TError = unknown>(
 operationName: string,
    params?: OperationControllerGetOperationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof operationControllerGetOperation>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getOperationControllerGetOperationQueryOptions(operationName,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create a new change
 */
export const releaseControllerCreateChange = (
    releaseCreateChangeReq: ReleaseCreateChangeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ReleaseCreateChangeResp>(
      {url: `/api/v1/devops/release/changes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: releaseCreateChangeReq
    },
      options);
    }
  


export const getReleaseControllerCreateChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerCreateChange>>, TError,{data: ReleaseCreateChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof releaseControllerCreateChange>>, TError,{data: ReleaseCreateChangeReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof releaseControllerCreateChange>>, {data: ReleaseCreateChangeReq}> = (props) => {
          const {data} = props ?? {};

          return  releaseControllerCreateChange(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ReleaseControllerCreateChangeMutationResult = NonNullable<Awaited<ReturnType<typeof releaseControllerCreateChange>>>
    export type ReleaseControllerCreateChangeMutationBody = ReleaseCreateChangeReq
    export type ReleaseControllerCreateChangeMutationError = unknown

    export const useReleaseControllerCreateChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerCreateChange>>, TError,{data: ReleaseCreateChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getReleaseControllerCreateChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get a default change
 */
export const releaseControllerDefaultChange = (
    params: ReleaseControllerDefaultChangeParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ReleaseDefaultChangeResp>(
      {url: `/api/v1/devops/release/changes/default`, method: 'get',
        params
    },
      options);
    }
  

export const getReleaseControllerDefaultChangeQueryKey = (params: ReleaseControllerDefaultChangeParams,) => [`/api/v1/devops/release/changes/default`, ...(params ? [params]: [])] as const;
  

    
export const getReleaseControllerDefaultChangeQueryOptions = <TData = Awaited<ReturnType<typeof releaseControllerDefaultChange>>, TError = unknown>(params: ReleaseControllerDefaultChangeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerDefaultChange>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof releaseControllerDefaultChange>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReleaseControllerDefaultChangeQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof releaseControllerDefaultChange>>> = () => releaseControllerDefaultChange(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ReleaseControllerDefaultChangeQueryResult = NonNullable<Awaited<ReturnType<typeof releaseControllerDefaultChange>>>
export type ReleaseControllerDefaultChangeQueryError = unknown

export const useReleaseControllerDefaultChange = <TData = Awaited<ReturnType<typeof releaseControllerDefaultChange>>, TError = unknown>(
 params: ReleaseControllerDefaultChangeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerDefaultChange>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReleaseControllerDefaultChangeQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get a change
 */
export const releaseControllerGetChange = (
    changeId: number,
    params?: ReleaseControllerGetChangeParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ReleaseGetChangeResp>(
      {url: `/api/v1/devops/release/changes/${changeId}`, method: 'get',
        params
    },
      options);
    }
  

export const getReleaseControllerGetChangeQueryKey = (changeId: number,
    params?: ReleaseControllerGetChangeParams,) => [`/api/v1/devops/release/changes/${changeId}`, ...(params ? [params]: [])] as const;
  

    
export const getReleaseControllerGetChangeQueryOptions = <TData = Awaited<ReturnType<typeof releaseControllerGetChange>>, TError = unknown>(changeId: number,
    params?: ReleaseControllerGetChangeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerGetChange>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof releaseControllerGetChange>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReleaseControllerGetChangeQueryKey(changeId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof releaseControllerGetChange>>> = () => releaseControllerGetChange(changeId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(changeId), ...queryOptions}}

export type ReleaseControllerGetChangeQueryResult = NonNullable<Awaited<ReturnType<typeof releaseControllerGetChange>>>
export type ReleaseControllerGetChangeQueryError = unknown

export const useReleaseControllerGetChange = <TData = Awaited<ReturnType<typeof releaseControllerGetChange>>, TError = unknown>(
 changeId: number,
    params?: ReleaseControllerGetChangeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerGetChange>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReleaseControllerGetChangeQueryOptions(changeId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update a change
 */
export const releaseControllerUpdateChange = (
    changeId: number,
    releaseUpdateChangeReq: ReleaseUpdateChangeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/release/changes/${changeId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: releaseUpdateChangeReq
    },
      options);
    }
  


export const getReleaseControllerUpdateChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerUpdateChange>>, TError,{changeId: number;data: ReleaseUpdateChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof releaseControllerUpdateChange>>, TError,{changeId: number;data: ReleaseUpdateChangeReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof releaseControllerUpdateChange>>, {changeId: number;data: ReleaseUpdateChangeReq}> = (props) => {
          const {changeId,data} = props ?? {};

          return  releaseControllerUpdateChange(changeId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ReleaseControllerUpdateChangeMutationResult = NonNullable<Awaited<ReturnType<typeof releaseControllerUpdateChange>>>
    export type ReleaseControllerUpdateChangeMutationBody = ReleaseUpdateChangeReq
    export type ReleaseControllerUpdateChangeMutationError = unknown

    export const useReleaseControllerUpdateChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerUpdateChange>>, TError,{changeId: number;data: ReleaseUpdateChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getReleaseControllerUpdateChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * cancel a change
 */
export const releaseControllerCancelChange = (
    changeId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/release/changes/${changeId}/cancel`, method: 'post'
    },
      options);
    }
  


export const getReleaseControllerCancelChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerCancelChange>>, TError,{changeId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof releaseControllerCancelChange>>, TError,{changeId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof releaseControllerCancelChange>>, {changeId: number}> = (props) => {
          const {changeId} = props ?? {};

          return  releaseControllerCancelChange(changeId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ReleaseControllerCancelChangeMutationResult = NonNullable<Awaited<ReturnType<typeof releaseControllerCancelChange>>>
    
    export type ReleaseControllerCancelChangeMutationError = unknown

    export const useReleaseControllerCancelChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerCancelChange>>, TError,{changeId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getReleaseControllerCancelChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * close a change
 */
export const releaseControllerCloseChange = (
    changeId: number,
    releaseCloseChangeReq: ReleaseCloseChangeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/release/changes/${changeId}/close`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: releaseCloseChangeReq
    },
      options);
    }
  


export const getReleaseControllerCloseChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerCloseChange>>, TError,{changeId: number;data: ReleaseCloseChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof releaseControllerCloseChange>>, TError,{changeId: number;data: ReleaseCloseChangeReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof releaseControllerCloseChange>>, {changeId: number;data: ReleaseCloseChangeReq}> = (props) => {
          const {changeId,data} = props ?? {};

          return  releaseControllerCloseChange(changeId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ReleaseControllerCloseChangeMutationResult = NonNullable<Awaited<ReturnType<typeof releaseControllerCloseChange>>>
    export type ReleaseControllerCloseChangeMutationBody = ReleaseCloseChangeReq
    export type ReleaseControllerCloseChangeMutationError = unknown

    export const useReleaseControllerCloseChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerCloseChange>>, TError,{changeId: number;data: ReleaseCloseChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getReleaseControllerCloseChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * exeuete a deployment of a change
 */
export const releaseControllerExecuteDeployment = (
    changeId: number,
    deploymentOrder: number,
    releaseExecuteDeploymentReq: ReleaseExecuteDeploymentReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/release/changes/${changeId}/deployments/${deploymentOrder}/deploy`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: releaseExecuteDeploymentReq
    },
      options);
    }
  


export const getReleaseControllerExecuteDeploymentMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerExecuteDeployment>>, TError,{changeId: number;deploymentOrder: number;data: ReleaseExecuteDeploymentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof releaseControllerExecuteDeployment>>, TError,{changeId: number;deploymentOrder: number;data: ReleaseExecuteDeploymentReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof releaseControllerExecuteDeployment>>, {changeId: number;deploymentOrder: number;data: ReleaseExecuteDeploymentReq}> = (props) => {
          const {changeId,deploymentOrder,data} = props ?? {};

          return  releaseControllerExecuteDeployment(changeId,deploymentOrder,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ReleaseControllerExecuteDeploymentMutationResult = NonNullable<Awaited<ReturnType<typeof releaseControllerExecuteDeployment>>>
    export type ReleaseControllerExecuteDeploymentMutationBody = ReleaseExecuteDeploymentReq
    export type ReleaseControllerExecuteDeploymentMutationError = unknown

    export const useReleaseControllerExecuteDeployment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerExecuteDeployment>>, TError,{changeId: number;deploymentOrder: number;data: ReleaseExecuteDeploymentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getReleaseControllerExecuteDeploymentMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * return if a deployment of a change is ready to execute
 */
export const releaseControllerIsDeploymentReady = (
    changeId: number,
    deploymentOrder: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ReleaseIsDeploymentReadyResp>(
      {url: `/api/v1/devops/release/changes/${changeId}/deployments/${deploymentOrder}/ready`, method: 'get'
    },
      options);
    }
  

export const getReleaseControllerIsDeploymentReadyQueryKey = (changeId: number,
    deploymentOrder: number,) => [`/api/v1/devops/release/changes/${changeId}/deployments/${deploymentOrder}/ready`] as const;
  

    
export const getReleaseControllerIsDeploymentReadyQueryOptions = <TData = Awaited<ReturnType<typeof releaseControllerIsDeploymentReady>>, TError = unknown>(changeId: number,
    deploymentOrder: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerIsDeploymentReady>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof releaseControllerIsDeploymentReady>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReleaseControllerIsDeploymentReadyQueryKey(changeId,deploymentOrder);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof releaseControllerIsDeploymentReady>>> = () => releaseControllerIsDeploymentReady(changeId,deploymentOrder, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(changeId && deploymentOrder), ...queryOptions}}

export type ReleaseControllerIsDeploymentReadyQueryResult = NonNullable<Awaited<ReturnType<typeof releaseControllerIsDeploymentReady>>>
export type ReleaseControllerIsDeploymentReadyQueryError = unknown

export const useReleaseControllerIsDeploymentReady = <TData = Awaited<ReturnType<typeof releaseControllerIsDeploymentReady>>, TError = unknown>(
 changeId: number,
    deploymentOrder: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerIsDeploymentReady>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReleaseControllerIsDeploymentReadyQueryOptions(changeId,deploymentOrder,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * verify a deployment of a change
 */
export const releaseControllerVerifyDeployment = (
    changeId: number,
    deploymentOrder: number,
    releaseVerifyDeploymentReq: ReleaseVerifyDeploymentReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/release/changes/${changeId}/deployments/${deploymentOrder}/verify`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: releaseVerifyDeploymentReq
    },
      options);
    }
  


export const getReleaseControllerVerifyDeploymentMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerVerifyDeployment>>, TError,{changeId: number;deploymentOrder: number;data: ReleaseVerifyDeploymentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof releaseControllerVerifyDeployment>>, TError,{changeId: number;deploymentOrder: number;data: ReleaseVerifyDeploymentReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof releaseControllerVerifyDeployment>>, {changeId: number;deploymentOrder: number;data: ReleaseVerifyDeploymentReq}> = (props) => {
          const {changeId,deploymentOrder,data} = props ?? {};

          return  releaseControllerVerifyDeployment(changeId,deploymentOrder,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ReleaseControllerVerifyDeploymentMutationResult = NonNullable<Awaited<ReturnType<typeof releaseControllerVerifyDeployment>>>
    export type ReleaseControllerVerifyDeploymentMutationBody = ReleaseVerifyDeploymentReq
    export type ReleaseControllerVerifyDeploymentMutationError = unknown

    export const useReleaseControllerVerifyDeployment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerVerifyDeployment>>, TError,{changeId: number;deploymentOrder: number;data: ReleaseVerifyDeploymentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getReleaseControllerVerifyDeploymentMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * submit a change
 */
export const releaseControllerSubmitChange = (
    changeId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/release/changes/${changeId}/submit`, method: 'post'
    },
      options);
    }
  


export const getReleaseControllerSubmitChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerSubmitChange>>, TError,{changeId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof releaseControllerSubmitChange>>, TError,{changeId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof releaseControllerSubmitChange>>, {changeId: number}> = (props) => {
          const {changeId} = props ?? {};

          return  releaseControllerSubmitChange(changeId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ReleaseControllerSubmitChangeMutationResult = NonNullable<Awaited<ReturnType<typeof releaseControllerSubmitChange>>>
    
    export type ReleaseControllerSubmitChangeMutationError = unknown

    export const useReleaseControllerSubmitChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerSubmitChange>>, TError,{changeId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getReleaseControllerSubmitChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list releases
 */
export const releaseControllerListReleases = (
    params?: ReleaseControllerListReleasesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ReleaseListReleaseResp>(
      {url: `/api/v1/devops/release/releases`, method: 'get',
        params
    },
      options);
    }
  

export const getReleaseControllerListReleasesQueryKey = (params?: ReleaseControllerListReleasesParams,) => [`/api/v1/devops/release/releases`, ...(params ? [params]: [])] as const;
  

    
export const getReleaseControllerListReleasesQueryOptions = <TData = Awaited<ReturnType<typeof releaseControllerListReleases>>, TError = unknown>(params?: ReleaseControllerListReleasesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerListReleases>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof releaseControllerListReleases>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReleaseControllerListReleasesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof releaseControllerListReleases>>> = () => releaseControllerListReleases(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ReleaseControllerListReleasesQueryResult = NonNullable<Awaited<ReturnType<typeof releaseControllerListReleases>>>
export type ReleaseControllerListReleasesQueryError = unknown

export const useReleaseControllerListReleases = <TData = Awaited<ReturnType<typeof releaseControllerListReleases>>, TError = unknown>(
 params?: ReleaseControllerListReleasesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerListReleases>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReleaseControllerListReleasesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get a release
 */
export const releaseControllerGetRelease = (
    releaseId: number,
    params?: ReleaseControllerGetReleaseParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ReleaseGetReleaseResp>(
      {url: `/api/v1/devops/release/releases/${releaseId}`, method: 'get',
        params
    },
      options);
    }
  

export const getReleaseControllerGetReleaseQueryKey = (releaseId: number,
    params?: ReleaseControllerGetReleaseParams,) => [`/api/v1/devops/release/releases/${releaseId}`, ...(params ? [params]: [])] as const;
  

    
export const getReleaseControllerGetReleaseQueryOptions = <TData = Awaited<ReturnType<typeof releaseControllerGetRelease>>, TError = unknown>(releaseId: number,
    params?: ReleaseControllerGetReleaseParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerGetRelease>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof releaseControllerGetRelease>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReleaseControllerGetReleaseQueryKey(releaseId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof releaseControllerGetRelease>>> = () => releaseControllerGetRelease(releaseId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(releaseId), ...queryOptions}}

export type ReleaseControllerGetReleaseQueryResult = NonNullable<Awaited<ReturnType<typeof releaseControllerGetRelease>>>
export type ReleaseControllerGetReleaseQueryError = unknown

export const useReleaseControllerGetRelease = <TData = Awaited<ReturnType<typeof releaseControllerGetRelease>>, TError = unknown>(
 releaseId: number,
    params?: ReleaseControllerGetReleaseParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerGetRelease>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReleaseControllerGetReleaseQueryOptions(releaseId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list all change of a release
 */
export const releaseControllerListChangesOfRelease = (
    releaseId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ReleaseListChangeResp>(
      {url: `/api/v1/devops/release/releases/${releaseId}/changes`, method: 'get'
    },
      options);
    }
  

export const getReleaseControllerListChangesOfReleaseQueryKey = (releaseId: number,) => [`/api/v1/devops/release/releases/${releaseId}/changes`] as const;
  

    
export const getReleaseControllerListChangesOfReleaseQueryOptions = <TData = Awaited<ReturnType<typeof releaseControllerListChangesOfRelease>>, TError = unknown>(releaseId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerListChangesOfRelease>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof releaseControllerListChangesOfRelease>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReleaseControllerListChangesOfReleaseQueryKey(releaseId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof releaseControllerListChangesOfRelease>>> = () => releaseControllerListChangesOfRelease(releaseId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(releaseId), ...queryOptions}}

export type ReleaseControllerListChangesOfReleaseQueryResult = NonNullable<Awaited<ReturnType<typeof releaseControllerListChangesOfRelease>>>
export type ReleaseControllerListChangesOfReleaseQueryError = unknown

export const useReleaseControllerListChangesOfRelease = <TData = Awaited<ReturnType<typeof releaseControllerListChangesOfRelease>>, TError = unknown>(
 releaseId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerListChangesOfRelease>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReleaseControllerListChangesOfReleaseQueryOptions(releaseId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list tickets
 */
export const releaseControllerListTickets = (
    params?: ReleaseControllerListTicketsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ReleaseListTicketsResp>(
      {url: `/api/v1/devops/release/tickets`, method: 'get',
        params
    },
      options);
    }
  

export const getReleaseControllerListTicketsQueryKey = (params?: ReleaseControllerListTicketsParams,) => [`/api/v1/devops/release/tickets`, ...(params ? [params]: [])] as const;
  

    
export const getReleaseControllerListTicketsQueryOptions = <TData = Awaited<ReturnType<typeof releaseControllerListTickets>>, TError = unknown>(params?: ReleaseControllerListTicketsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerListTickets>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof releaseControllerListTickets>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReleaseControllerListTicketsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof releaseControllerListTickets>>> = () => releaseControllerListTickets(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ReleaseControllerListTicketsQueryResult = NonNullable<Awaited<ReturnType<typeof releaseControllerListTickets>>>
export type ReleaseControllerListTicketsQueryError = unknown

export const useReleaseControllerListTickets = <TData = Awaited<ReturnType<typeof releaseControllerListTickets>>, TError = unknown>(
 params?: ReleaseControllerListTicketsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerListTickets>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReleaseControllerListTicketsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get a ticket
 */
export const releaseControllerGetTicket = (
    ticketName: string,
    params?: ReleaseControllerGetTicketParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ReleaseGetTicketResp>(
      {url: `/api/v1/devops/release/tickets/${ticketName}`, method: 'get',
        params
    },
      options);
    }
  

export const getReleaseControllerGetTicketQueryKey = (ticketName: string,
    params?: ReleaseControllerGetTicketParams,) => [`/api/v1/devops/release/tickets/${ticketName}`, ...(params ? [params]: [])] as const;
  

    
export const getReleaseControllerGetTicketQueryOptions = <TData = Awaited<ReturnType<typeof releaseControllerGetTicket>>, TError = unknown>(ticketName: string,
    params?: ReleaseControllerGetTicketParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerGetTicket>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof releaseControllerGetTicket>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReleaseControllerGetTicketQueryKey(ticketName,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof releaseControllerGetTicket>>> = () => releaseControllerGetTicket(ticketName,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(ticketName), ...queryOptions}}

export type ReleaseControllerGetTicketQueryResult = NonNullable<Awaited<ReturnType<typeof releaseControllerGetTicket>>>
export type ReleaseControllerGetTicketQueryError = unknown

export const useReleaseControllerGetTicket = <TData = Awaited<ReturnType<typeof releaseControllerGetTicket>>, TError = unknown>(
 ticketName: string,
    params?: ReleaseControllerGetTicketParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof releaseControllerGetTicket>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getReleaseControllerGetTicketQueryOptions(ticketName,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update a ticket
 */
export const releaseControllerUpdateTicket = (
    ticketName: string,
    releaseUpdateTicketReq: ReleaseUpdateTicketReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/release/tickets/${ticketName}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: releaseUpdateTicketReq
    },
      options);
    }
  


export const getReleaseControllerUpdateTicketMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerUpdateTicket>>, TError,{ticketName: string;data: ReleaseUpdateTicketReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof releaseControllerUpdateTicket>>, TError,{ticketName: string;data: ReleaseUpdateTicketReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof releaseControllerUpdateTicket>>, {ticketName: string;data: ReleaseUpdateTicketReq}> = (props) => {
          const {ticketName,data} = props ?? {};

          return  releaseControllerUpdateTicket(ticketName,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ReleaseControllerUpdateTicketMutationResult = NonNullable<Awaited<ReturnType<typeof releaseControllerUpdateTicket>>>
    export type ReleaseControllerUpdateTicketMutationBody = ReleaseUpdateTicketReq
    export type ReleaseControllerUpdateTicketMutationError = unknown

    export const useReleaseControllerUpdateTicket = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof releaseControllerUpdateTicket>>, TError,{ticketName: string;data: ReleaseUpdateTicketReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getReleaseControllerUpdateTicketMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list remediation tickets
 */
export const remediationTicketControllerListTickets = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<RemediationticketListRemediationTicketsResp>(
      {url: `/api/v1/devops/remediationtickets`, method: 'get'
    },
      options);
    }
  

export const getRemediationTicketControllerListTicketsQueryKey = () => [`/api/v1/devops/remediationtickets`] as const;
  

    
export const getRemediationTicketControllerListTicketsQueryOptions = <TData = Awaited<ReturnType<typeof remediationTicketControllerListTickets>>, TError = ApiBaseResp>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof remediationTicketControllerListTickets>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof remediationTicketControllerListTickets>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRemediationTicketControllerListTicketsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof remediationTicketControllerListTickets>>> = () => remediationTicketControllerListTickets(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type RemediationTicketControllerListTicketsQueryResult = NonNullable<Awaited<ReturnType<typeof remediationTicketControllerListTickets>>>
export type RemediationTicketControllerListTicketsQueryError = ApiBaseResp

export const useRemediationTicketControllerListTickets = <TData = Awaited<ReturnType<typeof remediationTicketControllerListTickets>>, TError = ApiBaseResp>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof remediationTicketControllerListTickets>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRemediationTicketControllerListTicketsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create Ticket
 */
export const remediationTicketControllerCreateTicket = (
    remediationticketCreateRemediationTicketReq: RemediationticketCreateRemediationTicketReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<RemediationticketCreateRemediationTicketResp>(
      {url: `/api/v1/devops/remediationtickets`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: remediationticketCreateRemediationTicketReq
    },
      options);
    }
  


export const getRemediationTicketControllerCreateTicketMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerCreateTicket>>, TError,{data: RemediationticketCreateRemediationTicketReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerCreateTicket>>, TError,{data: RemediationticketCreateRemediationTicketReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof remediationTicketControllerCreateTicket>>, {data: RemediationticketCreateRemediationTicketReq}> = (props) => {
          const {data} = props ?? {};

          return  remediationTicketControllerCreateTicket(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RemediationTicketControllerCreateTicketMutationResult = NonNullable<Awaited<ReturnType<typeof remediationTicketControllerCreateTicket>>>
    export type RemediationTicketControllerCreateTicketMutationBody = RemediationticketCreateRemediationTicketReq
    export type RemediationTicketControllerCreateTicketMutationError = ApiBaseResp

    export const useRemediationTicketControllerCreateTicket = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerCreateTicket>>, TError,{data: RemediationticketCreateRemediationTicketReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getRemediationTicketControllerCreateTicketMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get a remediation ticket
 */
export const remediationTicketControllerGetTicket = (
    ticketId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<RemediationticketGetRemediationTicketResp>(
      {url: `/api/v1/devops/remediationtickets/${ticketId}`, method: 'get'
    },
      options);
    }
  

export const getRemediationTicketControllerGetTicketQueryKey = (ticketId: number,) => [`/api/v1/devops/remediationtickets/${ticketId}`] as const;
  

    
export const getRemediationTicketControllerGetTicketQueryOptions = <TData = Awaited<ReturnType<typeof remediationTicketControllerGetTicket>>, TError = ApiBaseResp>(ticketId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof remediationTicketControllerGetTicket>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof remediationTicketControllerGetTicket>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRemediationTicketControllerGetTicketQueryKey(ticketId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof remediationTicketControllerGetTicket>>> = () => remediationTicketControllerGetTicket(ticketId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(ticketId), ...queryOptions}}

export type RemediationTicketControllerGetTicketQueryResult = NonNullable<Awaited<ReturnType<typeof remediationTicketControllerGetTicket>>>
export type RemediationTicketControllerGetTicketQueryError = ApiBaseResp

export const useRemediationTicketControllerGetTicket = <TData = Awaited<ReturnType<typeof remediationTicketControllerGetTicket>>, TError = ApiBaseResp>(
 ticketId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof remediationTicketControllerGetTicket>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRemediationTicketControllerGetTicketQueryOptions(ticketId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * transfer a remediation ticket phase
 */
export const remediationTicketControllerApproveTicketPhase = (
    ticketId: number,
    remediationticketApproveTicketPhaseReq: RemediationticketApproveTicketPhaseReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/remediationtickets/${ticketId}/approve`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: remediationticketApproveTicketPhaseReq
    },
      options);
    }
  


export const getRemediationTicketControllerApproveTicketPhaseMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerApproveTicketPhase>>, TError,{ticketId: number;data: RemediationticketApproveTicketPhaseReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerApproveTicketPhase>>, TError,{ticketId: number;data: RemediationticketApproveTicketPhaseReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof remediationTicketControllerApproveTicketPhase>>, {ticketId: number;data: RemediationticketApproveTicketPhaseReq}> = (props) => {
          const {ticketId,data} = props ?? {};

          return  remediationTicketControllerApproveTicketPhase(ticketId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RemediationTicketControllerApproveTicketPhaseMutationResult = NonNullable<Awaited<ReturnType<typeof remediationTicketControllerApproveTicketPhase>>>
    export type RemediationTicketControllerApproveTicketPhaseMutationBody = RemediationticketApproveTicketPhaseReq
    export type RemediationTicketControllerApproveTicketPhaseMutationError = unknown

    export const useRemediationTicketControllerApproveTicketPhase = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerApproveTicketPhase>>, TError,{ticketId: number;data: RemediationticketApproveTicketPhaseReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getRemediationTicketControllerApproveTicketPhaseMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * cancel a remediation ticket
 */
export const remediationTicketControllerCancelTicket = (
    ticketId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/remediationtickets/${ticketId}/cancel`, method: 'post'
    },
      options);
    }
  


export const getRemediationTicketControllerCancelTicketMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerCancelTicket>>, TError,{ticketId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerCancelTicket>>, TError,{ticketId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof remediationTicketControllerCancelTicket>>, {ticketId: number}> = (props) => {
          const {ticketId} = props ?? {};

          return  remediationTicketControllerCancelTicket(ticketId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RemediationTicketControllerCancelTicketMutationResult = NonNullable<Awaited<ReturnType<typeof remediationTicketControllerCancelTicket>>>
    
    export type RemediationTicketControllerCancelTicketMutationError = unknown

    export const useRemediationTicketControllerCancelTicket = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerCancelTicket>>, TError,{ticketId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getRemediationTicketControllerCancelTicketMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * generate final doc for the remediation ticket
 */
export const remediationTicketControllerGenerateFinalDoc = (
    ticketId: number,
    remediationticketGenerateFinalDocReq: RemediationticketGenerateFinalDocReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/remediationtickets/${ticketId}/generate_doc`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: remediationticketGenerateFinalDocReq
    },
      options);
    }
  


export const getRemediationTicketControllerGenerateFinalDocMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerGenerateFinalDoc>>, TError,{ticketId: number;data: RemediationticketGenerateFinalDocReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerGenerateFinalDoc>>, TError,{ticketId: number;data: RemediationticketGenerateFinalDocReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof remediationTicketControllerGenerateFinalDoc>>, {ticketId: number;data: RemediationticketGenerateFinalDocReq}> = (props) => {
          const {ticketId,data} = props ?? {};

          return  remediationTicketControllerGenerateFinalDoc(ticketId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RemediationTicketControllerGenerateFinalDocMutationResult = NonNullable<Awaited<ReturnType<typeof remediationTicketControllerGenerateFinalDoc>>>
    export type RemediationTicketControllerGenerateFinalDocMutationBody = RemediationticketGenerateFinalDocReq
    export type RemediationTicketControllerGenerateFinalDocMutationError = unknown

    export const useRemediationTicketControllerGenerateFinalDoc = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerGenerateFinalDoc>>, TError,{ticketId: number;data: RemediationticketGenerateFinalDocReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getRemediationTicketControllerGenerateFinalDocMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get remediation ticket progress
 */
export const remediationTicketControllerGetTicketProgress = (
    ticketId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<RemediationticketGetProgressResp>(
      {url: `/api/v1/devops/remediationtickets/${ticketId}/progress`, method: 'get'
    },
      options);
    }
  

export const getRemediationTicketControllerGetTicketProgressQueryKey = (ticketId: number,) => [`/api/v1/devops/remediationtickets/${ticketId}/progress`] as const;
  

    
export const getRemediationTicketControllerGetTicketProgressQueryOptions = <TData = Awaited<ReturnType<typeof remediationTicketControllerGetTicketProgress>>, TError = ApiBaseResp>(ticketId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof remediationTicketControllerGetTicketProgress>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof remediationTicketControllerGetTicketProgress>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRemediationTicketControllerGetTicketProgressQueryKey(ticketId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof remediationTicketControllerGetTicketProgress>>> = () => remediationTicketControllerGetTicketProgress(ticketId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(ticketId), ...queryOptions}}

export type RemediationTicketControllerGetTicketProgressQueryResult = NonNullable<Awaited<ReturnType<typeof remediationTicketControllerGetTicketProgress>>>
export type RemediationTicketControllerGetTicketProgressQueryError = ApiBaseResp

export const useRemediationTicketControllerGetTicketProgress = <TData = Awaited<ReturnType<typeof remediationTicketControllerGetTicketProgress>>, TError = ApiBaseResp>(
 ticketId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof remediationTicketControllerGetTicketProgress>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRemediationTicketControllerGetTicketProgressQueryOptions(ticketId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * transfer a remediation ticket phase
 */
export const remediationTicketControllerRejectTicketPhase = (
    ticketId: number,
    remediationticketRejectTicketPhaseReq: RemediationticketRejectTicketPhaseReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/remediationtickets/${ticketId}/reject`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: remediationticketRejectTicketPhaseReq
    },
      options);
    }
  


export const getRemediationTicketControllerRejectTicketPhaseMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerRejectTicketPhase>>, TError,{ticketId: number;data: RemediationticketRejectTicketPhaseReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerRejectTicketPhase>>, TError,{ticketId: number;data: RemediationticketRejectTicketPhaseReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof remediationTicketControllerRejectTicketPhase>>, {ticketId: number;data: RemediationticketRejectTicketPhaseReq}> = (props) => {
          const {ticketId,data} = props ?? {};

          return  remediationTicketControllerRejectTicketPhase(ticketId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RemediationTicketControllerRejectTicketPhaseMutationResult = NonNullable<Awaited<ReturnType<typeof remediationTicketControllerRejectTicketPhase>>>
    export type RemediationTicketControllerRejectTicketPhaseMutationBody = RemediationticketRejectTicketPhaseReq
    export type RemediationTicketControllerRejectTicketPhaseMutationError = unknown

    export const useRemediationTicketControllerRejectTicketPhase = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerRejectTicketPhase>>, TError,{ticketId: number;data: RemediationticketRejectTicketPhaseReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getRemediationTicketControllerRejectTicketPhaseMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * transfer a remediation ticket phase
 */
export const remediationTicketControllerSubmitTicketPhase = (
    ticketId: number,
    remediationticketSubmitTicketPhaseReq: RemediationticketSubmitTicketPhaseReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/remediationtickets/${ticketId}/submit`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: remediationticketSubmitTicketPhaseReq
    },
      options);
    }
  


export const getRemediationTicketControllerSubmitTicketPhaseMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerSubmitTicketPhase>>, TError,{ticketId: number;data: RemediationticketSubmitTicketPhaseReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerSubmitTicketPhase>>, TError,{ticketId: number;data: RemediationticketSubmitTicketPhaseReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof remediationTicketControllerSubmitTicketPhase>>, {ticketId: number;data: RemediationticketSubmitTicketPhaseReq}> = (props) => {
          const {ticketId,data} = props ?? {};

          return  remediationTicketControllerSubmitTicketPhase(ticketId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RemediationTicketControllerSubmitTicketPhaseMutationResult = NonNullable<Awaited<ReturnType<typeof remediationTicketControllerSubmitTicketPhase>>>
    export type RemediationTicketControllerSubmitTicketPhaseMutationBody = RemediationticketSubmitTicketPhaseReq
    export type RemediationTicketControllerSubmitTicketPhaseMutationError = unknown

    export const useRemediationTicketControllerSubmitTicketPhase = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof remediationTicketControllerSubmitTicketPhase>>, TError,{ticketId: number;data: RemediationticketSubmitTicketPhaseReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getRemediationTicketControllerSubmitTicketPhaseMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get remediation ticket approvals
 */
export const remediationTicketControllerGetTicketApprovals = (
    ticketId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<RemediationticketGetTicketApprovalsResp>(
      {url: `/api/v1/devops/remediationtickets/${ticketId}/ticket_approvals`, method: 'get'
    },
      options);
    }
  

export const getRemediationTicketControllerGetTicketApprovalsQueryKey = (ticketId: number,) => [`/api/v1/devops/remediationtickets/${ticketId}/ticket_approvals`] as const;
  

    
export const getRemediationTicketControllerGetTicketApprovalsQueryOptions = <TData = Awaited<ReturnType<typeof remediationTicketControllerGetTicketApprovals>>, TError = ApiBaseResp>(ticketId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof remediationTicketControllerGetTicketApprovals>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof remediationTicketControllerGetTicketApprovals>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRemediationTicketControllerGetTicketApprovalsQueryKey(ticketId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof remediationTicketControllerGetTicketApprovals>>> = () => remediationTicketControllerGetTicketApprovals(ticketId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(ticketId), ...queryOptions}}

export type RemediationTicketControllerGetTicketApprovalsQueryResult = NonNullable<Awaited<ReturnType<typeof remediationTicketControllerGetTicketApprovals>>>
export type RemediationTicketControllerGetTicketApprovalsQueryError = ApiBaseResp

export const useRemediationTicketControllerGetTicketApprovals = <TData = Awaited<ReturnType<typeof remediationTicketControllerGetTicketApprovals>>, TError = ApiBaseResp>(
 ticketId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof remediationTicketControllerGetTicketApprovals>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRemediationTicketControllerGetTicketApprovalsQueryOptions(ticketId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list roles
 */
export const listRoles = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<RbacListRolesResp>(
      {url: `/api/v1/devops/roles`, method: 'get'
    },
      options);
    }
  

export const getListRolesQueryKey = () => [`/api/v1/devops/roles`] as const;
  

    
export const getListRolesQueryOptions = <TData = Awaited<ReturnType<typeof listRoles>>, TError = ApiBaseResp>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listRoles>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listRoles>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListRolesQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listRoles>>> = () => listRoles(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListRolesQueryResult = NonNullable<Awaited<ReturnType<typeof listRoles>>>
export type ListRolesQueryError = ApiBaseResp

export const useListRoles = <TData = Awaited<ReturnType<typeof listRoles>>, TError = ApiBaseResp>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listRoles>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListRolesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create role
 */
export const createRole = (
    rbacCreateRoleReq: RbacCreateRoleReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiEmptyResp>(
      {url: `/api/v1/devops/roles`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: rbacCreateRoleReq
    },
      options);
    }
  


export const getCreateRoleMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRole>>, TError,{data: RbacCreateRoleReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createRole>>, TError,{data: RbacCreateRoleReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createRole>>, {data: RbacCreateRoleReq}> = (props) => {
          const {data} = props ?? {};

          return  createRole(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateRoleMutationResult = NonNullable<Awaited<ReturnType<typeof createRole>>>
    export type CreateRoleMutationBody = RbacCreateRoleReq
    export type CreateRoleMutationError = ApiBaseResp

    export const useCreateRole = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRole>>, TError,{data: RbacCreateRoleReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateRoleMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get role by id
 */
export const getRole = (
    roleId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<RbacGetRoleResp>(
      {url: `/api/v1/devops/roles/${roleId}`, method: 'get'
    },
      options);
    }
  

export const getGetRoleQueryKey = (roleId: number,) => [`/api/v1/devops/roles/${roleId}`] as const;
  

    
export const getGetRoleQueryOptions = <TData = Awaited<ReturnType<typeof getRole>>, TError = ApiBaseResp>(roleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRole>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getRole>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRoleQueryKey(roleId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRole>>> = () => getRole(roleId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(roleId), ...queryOptions}}

export type GetRoleQueryResult = NonNullable<Awaited<ReturnType<typeof getRole>>>
export type GetRoleQueryError = ApiBaseResp

export const useGetRole = <TData = Awaited<ReturnType<typeof getRole>>, TError = ApiBaseResp>(
 roleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRole>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRoleQueryOptions(roleId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update role
 */
export const updateRole = (
    roleId: number,
    rbacUpdateRoleReq: RbacUpdateRoleReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiEmptyResp>(
      {url: `/api/v1/devops/roles/${roleId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: rbacUpdateRoleReq
    },
      options);
    }
  


export const getUpdateRoleMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRole>>, TError,{roleId: number;data: RbacUpdateRoleReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateRole>>, TError,{roleId: number;data: RbacUpdateRoleReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRole>>, {roleId: number;data: RbacUpdateRoleReq}> = (props) => {
          const {roleId,data} = props ?? {};

          return  updateRole(roleId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateRoleMutationResult = NonNullable<Awaited<ReturnType<typeof updateRole>>>
    export type UpdateRoleMutationBody = RbacUpdateRoleReq
    export type UpdateRoleMutationError = ApiBaseResp

    export const useUpdateRole = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRole>>, TError,{roleId: number;data: RbacUpdateRoleReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateRoleMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * delete role
 */
export const deleteRole = (
    roleId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiEmptyResp>(
      {url: `/api/v1/devops/roles/${roleId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteRoleMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRole>>, TError,{roleId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteRole>>, TError,{roleId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRole>>, {roleId: number}> = (props) => {
          const {roleId} = props ?? {};

          return  deleteRole(roleId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteRoleMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRole>>>
    
    export type DeleteRoleMutationError = ApiBaseResp

    export const useDeleteRole = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRole>>, TError,{roleId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteRoleMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Apply a Secondary Confirm
 */
export const applySecondaryConfirm = (
    secondaryconfirmApplyReq: SecondaryconfirmApplyReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/secondaryconfirm/apply`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: secondaryconfirmApplyReq
    },
      options);
    }
  


export const getApplySecondaryConfirmMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof applySecondaryConfirm>>, TError,{data: SecondaryconfirmApplyReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof applySecondaryConfirm>>, TError,{data: SecondaryconfirmApplyReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof applySecondaryConfirm>>, {data: SecondaryconfirmApplyReq}> = (props) => {
          const {data} = props ?? {};

          return  applySecondaryConfirm(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ApplySecondaryConfirmMutationResult = NonNullable<Awaited<ReturnType<typeof applySecondaryConfirm>>>
    export type ApplySecondaryConfirmMutationBody = SecondaryconfirmApplyReq
    export type ApplySecondaryConfirmMutationError = unknown

    export const useApplySecondaryConfirm = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof applySecondaryConfirm>>, TError,{data: SecondaryconfirmApplyReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getApplySecondaryConfirmMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Get Secondary-Confirm Diff Info
 */
export const getSecondaryConfirmDiff = (
    params?: GetSecondaryConfirmDiffParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<SecondaryconfirmDiffResp>(
      {url: `/api/v1/devops/secondaryconfirm/diff`, method: 'get',
        params
    },
      options);
    }
  

export const getGetSecondaryConfirmDiffQueryKey = (params?: GetSecondaryConfirmDiffParams,) => [`/api/v1/devops/secondaryconfirm/diff`, ...(params ? [params]: [])] as const;
  

    
export const getGetSecondaryConfirmDiffQueryOptions = <TData = Awaited<ReturnType<typeof getSecondaryConfirmDiff>>, TError = ApiBaseResp>(params?: GetSecondaryConfirmDiffParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSecondaryConfirmDiff>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getSecondaryConfirmDiff>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSecondaryConfirmDiffQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSecondaryConfirmDiff>>> = () => getSecondaryConfirmDiff(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetSecondaryConfirmDiffQueryResult = NonNullable<Awaited<ReturnType<typeof getSecondaryConfirmDiff>>>
export type GetSecondaryConfirmDiffQueryError = ApiBaseResp

export const useGetSecondaryConfirmDiff = <TData = Awaited<ReturnType<typeof getSecondaryConfirmDiff>>, TError = ApiBaseResp>(
 params?: GetSecondaryConfirmDiffParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSecondaryConfirmDiff>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSecondaryConfirmDiffQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * List Secondary Confirms
 */
export const listSecondaryConfirms = (
    params?: ListSecondaryConfirmsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<SecondaryconfirmListResp>(
      {url: `/api/v1/devops/secondaryconfirm/list`, method: 'get',
        params
    },
      options);
    }
  

export const getListSecondaryConfirmsQueryKey = (params?: ListSecondaryConfirmsParams,) => [`/api/v1/devops/secondaryconfirm/list`, ...(params ? [params]: [])] as const;
  

    
export const getListSecondaryConfirmsQueryOptions = <TData = Awaited<ReturnType<typeof listSecondaryConfirms>>, TError = ApiBaseResp>(params?: ListSecondaryConfirmsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listSecondaryConfirms>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listSecondaryConfirms>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListSecondaryConfirmsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listSecondaryConfirms>>> = () => listSecondaryConfirms(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListSecondaryConfirmsQueryResult = NonNullable<Awaited<ReturnType<typeof listSecondaryConfirms>>>
export type ListSecondaryConfirmsQueryError = ApiBaseResp

export const useListSecondaryConfirms = <TData = Awaited<ReturnType<typeof listSecondaryConfirms>>, TError = ApiBaseResp>(
 params?: ListSecondaryConfirmsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listSecondaryConfirms>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListSecondaryConfirmsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list tenants
 */
export const listTenants = (
    params?: ListTenantsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantListTenantResp>(
      {url: `/api/v1/devops/tenants`, method: 'get',
        params
    },
      options);
    }
  

export const getListTenantsQueryKey = (params?: ListTenantsParams,) => [`/api/v1/devops/tenants`, ...(params ? [params]: [])] as const;
  

    
export const getListTenantsQueryOptions = <TData = Awaited<ReturnType<typeof listTenants>>, TError = ApiBaseResp>(params?: ListTenantsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenants>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listTenants>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenants>>> = () => listTenants(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListTenantsQueryResult = NonNullable<Awaited<ReturnType<typeof listTenants>>>
export type ListTenantsQueryError = ApiBaseResp

export const useListTenants = <TData = Awaited<ReturnType<typeof listTenants>>, TError = ApiBaseResp>(
 params?: ListTenantsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenants>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTenantsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get impersonate
 */
export const getImpersonate = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantGetImpersonateResp>(
      {url: `/api/v1/devops/tenants/impersonate`, method: 'get'
    },
      options);
    }
  

export const getGetImpersonateQueryKey = () => [`/api/v1/devops/tenants/impersonate`] as const;
  

    
export const getGetImpersonateQueryOptions = <TData = Awaited<ReturnType<typeof getImpersonate>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getImpersonate>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getImpersonate>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetImpersonateQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getImpersonate>>> = () => getImpersonate(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetImpersonateQueryResult = NonNullable<Awaited<ReturnType<typeof getImpersonate>>>
export type GetImpersonateQueryError = unknown

export const useGetImpersonate = <TData = Awaited<ReturnType<typeof getImpersonate>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getImpersonate>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetImpersonateQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * add impersonate
 */
export const addImpersonate = (
    tenantAddImpersonateReq: TenantAddImpersonateReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantAddImpersonateResp>(
      {url: `/api/v1/devops/tenants/impersonate`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: tenantAddImpersonateReq
    },
      options);
    }
  


export const getAddImpersonateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addImpersonate>>, TError,{data: TenantAddImpersonateReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof addImpersonate>>, TError,{data: TenantAddImpersonateReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addImpersonate>>, {data: TenantAddImpersonateReq}> = (props) => {
          const {data} = props ?? {};

          return  addImpersonate(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AddImpersonateMutationResult = NonNullable<Awaited<ReturnType<typeof addImpersonate>>>
    export type AddImpersonateMutationBody = TenantAddImpersonateReq
    export type AddImpersonateMutationError = unknown

    export const useAddImpersonate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addImpersonate>>, TError,{data: TenantAddImpersonateReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getAddImpersonateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * delete impersonate
 */
export const deleteImpersonate = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/tenants/impersonate`, method: 'delete'
    },
      options);
    }
  


export const getDeleteImpersonateMutationOptions = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteImpersonate>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteImpersonate>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteImpersonate>>, TVariables> = () => {
          

          return  deleteImpersonate(requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteImpersonateMutationResult = NonNullable<Awaited<ReturnType<typeof deleteImpersonate>>>
    
    export type DeleteImpersonateMutationError = unknown

    export const useDeleteImpersonate = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteImpersonate>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteImpersonateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get tenant
 */
export const getTenant = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantGetTenantResp>(
      {url: `/api/v1/devops/tenants/${tenantId}`, method: 'get'
    },
      options);
    }
  

export const getGetTenantQueryKey = (tenantId: string,) => [`/api/v1/devops/tenants/${tenantId}`] as const;
  

    
export const getGetTenantQueryOptions = <TData = Awaited<ReturnType<typeof getTenant>>, TError = ApiBaseResp>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenant>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenant>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenant>>> = () => getTenant(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetTenantQueryResult = NonNullable<Awaited<ReturnType<typeof getTenant>>>
export type GetTenantQueryError = ApiBaseResp

export const useGetTenant = <TData = Awaited<ReturnType<typeof getTenant>>, TError = ApiBaseResp>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenant>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update tenant
 */
export const updateTenant = (
    tenantId: string,
    tenantUpdateTenantReq: TenantUpdateTenantReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantCommonResp>(
      {url: `/api/v1/devops/tenants/${tenantId}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: tenantUpdateTenantReq
    },
      options);
    }
  


export const getUpdateTenantMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTenant>>, TError,{tenantId: string;data: TenantUpdateTenantReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTenant>>, TError,{tenantId: string;data: TenantUpdateTenantReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTenant>>, {tenantId: string;data: TenantUpdateTenantReq}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  updateTenant(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateTenantMutationResult = NonNullable<Awaited<ReturnType<typeof updateTenant>>>
    export type UpdateTenantMutationBody = TenantUpdateTenantReq
    export type UpdateTenantMutationError = unknown

    export const useUpdateTenant = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTenant>>, TError,{tenantId: string;data: TenantUpdateTenantReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateTenantMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * delete tenant
 */
export const deleteTenant = (
    tenantId: string,
    tenantDeleteTenantReq: TenantDeleteTenantReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantCommonResp>(
      {url: `/api/v1/devops/tenants/${tenantId}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: tenantDeleteTenantReq
    },
      options);
    }
  


export const getDeleteTenantMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTenant>>, TError,{tenantId: string;data: TenantDeleteTenantReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTenant>>, TError,{tenantId: string;data: TenantDeleteTenantReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTenant>>, {tenantId: string;data: TenantDeleteTenantReq}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  deleteTenant(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteTenantMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTenant>>>
    export type DeleteTenantMutationBody = TenantDeleteTenantReq
    export type DeleteTenantMutationError = unknown

    export const useDeleteTenant = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTenant>>, TError,{tenantId: string;data: TenantDeleteTenantReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteTenantMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get tenant users
 */
export const getTenantUsers = (
    tenantId: string,
    params?: GetTenantUsersParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantGetUsersResp>(
      {url: `/api/v1/devops/tenants/${tenantId}/accountusers`, method: 'get',
        params
    },
      options);
    }
  

export const getGetTenantUsersQueryKey = (tenantId: string,
    params?: GetTenantUsersParams,) => [`/api/v1/devops/tenants/${tenantId}/accountusers`, ...(params ? [params]: [])] as const;
  

    
export const getGetTenantUsersQueryOptions = <TData = Awaited<ReturnType<typeof getTenantUsers>>, TError = unknown>(tenantId: string,
    params?: GetTenantUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantUsers>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantUsersQueryKey(tenantId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantUsers>>> = () => getTenantUsers(tenantId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetTenantUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantUsers>>>
export type GetTenantUsersQueryError = unknown

export const useGetTenantUsers = <TData = Awaited<ReturnType<typeof getTenantUsers>>, TError = unknown>(
 tenantId: string,
    params?: GetTenantUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantUsersQueryOptions(tenantId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * add user to tenant
 */
export const addUserToTenant = (
    tenantId: string,
    userId: string,
    tenantAddUserToTenantReq: TenantAddUserToTenantReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantCommonResp>(
      {url: `/api/v1/devops/tenants/${tenantId}/accountusers/${userId}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: tenantAddUserToTenantReq
    },
      options);
    }
  


export const getAddUserToTenantMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addUserToTenant>>, TError,{tenantId: string;userId: string;data: TenantAddUserToTenantReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof addUserToTenant>>, TError,{tenantId: string;userId: string;data: TenantAddUserToTenantReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addUserToTenant>>, {tenantId: string;userId: string;data: TenantAddUserToTenantReq}> = (props) => {
          const {tenantId,userId,data} = props ?? {};

          return  addUserToTenant(tenantId,userId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AddUserToTenantMutationResult = NonNullable<Awaited<ReturnType<typeof addUserToTenant>>>
    export type AddUserToTenantMutationBody = TenantAddUserToTenantReq
    export type AddUserToTenantMutationError = unknown

    export const useAddUserToTenant = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addUserToTenant>>, TError,{tenantId: string;userId: string;data: TenantAddUserToTenantReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getAddUserToTenantMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * remove user from tenant
 */
export const removeUserFromTenant = (
    tenantId: string,
    userId: string,
    tenantRemoveUserFromTenantReq: TenantRemoveUserFromTenantReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantCommonResp>(
      {url: `/api/v1/devops/tenants/${tenantId}/accountusers/${userId}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: tenantRemoveUserFromTenantReq
    },
      options);
    }
  


export const getRemoveUserFromTenantMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeUserFromTenant>>, TError,{tenantId: string;userId: string;data: TenantRemoveUserFromTenantReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof removeUserFromTenant>>, TError,{tenantId: string;userId: string;data: TenantRemoveUserFromTenantReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeUserFromTenant>>, {tenantId: string;userId: string;data: TenantRemoveUserFromTenantReq}> = (props) => {
          const {tenantId,userId,data} = props ?? {};

          return  removeUserFromTenant(tenantId,userId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RemoveUserFromTenantMutationResult = NonNullable<Awaited<ReturnType<typeof removeUserFromTenant>>>
    export type RemoveUserFromTenantMutationBody = TenantRemoveUserFromTenantReq
    export type RemoveUserFromTenantMutationError = unknown

    export const useRemoveUserFromTenant = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeUserFromTenant>>, TError,{tenantId: string;userId: string;data: TenantRemoveUserFromTenantReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getRemoveUserFromTenantMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * add credits for tenant
 */
export const addCredits = (
    tenantId: string,
    tenantAddCreditsReq: TenantAddCreditsReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantCommonResp>(
      {url: `/api/v1/devops/tenants/${tenantId}/credits`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: tenantAddCreditsReq
    },
      options);
    }
  


export const getAddCreditsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCredits>>, TError,{tenantId: string;data: TenantAddCreditsReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof addCredits>>, TError,{tenantId: string;data: TenantAddCreditsReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addCredits>>, {tenantId: string;data: TenantAddCreditsReq}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  addCredits(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AddCreditsMutationResult = NonNullable<Awaited<ReturnType<typeof addCredits>>>
    export type AddCreditsMutationBody = TenantAddCreditsReq
    export type AddCreditsMutationError = unknown

    export const useAddCredits = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCredits>>, TError,{tenantId: string;data: TenantAddCreditsReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getAddCreditsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * delete project
 */
export const deleteProject = (
    tenantId: string,
    projectId: string,
    tenantDeleteProjectReq: TenantDeleteProjectReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantCommonResp>(
      {url: `/api/v1/devops/tenants/${tenantId}/projects/${projectId}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: tenantDeleteProjectReq
    },
      options);
    }
  


export const getDeleteProjectMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProject>>, TError,{tenantId: string;projectId: string;data: TenantDeleteProjectReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProject>>, TError,{tenantId: string;projectId: string;data: TenantDeleteProjectReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProject>>, {tenantId: string;projectId: string;data: TenantDeleteProjectReq}> = (props) => {
          const {tenantId,projectId,data} = props ?? {};

          return  deleteProject(tenantId,projectId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProject>>>
    export type DeleteProjectMutationBody = TenantDeleteProjectReq
    export type DeleteProjectMutationError = unknown

    export const useDeleteProject = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProject>>, TError,{tenantId: string;projectId: string;data: TenantDeleteProjectReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteProjectMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get project users
 */
export const getProjectUsers = (
    tenantId: string,
    projectId: string,
    params?: GetProjectUsersParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantGetUsersResp>(
      {url: `/api/v1/devops/tenants/${tenantId}/projects/${projectId}/accountusers`, method: 'get',
        params
    },
      options);
    }
  

export const getGetProjectUsersQueryKey = (tenantId: string,
    projectId: string,
    params?: GetProjectUsersParams,) => [`/api/v1/devops/tenants/${tenantId}/projects/${projectId}/accountusers`, ...(params ? [params]: [])] as const;
  

    
export const getGetProjectUsersQueryOptions = <TData = Awaited<ReturnType<typeof getProjectUsers>>, TError = unknown>(tenantId: string,
    projectId: string,
    params?: GetProjectUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getProjectUsers>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectUsersQueryKey(tenantId,projectId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectUsers>>> = () => getProjectUsers(tenantId,projectId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && projectId), ...queryOptions}}

export type GetProjectUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectUsers>>>
export type GetProjectUsersQueryError = unknown

export const useGetProjectUsers = <TData = Awaited<ReturnType<typeof getProjectUsers>>, TError = unknown>(
 tenantId: string,
    projectId: string,
    params?: GetProjectUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectUsersQueryOptions(tenantId,projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * add user to project
 */
export const addUserToProject = (
    tenantId: string,
    projectId: string,
    userId: string,
    tenantAddUserToProjectReq: TenantAddUserToProjectReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantCommonResp>(
      {url: `/api/v1/devops/tenants/${tenantId}/projects/${projectId}/accountusers/${userId}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: tenantAddUserToProjectReq
    },
      options);
    }
  


export const getAddUserToProjectMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addUserToProject>>, TError,{tenantId: string;projectId: string;userId: string;data: TenantAddUserToProjectReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof addUserToProject>>, TError,{tenantId: string;projectId: string;userId: string;data: TenantAddUserToProjectReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addUserToProject>>, {tenantId: string;projectId: string;userId: string;data: TenantAddUserToProjectReq}> = (props) => {
          const {tenantId,projectId,userId,data} = props ?? {};

          return  addUserToProject(tenantId,projectId,userId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AddUserToProjectMutationResult = NonNullable<Awaited<ReturnType<typeof addUserToProject>>>
    export type AddUserToProjectMutationBody = TenantAddUserToProjectReq
    export type AddUserToProjectMutationError = unknown

    export const useAddUserToProject = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addUserToProject>>, TError,{tenantId: string;projectId: string;userId: string;data: TenantAddUserToProjectReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getAddUserToProjectMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * remove user from project
 */
export const removeUserFromProject = (
    tenantId: string,
    projectId: string,
    userId: string,
    tenantRemoveUserFromProjectReq: TenantRemoveUserFromProjectReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantCommonResp>(
      {url: `/api/v1/devops/tenants/${tenantId}/projects/${projectId}/accountusers/${userId}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: tenantRemoveUserFromProjectReq
    },
      options);
    }
  


export const getRemoveUserFromProjectMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeUserFromProject>>, TError,{tenantId: string;projectId: string;userId: string;data: TenantRemoveUserFromProjectReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof removeUserFromProject>>, TError,{tenantId: string;projectId: string;userId: string;data: TenantRemoveUserFromProjectReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeUserFromProject>>, {tenantId: string;projectId: string;userId: string;data: TenantRemoveUserFromProjectReq}> = (props) => {
          const {tenantId,projectId,userId,data} = props ?? {};

          return  removeUserFromProject(tenantId,projectId,userId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RemoveUserFromProjectMutationResult = NonNullable<Awaited<ReturnType<typeof removeUserFromProject>>>
    export type RemoveUserFromProjectMutationBody = TenantRemoveUserFromProjectReq
    export type RemoveUserFromProjectMutationError = unknown

    export const useRemoveUserFromProject = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeUserFromProject>>, TError,{tenantId: string;projectId: string;userId: string;data: TenantRemoveUserFromProjectReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getRemoveUserFromProjectMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list tenant quota
 */
export const listTenantQuota = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<TenantListTenantQuotaResp>(
      {url: `/api/v1/devops/tenants/${tenantId}/quota`, method: 'get'
    },
      options);
    }
  

export const getListTenantQuotaQueryKey = (tenantId: string,) => [`/api/v1/devops/tenants/${tenantId}/quota`] as const;
  

    
export const getListTenantQuotaQueryOptions = <TData = Awaited<ReturnType<typeof listTenantQuota>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantQuota>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listTenantQuota>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantQuotaQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenantQuota>>> = () => listTenantQuota(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type ListTenantQuotaQueryResult = NonNullable<Awaited<ReturnType<typeof listTenantQuota>>>
export type ListTenantQuotaQueryError = unknown

export const useListTenantQuota = <TData = Awaited<ReturnType<typeof listTenantQuota>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantQuota>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTenantQuotaQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * list user groups
 */
export const userControllerListGroups = (
    params?: UserControllerListGroupsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserListGroupsResp>(
      {url: `/api/v1/devops/user/groups`, method: 'get',
        params
    },
      options);
    }
  

export const getUserControllerListGroupsQueryKey = (params?: UserControllerListGroupsParams,) => [`/api/v1/devops/user/groups`, ...(params ? [params]: [])] as const;
  

    
export const getUserControllerListGroupsQueryOptions = <TData = Awaited<ReturnType<typeof userControllerListGroups>>, TError = unknown>(params?: UserControllerListGroupsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerListGroups>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof userControllerListGroups>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerListGroupsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerListGroups>>> = () => userControllerListGroups(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type UserControllerListGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerListGroups>>>
export type UserControllerListGroupsQueryError = unknown

export const useUserControllerListGroups = <TData = Awaited<ReturnType<typeof userControllerListGroups>>, TError = unknown>(
 params?: UserControllerListGroupsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerListGroups>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerListGroupsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create a user group
 */
export const userControllerCreateGroup = (
    userCreateGroupReq: UserCreateGroupReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/user/groups`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userCreateGroupReq
    },
      options);
    }
  


export const getUserControllerCreateGroupMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerCreateGroup>>, TError,{data: UserCreateGroupReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userControllerCreateGroup>>, TError,{data: UserCreateGroupReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerCreateGroup>>, {data: UserCreateGroupReq}> = (props) => {
          const {data} = props ?? {};

          return  userControllerCreateGroup(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserControllerCreateGroupMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerCreateGroup>>>
    export type UserControllerCreateGroupMutationBody = UserCreateGroupReq
    export type UserControllerCreateGroupMutationError = unknown

    export const useUserControllerCreateGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerCreateGroup>>, TError,{data: UserCreateGroupReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUserControllerCreateGroupMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get a user group
 */
export const userControllerGetGroup = (
    groupName: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserGetGroupResp>(
      {url: `/api/v1/devops/user/groups/${groupName}`, method: 'get'
    },
      options);
    }
  

export const getUserControllerGetGroupQueryKey = (groupName: string,) => [`/api/v1/devops/user/groups/${groupName}`] as const;
  

    
export const getUserControllerGetGroupQueryOptions = <TData = Awaited<ReturnType<typeof userControllerGetGroup>>, TError = unknown>(groupName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetGroup>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof userControllerGetGroup>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserControllerGetGroupQueryKey(groupName);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof userControllerGetGroup>>> = () => userControllerGetGroup(groupName, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(groupName), ...queryOptions}}

export type UserControllerGetGroupQueryResult = NonNullable<Awaited<ReturnType<typeof userControllerGetGroup>>>
export type UserControllerGetGroupQueryError = unknown

export const useUserControllerGetGroup = <TData = Awaited<ReturnType<typeof userControllerGetGroup>>, TError = unknown>(
 groupName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userControllerGetGroup>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserControllerGetGroupQueryOptions(groupName,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update a user group
 */
export const userControllerUpdateGroup = (
    groupName: string,
    userUpdateGroupReq: UserUpdateGroupReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/user/groups/${groupName}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: userUpdateGroupReq
    },
      options);
    }
  


export const getUserControllerUpdateGroupMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdateGroup>>, TError,{groupName: string;data: UserUpdateGroupReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdateGroup>>, TError,{groupName: string;data: UserUpdateGroupReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerUpdateGroup>>, {groupName: string;data: UserUpdateGroupReq}> = (props) => {
          const {groupName,data} = props ?? {};

          return  userControllerUpdateGroup(groupName,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserControllerUpdateGroupMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerUpdateGroup>>>
    export type UserControllerUpdateGroupMutationBody = UserUpdateGroupReq
    export type UserControllerUpdateGroupMutationError = unknown

    export const useUserControllerUpdateGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUpdateGroup>>, TError,{groupName: string;data: UserUpdateGroupReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUserControllerUpdateGroupMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * delete a user group
 */
export const userControllerDeleteGroup = (
    groupName: string,
    userDeleteGroupReq: UserDeleteGroupReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/user/groups/${groupName}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: userDeleteGroupReq
    },
      options);
    }
  


export const getUserControllerDeleteGroupMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerDeleteGroup>>, TError,{groupName: string;data: UserDeleteGroupReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userControllerDeleteGroup>>, TError,{groupName: string;data: UserDeleteGroupReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerDeleteGroup>>, {groupName: string;data: UserDeleteGroupReq}> = (props) => {
          const {groupName,data} = props ?? {};

          return  userControllerDeleteGroup(groupName,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserControllerDeleteGroupMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerDeleteGroup>>>
    export type UserControllerDeleteGroupMutationBody = UserDeleteGroupReq
    export type UserControllerDeleteGroupMutationError = unknown

    export const useUserControllerDeleteGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerDeleteGroup>>, TError,{groupName: string;data: UserDeleteGroupReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUserControllerDeleteGroupMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * map users to a group
 */
export const userControllerMapUsersToGroup = (
    userMapUsersToGroupReq: UserMapUsersToGroupReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/user/user-group-mappings`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userMapUsersToGroupReq
    },
      options);
    }
  


export const getUserControllerMapUsersToGroupMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerMapUsersToGroup>>, TError,{data: UserMapUsersToGroupReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userControllerMapUsersToGroup>>, TError,{data: UserMapUsersToGroupReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerMapUsersToGroup>>, {data: UserMapUsersToGroupReq}> = (props) => {
          const {data} = props ?? {};

          return  userControllerMapUsersToGroup(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserControllerMapUsersToGroupMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerMapUsersToGroup>>>
    export type UserControllerMapUsersToGroupMutationBody = UserMapUsersToGroupReq
    export type UserControllerMapUsersToGroupMutationError = unknown

    export const useUserControllerMapUsersToGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerMapUsersToGroup>>, TError,{data: UserMapUsersToGroupReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUserControllerMapUsersToGroupMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * unmap users to a group
 */
export const userControllerUnmapUsersFromGroup = (
    userUnmapUsersFromGroupReq: UserUnmapUsersFromGroupReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/user/user-group-mappings`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: userUnmapUsersFromGroupReq
    },
      options);
    }
  


export const getUserControllerUnmapUsersFromGroupMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUnmapUsersFromGroup>>, TError,{data: UserUnmapUsersFromGroupReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userControllerUnmapUsersFromGroup>>, TError,{data: UserUnmapUsersFromGroupReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userControllerUnmapUsersFromGroup>>, {data: UserUnmapUsersFromGroupReq}> = (props) => {
          const {data} = props ?? {};

          return  userControllerUnmapUsersFromGroup(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserControllerUnmapUsersFromGroupMutationResult = NonNullable<Awaited<ReturnType<typeof userControllerUnmapUsersFromGroup>>>
    export type UserControllerUnmapUsersFromGroupMutationBody = UserUnmapUsersFromGroupReq
    export type UserControllerUnmapUsersFromGroupMutationError = unknown

    export const useUserControllerUnmapUsersFromGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userControllerUnmapUsersFromGroup>>, TError,{data: UserUnmapUsersFromGroupReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUserControllerUnmapUsersFromGroupMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list subscriptions
 */
export const userBehaviorControllerListSubscriptions = (
    params?: UserBehaviorControllerListSubscriptionsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserbehaviorListSubscriptionsResp>(
      {url: `/api/v1/devops/userbehavior/subscriptions`, method: 'get',
        params
    },
      options);
    }
  

export const getUserBehaviorControllerListSubscriptionsQueryKey = (params?: UserBehaviorControllerListSubscriptionsParams,) => [`/api/v1/devops/userbehavior/subscriptions`, ...(params ? [params]: [])] as const;
  

    
export const getUserBehaviorControllerListSubscriptionsQueryOptions = <TData = Awaited<ReturnType<typeof userBehaviorControllerListSubscriptions>>, TError = unknown>(params?: UserBehaviorControllerListSubscriptionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userBehaviorControllerListSubscriptions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof userBehaviorControllerListSubscriptions>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserBehaviorControllerListSubscriptionsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof userBehaviorControllerListSubscriptions>>> = () => userBehaviorControllerListSubscriptions(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type UserBehaviorControllerListSubscriptionsQueryResult = NonNullable<Awaited<ReturnType<typeof userBehaviorControllerListSubscriptions>>>
export type UserBehaviorControllerListSubscriptionsQueryError = unknown

export const useUserBehaviorControllerListSubscriptions = <TData = Awaited<ReturnType<typeof userBehaviorControllerListSubscriptions>>, TError = unknown>(
 params?: UserBehaviorControllerListSubscriptionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userBehaviorControllerListSubscriptions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserBehaviorControllerListSubscriptionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create an subscription
 */
export const userBehaviorControllerCreateSubscription = (
    userbehaviorCreateSubscriptionReq: UserbehaviorCreateSubscriptionReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserbehaviorCreateSubscriptionResp>(
      {url: `/api/v1/devops/userbehavior/subscriptions`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userbehaviorCreateSubscriptionReq
    },
      options);
    }
  


export const getUserBehaviorControllerCreateSubscriptionMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userBehaviorControllerCreateSubscription>>, TError,{data: UserbehaviorCreateSubscriptionReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userBehaviorControllerCreateSubscription>>, TError,{data: UserbehaviorCreateSubscriptionReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userBehaviorControllerCreateSubscription>>, {data: UserbehaviorCreateSubscriptionReq}> = (props) => {
          const {data} = props ?? {};

          return  userBehaviorControllerCreateSubscription(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserBehaviorControllerCreateSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof userBehaviorControllerCreateSubscription>>>
    export type UserBehaviorControllerCreateSubscriptionMutationBody = UserbehaviorCreateSubscriptionReq
    export type UserBehaviorControllerCreateSubscriptionMutationError = unknown

    export const useUserBehaviorControllerCreateSubscription = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userBehaviorControllerCreateSubscription>>, TError,{data: UserbehaviorCreateSubscriptionReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUserBehaviorControllerCreateSubscriptionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * add an external lark chat to lark chat list
 */
export const userBehaviorControllerAddExternalLarkChat = (
    userbehaviorAddExternalLarkChatReq: UserbehaviorAddExternalLarkChatReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/userbehavior/subscriptions/add_chat`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userbehaviorAddExternalLarkChatReq
    },
      options);
    }
  


export const getUserBehaviorControllerAddExternalLarkChatMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userBehaviorControllerAddExternalLarkChat>>, TError,{data: UserbehaviorAddExternalLarkChatReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userBehaviorControllerAddExternalLarkChat>>, TError,{data: UserbehaviorAddExternalLarkChatReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userBehaviorControllerAddExternalLarkChat>>, {data: UserbehaviorAddExternalLarkChatReq}> = (props) => {
          const {data} = props ?? {};

          return  userBehaviorControllerAddExternalLarkChat(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserBehaviorControllerAddExternalLarkChatMutationResult = NonNullable<Awaited<ReturnType<typeof userBehaviorControllerAddExternalLarkChat>>>
    export type UserBehaviorControllerAddExternalLarkChatMutationBody = UserbehaviorAddExternalLarkChatReq
    export type UserBehaviorControllerAddExternalLarkChatMutationError = unknown

    export const useUserBehaviorControllerAddExternalLarkChat = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userBehaviorControllerAddExternalLarkChat>>, TError,{data: UserbehaviorAddExternalLarkChatReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUserBehaviorControllerAddExternalLarkChatMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * get lark chat options
 */
export const userBehaviorControllerGetLarkChatOptions = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserbehaviorListLarkChatOptionsResp>(
      {url: `/api/v1/devops/userbehavior/subscriptions/larkchat_options`, method: 'get'
    },
      options);
    }
  

export const getUserBehaviorControllerGetLarkChatOptionsQueryKey = () => [`/api/v1/devops/userbehavior/subscriptions/larkchat_options`] as const;
  

    
export const getUserBehaviorControllerGetLarkChatOptionsQueryOptions = <TData = Awaited<ReturnType<typeof userBehaviorControllerGetLarkChatOptions>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userBehaviorControllerGetLarkChatOptions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof userBehaviorControllerGetLarkChatOptions>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserBehaviorControllerGetLarkChatOptionsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof userBehaviorControllerGetLarkChatOptions>>> = () => userBehaviorControllerGetLarkChatOptions(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type UserBehaviorControllerGetLarkChatOptionsQueryResult = NonNullable<Awaited<ReturnType<typeof userBehaviorControllerGetLarkChatOptions>>>
export type UserBehaviorControllerGetLarkChatOptionsQueryError = unknown

export const useUserBehaviorControllerGetLarkChatOptions = <TData = Awaited<ReturnType<typeof userBehaviorControllerGetLarkChatOptions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userBehaviorControllerGetLarkChatOptions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserBehaviorControllerGetLarkChatOptionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get an subscription
 */
export const userBehaviorControllerGetSubscription = (
    subscriptionId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserbehaviorGetSubscriptionResp>(
      {url: `/api/v1/devops/userbehavior/subscriptions/${subscriptionId}`, method: 'get'
    },
      options);
    }
  

export const getUserBehaviorControllerGetSubscriptionQueryKey = (subscriptionId: number,) => [`/api/v1/devops/userbehavior/subscriptions/${subscriptionId}`] as const;
  

    
export const getUserBehaviorControllerGetSubscriptionQueryOptions = <TData = Awaited<ReturnType<typeof userBehaviorControllerGetSubscription>>, TError = unknown>(subscriptionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userBehaviorControllerGetSubscription>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof userBehaviorControllerGetSubscription>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserBehaviorControllerGetSubscriptionQueryKey(subscriptionId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof userBehaviorControllerGetSubscription>>> = () => userBehaviorControllerGetSubscription(subscriptionId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(subscriptionId), ...queryOptions}}

export type UserBehaviorControllerGetSubscriptionQueryResult = NonNullable<Awaited<ReturnType<typeof userBehaviorControllerGetSubscription>>>
export type UserBehaviorControllerGetSubscriptionQueryError = unknown

export const useUserBehaviorControllerGetSubscription = <TData = Awaited<ReturnType<typeof userBehaviorControllerGetSubscription>>, TError = unknown>(
 subscriptionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof userBehaviorControllerGetSubscription>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getUserBehaviorControllerGetSubscriptionQueryOptions(subscriptionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * edit subscription
 */
export const userBehaviorControllerEditSubscription = (
    subscriptionId: number,
    userbehaviorEditSubscriptionReq: UserbehaviorEditSubscriptionReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserbehaviorEditSubscriptionResp>(
      {url: `/api/v1/devops/userbehavior/subscriptions/${subscriptionId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: userbehaviorEditSubscriptionReq
    },
      options);
    }
  


export const getUserBehaviorControllerEditSubscriptionMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userBehaviorControllerEditSubscription>>, TError,{subscriptionId: number;data: UserbehaviorEditSubscriptionReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userBehaviorControllerEditSubscription>>, TError,{subscriptionId: number;data: UserbehaviorEditSubscriptionReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userBehaviorControllerEditSubscription>>, {subscriptionId: number;data: UserbehaviorEditSubscriptionReq}> = (props) => {
          const {subscriptionId,data} = props ?? {};

          return  userBehaviorControllerEditSubscription(subscriptionId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserBehaviorControllerEditSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof userBehaviorControllerEditSubscription>>>
    export type UserBehaviorControllerEditSubscriptionMutationBody = UserbehaviorEditSubscriptionReq
    export type UserBehaviorControllerEditSubscriptionMutationError = ApiBaseResp

    export const useUserBehaviorControllerEditSubscription = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userBehaviorControllerEditSubscription>>, TError,{subscriptionId: number;data: UserbehaviorEditSubscriptionReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUserBehaviorControllerEditSubscriptionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * cancel an subscription
 */
export const userBehaviorControllerCancelSubscription = (
    subscriptionId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/userbehavior/subscriptions/${subscriptionId}/cancel`, method: 'post'
    },
      options);
    }
  


export const getUserBehaviorControllerCancelSubscriptionMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userBehaviorControllerCancelSubscription>>, TError,{subscriptionId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userBehaviorControllerCancelSubscription>>, TError,{subscriptionId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userBehaviorControllerCancelSubscription>>, {subscriptionId: number}> = (props) => {
          const {subscriptionId} = props ?? {};

          return  userBehaviorControllerCancelSubscription(subscriptionId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserBehaviorControllerCancelSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof userBehaviorControllerCancelSubscription>>>
    
    export type UserBehaviorControllerCancelSubscriptionMutationError = unknown

    export const useUserBehaviorControllerCancelSubscription = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userBehaviorControllerCancelSubscription>>, TError,{subscriptionId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUserBehaviorControllerCancelSubscriptionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * send a mock event to subscription
 */
export const userBehaviorControllerSendMockEvent = (
    subscriptionId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/userbehavior/subscriptions/${subscriptionId}/mock`, method: 'post'
    },
      options);
    }
  


export const getUserBehaviorControllerSendMockEventMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userBehaviorControllerSendMockEvent>>, TError,{subscriptionId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof userBehaviorControllerSendMockEvent>>, TError,{subscriptionId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof userBehaviorControllerSendMockEvent>>, {subscriptionId: number}> = (props) => {
          const {subscriptionId} = props ?? {};

          return  userBehaviorControllerSendMockEvent(subscriptionId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UserBehaviorControllerSendMockEventMutationResult = NonNullable<Awaited<ReturnType<typeof userBehaviorControllerSendMockEvent>>>
    
    export type UserBehaviorControllerSendMockEventMutationError = unknown

    export const useUserBehaviorControllerSendMockEvent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof userBehaviorControllerSendMockEvent>>, TError,{subscriptionId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUserBehaviorControllerSendMockEventMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * list users
 */
export const listUsers = (
    params?: ListUsersParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserListUserResp>(
      {url: `/api/v1/devops/users`, method: 'get',
        params
    },
      options);
    }
  

export const getListUsersQueryKey = (params?: ListUsersParams,) => [`/api/v1/devops/users`, ...(params ? [params]: [])] as const;
  

    
export const getListUsersQueryOptions = <TData = Awaited<ReturnType<typeof listUsers>>, TError = ApiBaseResp>(params?: ListUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listUsers>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUsersQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUsers>>> = () => listUsers(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListUsersQueryResult = NonNullable<Awaited<ReturnType<typeof listUsers>>>
export type ListUsersQueryError = ApiBaseResp

export const useListUsers = <TData = Awaited<ReturnType<typeof listUsers>>, TError = ApiBaseResp>(
 params?: ListUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * get user by email
 */
export const getUser = (
    params: GetUserParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserGetUserResp>(
      {url: `/api/v1/devops/users/detail`, method: 'get',
        params
    },
      options);
    }
  

export const getGetUserQueryKey = (params: GetUserParams,) => [`/api/v1/devops/users/detail`, ...(params ? [params]: [])] as const;
  

    
export const getGetUserQueryOptions = <TData = Awaited<ReturnType<typeof getUser>>, TError = ApiBaseResp>(params: GetUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = () => getUser(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type GetUserQueryError = ApiBaseResp

export const useGetUser = <TData = Awaited<ReturnType<typeof getUser>>, TError = ApiBaseResp>(
 params: GetUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUserQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update user role bindings
 */
export const updateUserRoleBindings = (
    userUpdateUserRoleBindingsReq: UserUpdateUserRoleBindingsReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiEmptyResp>(
      {url: `/api/v1/devops/users/roles`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: userUpdateUserRoleBindingsReq
    },
      options);
    }
  


export const getUpdateUserRoleBindingsMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserRoleBindings>>, TError,{data: UserUpdateUserRoleBindingsReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateUserRoleBindings>>, TError,{data: UserUpdateUserRoleBindingsReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserRoleBindings>>, {data: UserUpdateUserRoleBindingsReq}> = (props) => {
          const {data} = props ?? {};

          return  updateUserRoleBindings(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateUserRoleBindingsMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserRoleBindings>>>
    export type UpdateUserRoleBindingsMutationBody = UserUpdateUserRoleBindingsReq
    export type UpdateUserRoleBindingsMutationError = ApiBaseResp

    export const useUpdateUserRoleBindings = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserRoleBindings>>, TError,{data: UserUpdateUserRoleBindingsReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateUserRoleBindingsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * sync users manually
 */
export const syncUsersManually = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiEmptyResp>(
      {url: `/api/v1/devops/users/sync`, method: 'post'
    },
      options);
    }
  


export const getSyncUsersManuallyMutationOptions = <TError = ApiBaseResp,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncUsersManually>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof syncUsersManually>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof syncUsersManually>>, TVariables> = () => {
          

          return  syncUsersManually(requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SyncUsersManuallyMutationResult = NonNullable<Awaited<ReturnType<typeof syncUsersManually>>>
    
    export type SyncUsersManuallyMutationError = ApiBaseResp

    export const useSyncUsersManually = <TError = ApiBaseResp,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof syncUsersManually>>, TError,TVariables, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getSyncUsersManuallyMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
